import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Alert,
  Card,
  CardBody,
  CardTitle,
  Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
  ListGroup,
  ListGroupItem,
  Row
} from 'reactstrap'
import LoadingOverlay from '../../components/LoadingOverlay'
import generateFormFields from '../../helpers/FormFieldGenerator'
import { addLeadToList, removeLeadFromList, replaceLeadInList } from '../../actions/leads'
import {
  createUserLead, disqualifyUserLead, getLeadAuditHistory,
  getUserLead,
  qualifyUserLead,
  resetLead,
  setLead,
  updateLeadFormField,
  updateUserLead
} from '../../actions/lead'
import { Address, General, Preferences } from './form'
import { ButtonIcon } from '../../components/ButtonIcon'
import { detailedDiff, diff } from 'deep-object-diff'
import isEmpty from 'lodash.isempty'
import { warningFeedback } from '../../actions/feedback'
import debounce from 'debounce-promise'
import resolveArgs from '../../helpers/ArgumentResolver'
import { useNavigate, useParams } from 'react-router-dom'
import { LeadEnums } from '../../utils/Constants/Lead'
import isEmail from 'validator/lib/isEmail'
import PricingToolQuote from '../PricingToolQuote'
import UnsavedChangesAlert from '../../components/Alerts/UnsavedChangesAlert'
import classnames from 'classnames'
import { setConfirmDialog } from '../../actions/dialogs'
import FormValidationErrors from '../../components/Errors/FormValidationErrors'
import CollapsibleCard from '../../components/CollapsibleCard'
import EntityMainFormCard from '../../components/Cards/EntityMainFormCard'
import EntitySubFormCard from '../../components/Cards/EntitySubFormCard'
import HeadlessModal from '../../components/Modals/HeadlessModal'
import AuditHistory from "../../components/AuditHistory";
import { addBreadcrumbs } from '../../actions/breadcrumbs';

const Lead = ({
  id = null,
  toggleModal = null
}) => {

  // router
  const navigate = useNavigate()
  const params = useParams();

  const {original, form, audits} = useSelector(state => state.lead);
  const user = useSelector(state => state.authenticationState.account);
  const users = useSelector(state => state.helpers.systemUsers);
  const optionSets = useSelector(state => state.helpers.optionSets.lead);
  const industryTypes = useSelector(state => state.helpers.optionSets.account.industry);
  const campaigns = useSelector(state => state.campaigns);

  // redux
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [showDisqualifyModal, setShowDisqualifyModal] = useState(false)
  const [auditsLoading, setAuditsLoading] = useState(false);

  const leadId = useMemo(() => id || params.leadId, [id, params.leadId])
  const isNew = useMemo(() => leadId === 'new' && !original.id, [leadId, original.id])

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen(prevState => !prevState)
  const toggleDisqualify = () => setShowDisqualifyModal(prevState => !prevState)
  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState);

  useEffect(() => {
    setErrors([])
    if (!isNew && leadId) {
      dispatch(resetLead())
      loadLeadData(leadId)
    } else {
      dispatch(updateLeadFormField({ owner: users.find(x => x.id === user.cui) }))
    }
    return () => {
      dispatch(resetLead())
    }
  }, [leadId])

  useEffect(() => {
    dispatch(addBreadcrumbs([{name: original.topic ?? 'New' }]))
  }, [original.id])

  const loadLeadData = (leadId) => {
    setLoading(true)
    dispatch(getUserLead(user.cui, leadId))
      .then(() => {
        setLoading(false)
      })
  }

  const save = () => {
    if (validated()) {
      if (!isNew) {
        const toUpdate = diff(original, form)
        if (!isEmpty(toUpdate)) {
          setLoading(true)
          dispatch(updateUserLead(user.cui, original.id, resolveArgs(toUpdate)))
            .then((result) => {
              if (result) {
                dispatch(setLead(result))
                dispatch(replaceLeadInList(result))
              }
              setLoading(false)
            })
        } else {
          dispatch(warningFeedback('nothing to update!'))
        }
      } else {
        setLoading(true)
        dispatch(createUserLead(user.cui, resolveArgs(form)))
          .then((result) => {
            if (result?.errors) {
              setErrors(Object.values(result.errors))
            } else if (result) {
              dispatch(setLead(result))
              dispatch(addLeadToList(result))
            }
            setLoading(false)
          })
      }
    }
  }

  const qualify = () => {
    setLoading(true)
    dispatch(qualifyUserLead(user.cui, original.id || id))
      .then((result) => {
        if (result) {
          dispatch(removeLeadFromList(original))
          navigate(`/sales/opportunities/${result.id}`)
        }
        setLoading(false)
      })
  }
  const disqualify = (statusReason) => {
    setLoading(true)
    toggleDisqualify()
    dispatch(disqualifyUserLead(user.cui, original.id || id, statusReason))
      .then((result) => {
        if (result) {
          dispatch(setLead(result))
          dispatch(removeLeadFromList(original))
        }
        setLoading(false)
      })
  }
  const validated = () => {
    let errors = []
    setErrors(errors)
    for (const [key, value] of Object.entries(General)) {
      if (value.mandatory && (form[key] === undefined || form[key] === null || form[key].length === 0)) {
        errors.push(`You must provide a valid ${value.label}`)
      }
      if (key === 'email' && form[key] && !isEmail(form[key])) {
        errors.push(`You must provide a valid email address.`)
      }
      if (key === 'description' && form[key]?.length > 2000) {
        errors.push(`Description up to 2000 characters only.`)
      }
    }
    if (errors.length === 0) {
      return true
    }
    setErrors(errors)
  }

  const handleInput = (event) => {
    dispatch(updateLeadFormField({ [event.target.id]: event.target.value }))
  }

  const getSelectOptions = (field) => {
    if (field === 'campaign') {
      return campaigns.list.map((campaign) => {
        return {
          value: campaign.id,
          label: campaign.name
        }
      })
    }
    return industryTypes.options
  }

  const getSelectedOption = (field) => {
    let selected = []
    if (field === 'campaign') {
      return campaigns.list.find(campaign => campaign.id === form.campaign) ?? null
    }
    if (field === 'industryType') {
      return industryTypes.options.find(type => type.value === form.industryType) ?? null
    }
    if (selected.length) {
      return selected[0]
    }

    return null
  }

  const handleSelectInput = (key, selected) => {
    dispatch(updateLeadFormField({ [key]: selected?.value ?? null }))
  }
  const handleAsyncSelected = (key, selected) => {
    dispatch(updateLeadFormField({ [key]: selected }))
  }

  const handleAsyncInput = (entity, key, length, search) => {
    if (!search || search.length < length) {
      return new Promise(() => [])
    }
    if (entity === 'owner') {
      return new Promise((resolve) => {
        resolve(filterOwners(search))
      })
    }
  }
  const handleInputDate = (field, value) => {
    dispatch(updateLeadFormField({ [field]: value }))
  }

  const filterOwners = (search) => {
    return users.filter(user => {
      return user.name.toLowerCase()
        .includes(search.toLowerCase())
    })
  }

  const canQualify = () => {
    return original.status === LeadEnums.status.OPEN && original.firstName && original.lastName && original.company && !hasChanges()
  }

  const getStatusReason = (label) => {
    return optionSets.statusReason.options.find(option => option.label.toLowerCase() === label.toLowerCase()).value
  }

  const hasChanges = () => {
    const changes = diff(original, form);
    return !isEmpty(changes)
  }

  const onClosing = () => {
    if (hasChanges() || isNew) {
      dispatch(setConfirmDialog({
        color: 'danger',
        text: 'You have unsaved changes! Closing this window will result losing the changes you\'ve made.',
        proceed: () => toggleModal()
      }))
    } else {
      toggleModal()
    }
  }

  const isActive = () => {
    return original.status === LeadEnums.status.OPEN
  }

  const getAudits = () => {
    toggleAuditsLoading();
    dispatch(getLeadAuditHistory(original.id)).then(() => toggleAuditsLoading())
  }

  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <Card className="bg-light border-0 mb-0">
          <CardBody>
            <Row className="mb-2">
              <Col className="d-flex">
                <CardTitle>
                  {original.topic || (isNew ? 'New Lead' : '')}
                </CardTitle>
                <div className={classnames('d-flex', 'align-items-center', 'fadeIn', 'ms-auto')}>
                  {
                    !loading && !isNew && hasChanges() && <UnsavedChangesAlert save={save}/>
                  }
                  <ButtonIcon disabled={loading || !hasChanges()} icon={'fa fa-save'} tooltip={'Save'} onClick={save}/>
                  <ButtonIcon disabled={loading || isNew} icon={'fa fa-refresh'} tooltip={'Reload'}
                              onClick={loadLeadData}/>
                  <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="left">
                    <DropdownToggle tag="span" data-toggle="dropdown"
                                    aria-expanded={dropdownOpen}>
                      <ButtonIcon icon="fa fa-lg fa-bars" onClick={() => {}}/>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={qualify}
                        disabled={loading || !canQualify()}
                      >Qualify
                      </DropdownItem>
                      <DropdownItem
                        disabled={loading || original.status !== LeadEnums.status.OPEN}
                        onClick={toggleDisqualify}
                      >
                        Disqualify
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  {toggleModal &&
                    <ButtonIcon onClick={onClosing} icon="fa fa-lg fa-close" tooltip={'Close Popup'}/>
                  }
                </div>
              </Col>
            </Row>
            <FormValidationErrors errors={errors}/>
            {!loading && !isActive() && !isNew &&

              <Row>
                <Col>
                  <Alert color={'warning'}>
                    <p className="mb-0">This Lead is now closed.</p>
                  </Alert>
                </Col>
              </Row>
            }
            <Row className="d-flex justify-content-between">
              <Col className={'col-md-6 col-sm-12'}>
                <EntityMainFormCard>
                  <Row form>
                    {generateFormFields({
                      fields: General,
                      handleInput,
                      getSelectOptions,
                      getSelectedOption,
                      handleSelectInput,
                      handleInputDate,
                      data: form,
                      handleAsyncInput: debounce(handleAsyncInput, 500),
                      handleAsyncSelected,
                      optionSets
                    })}
                  </Row>
                </EntityMainFormCard>
              </Col>
              <Col className={'col-md-6 col-sm-12'}>
                <Row className={'d-flex flex-column'}>
                  <Col>
                    <EntitySubFormCard title={'Address'}>
                      <Row form>
                        {generateFormFields({
                          fields: Address,
                          handleInput,
                          data: form,
                          optionSets
                        })}
                      </Row>
                    </EntitySubFormCard>
                  </Col>
                  <Col>
                    <EntitySubFormCard title={'Preferences'}>
                      <Row form>
                        {generateFormFields({
                          fields: Preferences,
                          handleSelectInput,
                          data: form
                        })}
                      </Row>
                    </EntitySubFormCard>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                  title={'Pricing Tool Quote'}
                >
                  {original.pricingToolQuote ?
                    <PricingToolQuote id={original.pricingToolQuote}/>
                    :
                    <Alert color={'warning'}>This lead does not have a Pricing Tool Quote associated.</Alert>
                  }
                </CollapsibleCard>
              </Col>
            </Row>
            {!isNew && <Row>
              <Col>
                <CollapsibleCard
                    title={'Audit History'}
                    onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
                >
                  <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
                </CollapsibleCard>
              </Col>
            </Row>}
          </CardBody>
        </Card>
      </LoadingOverlay>
      <HeadlessModal
        size={'sm'}
        open={showDisqualifyModal}
        toggle={toggleDisqualify}
      >
        <Card className="bg-light border-0 mb-0">
          <CardBody>
            <Row className={'mb-2'}>
              <Col className={'d-flex justify-content-between'}>
                <CardTitle>Select a reason</CardTitle>
                <ButtonIcon onClick={toggleDisqualify} icon="fa fa-lg fa-close" tooltip={'Close Popup'}/>
              </Col>

            </Row>
            <Row>
              <Col>
                <ListGroup>
                  <ListGroupItem tag="button" action
                                 onClick={() => disqualify(getStatusReason('lost'))}>Lost</ListGroupItem>
                  <ListGroupItem tag="button" action
                                 onClick={() => disqualify(getStatusReason('cancelled'))}>Canceled</ListGroupItem>
                  <ListGroupItem tag="button" action
                                 onClick={() => disqualify(getStatusReason('no longer interested'))}>No
                    Longer Interested</ListGroupItem>
                  <ListGroupItem tag="button" action onClick={() => disqualify(getStatusReason('cannot contact'))}>Cannot
                    Contact</ListGroupItem>
                  <ListGroupItem tag="button" action onClick={() => disqualify(getStatusReason('bad lead'))}>Bad
                    Lead</ListGroupItem>
                </ListGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </HeadlessModal>
    </div>
  )
}

export default Lead
