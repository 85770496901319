import {FunctionComponent, useEffect, useState} from "react";
import ProductService from "../../utils/Products/ProductService";
import {Alert, Button} from "reactstrap";
import {TServiceCheck} from "../../types/ServiceCheck";
import FormValidationErrors from "../Errors/FormValidationErrors";
import LoadingOverlay from "../LoadingOverlay";
import isEmpty from "lodash.isempty";
import {useDispatch} from "react-redux";
import {defaultErrorFeedback} from "../../actions/feedback";

type TOwnProps = {
    orderProductId: string
}
const ServiceCheck: FunctionComponent<TOwnProps> = ({
    orderProductId
}) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [serviceCheck, setServiceCheck] = useState<TServiceCheck|null>( null);

    const toggleLoading = () => setLoading(prevState => !prevState);

    useEffect(() => {
        console.log({errors})
    }, [errors])

    const runCheck = () =>{
        toggleLoading()
        setServiceCheck(null)
        setErrors([])
        ProductService.serviceCheck(orderProductId).then((result: any) => {
            if(result.status === 422){
                setErrors(Object.values(result.data))
            }else if(result.status == 200){
                setServiceCheck(result.data)
            }else{
                dispatch(defaultErrorFeedback());
            }

            toggleLoading()
        })
    }

    return (
        <div style={{minHeight: '100px'}}>
            {!isEmpty(errors) && <FormValidationErrors errors={errors}/>}
            {serviceCheck && !serviceCheck.passed &&
                <div>
                  <FormValidationErrors errors={serviceCheck.errors}/>
                </div>
            }
            {(serviceCheck && serviceCheck.passed) ?
              <Alert color={'success'}>
                OK
              </Alert> :
                <LoadingOverlay loading={loading} text={'in progress...'}>
                    {
                        !loading && <Button color={'info'} block onClick={runCheck}>Run</Button>

                    }

                </LoadingOverlay>
            }
        </div>
    )

}

export default ServiceCheck;