import React, { useEffect, useMemo, useState } from 'react';
import {connect, useDispatch} from 'react-redux';
import {
  Alert,
  Badge,
  Button,
  CardTitle,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  CardBody,
  Card
} from 'reactstrap';
import LoadingOverlay from '../../components/LoadingOverlay';
import generateFormFields from '../../helpers/FormFieldGenerator';
import OpportunityForm from './form';
import {
  addOpportunityTask,
  closeOpportunity,
  createUserOpportunity,
  getOpportunity, getOpportunityAuditHistory, getOpportunityRelatedServices,
  resetOpportunity, restoreOpportunity,
  searchAccounts,
  toggleOpportunityLoading,
  updateOpportunity,
  updateOpportunityField
} from '../../actions/opportunity';
import Quotes from '../Quotes';
import Quote from '../Quote';
import { SelectMod } from '../../components/Selects/SelectMod';
import {
  addNewOpportunityToList,
  removeOpportunityFromList,
  replaceOpportunityInList
} from '../../actions/opportunities';
import { diff } from 'deep-object-diff';
import resolveArgs from '../../helpers/ArgumentResolver';
import isEmpty from 'lodash.isempty';
import { warningFeedback } from '../../actions/feedback';
import { getAccountContacts, getQuote, resetQuote, setQuoteLoader } from '../../actions/quote';
import TasksTable from '../../components/Tables/Tasks';
import Task from '../Task';
import { ButtonIcon } from '../../components/ButtonIcon';
import { updateQuoteInList } from '../../actions/quotes';
import classnames from 'classnames';
import { OpportunityEnums } from '../../utils/Constants/Opportunity';
import HeadlessModal from '../../components/Modals/HeadlessModal';
import Contact from '../Contact';
import UnsavedChangesAlert from '../../components/Alerts/UnsavedChangesAlert';
import { TaskEnums } from '../../utils/Constants/Task';
import CollapsibleCard from '../../components/CollapsibleCard';
import FormValidationErrors from '../../components/Errors/FormValidationErrors';
import EntitySubFormCard from '../../components/Cards/EntitySubFormCard';
import EntityMainFormCard from '../../components/Cards/EntityMainFormCard';
import { setConfirmDialog } from '../../actions/dialogs';
import queryString from 'query-string';
import { formValidator } from '../../helpers/FormValidator';
import { addBreadcrumbs, resetBreadcrumbs } from '../../actions/breadcrumbs';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import AuditHistory from "../../components/AuditHistory";
import { NavigationBlocker } from '../../components/NavigationBlocker';
import { PriceListEnums } from '../../utils/Constants/PriceList';
import { resolveCurrencySign } from '../../utils/Helpers/Currency';
import { useGetActivePriceListByType } from '../../hooks/PriceList';

const entityInitialState = {type: '', id: ''}
const Opportunity = ({
  opportunity: {
    loading,
    quoteRefInProgress,
    form,
    original,
    audits
  },
  optionSets,
  taskOptionSets,
  users,
  user,
  progressBars,
  id = null,
  toggleModal = null,
  priceLists
}) => {

  const dispatch = useDispatch()

  const navigate = useNavigate();
  const { opportunityId } = useParams()
  const location = useLocation()

  const [closingOpportunity, setClosingOpportunity] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [reasonToClose, setReasonToClose] = useState(null);
  const [quote, setQuote] = useState({});
  const [errors, setErrors] = useState([]);
  const [loadingText, setLoadingText] = useState('');
  const [accountContacts, setAccountContacts] = useState([]);
  const [accountServices, setAccountServices] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showEntityModal, setShowEntityModal] = useState(false);
  const [entity, setEntity] = useState(entityInitialState);
  const [activeTasksCount, setActiveTasksCount] = useState(0);
  const [task, setTask] = useState({});
  const [auditsLoading, setAuditsLoading] = useState(false);

  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState);
  const isNew = opportunityId === 'new' && !original.id;

  const quoteProgress = progressBars[quoteRefInProgress];
  const getActivePriceListByType = useGetActivePriceListByType();

  useEffect(() => {
    if (quoteProgress && quoteProgress.status === 'complete') {
      dispatch(getQuote(quoteRefInProgress)).then((result) => {
        if(result){
          dispatch(updateQuoteInList(result.id, result));
        }
      })
    }
    if (quoteProgress && quoteProgress.status === 'error'){
      dispatch(setQuoteLoader(false))
    }
  }, [quoteProgress]);

  useEffect(() => {
    if(!toggleModal){
      dispatch(addBreadcrumbs([{name: original.number ?? original.name ?? 'New' }]))
    }


  }, [original.id])
  useEffect(() => {
    const params = queryString.parse(location?.search);
    if(params.accountId && params.account){
      dispatch(updateOpportunityField({ customer: {name: params.account, accountid: params.accountId, priceListType: parseInt(params.priceListType)} }));
    }

  }, [location?.search])

  useEffect(() => {
    if (!isNew) {
      getData();
    } else {
      dispatch(updateOpportunityField({ owner: users.find(x => x.id === user.cui) }));
      dispatch(updateOpportunityField({ type: 1 }));
    }
    return () => {
      dispatch(resetOpportunity());
      dispatch(resetBreadcrumbs());
    };

  }, []);

  useEffect(() => {
    if(isNew && form.customer?.accountid){
      setLoadingText('Loading Customer Contacts...')
      dispatch(toggleOpportunityLoading());
      let promises = [
        dispatch(getAccountContacts(form.customer.accountid)).then((result) => {
          if(result){
            setAccountContacts(result)
          }

        }),
      ]
      Promise.all(promises).then(() =>  {
        setLoadingText('')
        dispatch(toggleOpportunityLoading())
      })
    }
    if(isNew && form.customer?.priceListType){
      dispatch(updateOpportunityField({priceList: getActivePriceListByType(form.customer.priceListType)}))
    }

  }, [form.customer])

  useEffect(() => {
    setActiveTasksCount(original.tasks.filter(task => task.status === TaskEnums.status.OPEN).length)
  }, [original.tasks])


  const getData = () => {
    dispatch(toggleOpportunityLoading());
    dispatch(getOpportunity(user.cui, id || original.id || opportunityId))
      .then((result) => {
        if(result){
          let promises = [
            dispatch(getAccountContacts(result.customer.accountid)).then((result) => {
              if(result){
                setAccountContacts(result)
              }

            }),
            dispatch(getOpportunityRelatedServices(result.id)).then((result) => {
              if(result){
                setAccountServices(result.filter(service => service.isActive))
              }
            })
          ]
          Promise.all(promises).then(() =>  dispatch(toggleOpportunityLoading()))
        }else{
          dispatch(toggleOpportunityLoading());
        }

      });
  };

  const getAudits = () => {
    toggleAuditsLoading();
    dispatch(getOpportunityAuditHistory(original.id)).then(() => toggleAuditsLoading())
  }

  const hasChanges = () => {
    const theDiff = diff(original, form);
    return !isClosed() &&!loading && !isEmpty(theDiff)
  }
  const save = () => {
    if (validated()) {
      if (!isNew) {
        const toSave = diff(original, form);
        delete toSave.tasks;
        if (!isEmpty(toSave)) {
          dispatch(toggleOpportunityLoading());
          dispatch(updateOpportunity(user.cui, original.id || opportunityId, resolveArgs(toSave)))
            .then((result) => {
              if(result.errors){
                setErrors(Object.values(result.errors))
              }
              else if (result) {
                dispatch(replaceOpportunityInList(result));
              }
              dispatch(toggleOpportunityLoading());
            });
        } else {
          dispatch(warningFeedback('Nothing to update!'));
        }
      } else {
        dispatch(toggleOpportunityLoading());
        setLoadingText('Creating opportunity...')
        dispatch(createUserOpportunity(user.cui, resolveArgs(form)))
          .then((result) => {
            if(result.errors){
              setErrors(Object.values(result.errors))
            }
            else if(result){
              setLoadingText('Loading opportunity related services...')
              dispatch(addNewOpportunityToList(result))
              dispatch(getOpportunityRelatedServices(result.id)).then((result) => {
                if(result){
                  setAccountServices(result.filter(service => service.isActive))
                }
                setLoadingText('')
                dispatch(toggleOpportunityLoading());
              })
              navigate(`/sales/opportunities/${result.id}`)
            }else{
              dispatch(toggleOpportunityLoading());
            }

          });
      }
    }
  };
  const isClosed = () => {
    return form.status === 2 || (form.status === 1 && form.statusReason === 3);
  };

  const hasLead = () => {
    return !isEmpty(original.lead)
  }

  const hasQuote = () => {
    return !isEmpty(original.quotes)
  }

  const toggleCloseModal = () => {
    setShowCloseModal(!showCloseModal);
  };

  const toggleQuoteModal = () => {
    setQuote({});
  };

  const toggleTaskModal = () => {
    setShowTaskModal(!showTaskModal);
  };
  const toggleEntityModal = () => {
    setShowEntityModal(prevState => !prevState)
  }
  const getCloseOptions = () => {
    return optionSets.statusReason.options.filter(s => s.status === 2);
  };
  const setCloseOption = (selected) => {
    setReasonToClose(selected);
  };
  const close = () => {
    setClosingOpportunity(true);
    dispatch(closeOpportunity(user.cui, original.id, reasonToClose.value))
      .then((result) => {
        if (result) {
          dispatch(removeOpportunityFromList(original.id));
          dispatch(updateOpportunityField({ status: OpportunityEnums.status.LOST }));
          dispatch(updateOpportunityField({ quotes: [] }));
          setQuote({});
        }
        toggleCloseModal();
        setClosingOpportunity(false);
      });
  };
  const reOpen = () => {
    dispatch(toggleOpportunityLoading())
    dispatch(restoreOpportunity(user.cui, original.id)).then((result) => {
      if(result.errors){
        setErrors(Object.values(result.errors))
      }
      else if (result) {
        dispatch(addNewOpportunityToList(result));
      }
      dispatch(toggleOpportunityLoading());
    })
  }
  const getSelectOptions = (field) => {
    if(field === 'contact'){
      return accountContacts.map((contact) => {
        return {
          value: contact.id,
          label: contact.fullName,
          isDisabled: !contact.isActive
        };
      })
    } else if (field === 'priceList') {
      return priceLists
        .filter(priceList =>
          priceList.status?.id === PriceListEnums.status.ACTIVE &&
          priceList.type?.id !== PriceListEnums.type.PORTAL)
        .map((option) => {
          return {
            value: option.uuid,
            label: option.name
          };
        });
    }
    else if (optionSets[field]) {
      return optionSets[field].options.map((option) => {
        return {
          value: option.value,
          label: option.label
        };
      });
    }
    return [];
  };
  const getSelectedOption = (field) => {
    let selected = [];
    if(field === 'contact'){
      return accountContacts.find((contact) => contact.id === (form['contact']?.id || form['contact']) || null)
    }else if (field === 'priceList'){
      selected = priceLists.filter((option) => {
        return form[field] && (option.uuid === form[field].uuid || option.uuid === form[field] ) ;
      });
    }else if (optionSets[field]) {
      selected = optionSets[field].options.filter(option => option.value === form[field]);
    }
    if (selected.length) {
      return selected[0];
    }

    return null;
  };
  const handleInput = (event) => {
    const formField = OpportunityForm(isNew)[event.target.id];
    if ((formField && !formField.plaintext) || event.target.id === 'description') {
      dispatch(updateOpportunityField({ [event.target.id]: event.target.value }));
    }

  };
  const handleInputDate = (field, value) => {
    dispatch(updateOpportunityField({ [field]: value }));
  };
  const handleSelectInput = (field, selected) => {
    if(field === 'contact'){
      const contact = accountContacts.find(contact => contact.id === selected?.value)
      dispatch(updateOpportunityField({ [field]: contact || [] }));
    }else{
      dispatch(updateOpportunityField({ [field]: selected?.value || null }));
    }

  };
  const handleAsyncSelected = (field, selected) => {
    dispatch(updateOpportunityField({ [field]: selected }));
  };
  const handleAsyncInput = (entity, key, length, search) => {
    if (!search || search.length < length) {
      return new Promise(() => []);
    }
    if (entity === 'account') {
      return dispatch(searchAccounts(search))
        .then((result) => result);
    }
    if (entity === 'user') {
      return new Promise(resolve => {
        resolve(filterUsers(search));
      });
    }
  };

  const handleEntityClick = (key) => {
    if(key === 'contact'){
      setEntity({type: 'contact', id: form.contact?.id || form.contact})
    }
    toggleEntityModal()
  }

  const filterUsers = (search) => {
    return users.filter((option) => {
      return option.name.toLowerCase()
        .includes(search.toLowerCase());
    });
  };

  const validated = () => {
    let errorArr = formValidator(OpportunityForm(isNew), form);
    setErrors(errorArr);
    return isEmpty(errorArr);
  };

  const onClosing = () => {
    if(hasChanges() || isNew){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => toggleModal()
      }))
    }else if(toggleModal){
      toggleModal()
    }
  }

  const getEntityComponent = () => {
    if(entity.type === 'contact'){
      return <Contact
        id={entity.id}
        accountId={form.customer.accountid}
        toggleModal={toggleEntityModal}
      />
    }
    return ''
  }

  return (
    <div className="animated fadeIn">
      <NavigationBlocker shouldBlock={hasChanges()}/>
      <LoadingOverlay loading={loading} text={loadingText}>
        <Row>
          <Col>
            <Card className='bg-light border-0'>
              <CardBody>
                <Row className={'mb-2'}>
                  <Col className='d-flex'>
                    <CardTitle className='d-flex'>{original.number || original.name || 'New Opportunity'}</CardTitle>
                    <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ms-auto')}>
                      {
                        hasChanges() && <UnsavedChangesAlert save={save}/>
                      }
                      {!isNew &&
                        <Button
                          onClick={isClosed() ? reOpen : toggleCloseModal}
                          color={'primary'}
                          size={'sm'}
                          className={'ms-2'}
                        >
                          {isClosed() ? 'Re-Open' : 'Close'}
                        </Button>
                      }
                      <ButtonIcon disabled={!hasChanges()} icon={'fa fa-save'} tooltip={'save'}  onClick={save}/>
                      <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'reload'}  onClick={getData}/>
                      {toggleModal &&
                        <ButtonIcon onClick={onClosing} icon='fa fa-lg fa-close' tooltip={'Close Popup'}/>
                      }
                    </div>
                  </Col>
                </Row>
                {isClosed() &&
                  <Row>
                    <Col>
                      <Alert color={'warning'}>
                        <span>This opportunity is now closed.</span>
                      </Alert>

                    </Col>
                  </Row>
                }
                {!isEmpty(original.contact) && !original.contact.isActive && (
                  <Row>
                    <Col>
                      <Alert color={'warning'}>
                        <p className="mb-0" >Inactive Contact Warning!</p>
                      </Alert>
                    </Col>
                  </Row>
                )}
                <FormValidationErrors errors={errors}/>
                <Row className="d-flex justify-content-between">
                  <Col className={"d-flex col-md-6 col-sm-12"}>
                    <EntityMainFormCard>
                      <Row form>
                        {generateFormFields(
                          {
                            fields: OpportunityForm(isNew, hasLead(), hasQuote(), resolveCurrencySign(original.priceList?.currency?.id), handleEntityClick),
                            handleClick: !isNew ? handleEntityClick : () => {},
                            handleInput,
                            getSelectOptions,
                            getSelectedOption,
                            handleSelectInput,
                            handleAsyncSelected,
                            handleAsyncInput,
                            handleInputDate,
                            data: form
                          })
                        }
                      </Row>
                    </EntityMainFormCard>
                  </Col>
                  <Col className={"d-flex col-md-6 col-sm-12"} >
                      <EntitySubFormCard title={'Description'} grow>
                        <Input
                          type="textarea"
                          name={'description'}
                          id={'description'}
                          value={form.description ?? ''}
                          onChange={handleInput}
                          rows={20}
                        />
                      </EntitySubFormCard>
                  </Col>
                </Row>
                {!isNew &&
                  <>
                    <Row>
                      <Col>
                        <EntitySubFormCard title={'Quotes'}>
                          <Row>
                            <Col className={'d-flex justify-content-end'}>
                              <Button
                                onClick={() => {
                                  setQuote({ id: 'new' });
                                  dispatch(resetQuote());
                                }}
                                disabled={isClosed() || isNew || hasChanges()}
                                size='sm'
                                color='secondary'>
                                New Quote
                              </Button>
                            </Col>

                          </Row>
                          <Quotes
                            opportunityQuotes={form.quotes}
                            onRowClick={setQuote}
                            priceList={original.priceList}
                          />
                        </EntitySubFormCard>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <CollapsibleCard
                          open
                          headerExtraRight={<div className='ms-2'>
                            {activeTasksCount > 0 &&
                              <Badge color="warning">{activeTasksCount}</Badge>
                            }
                          </div>}
                          title={'Tasks'}
                        >
                          <Row>
                            <Col>
                              <TasksTable
                                withNew={!isNew}
                                tasks={original.tasks ?? []}
                                types={taskOptionSets.type}
                                defaultType={TaskEnums.type.SALES_FEASIBILITY}
                                priorities={taskOptionSets.priority}
                                statusReason={taskOptionSets.statusReason}
                                pageSize={10}
                                onRowClick={(task) => {
                                  setTask(task);
                                  setShowTaskModal(true);
                                }}
                                relatedTo={{type: 'opportunity', data: form}}
                                onCreated={task => dispatch(addOpportunityTask(task))}
                              />
                            </Col>
                          </Row>
                        </CollapsibleCard>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CollapsibleCard
                          title={'Audit History'}
                          onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
                        >
                          <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
                        </CollapsibleCard>
                      </Col>
                    </Row>
                  </>

                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingOverlay>
      <Modal
        isOpen={showCloseModal}
        toggle={toggleCloseModal}
        className="modal-lg"
        backdrop={'static'}
      >
        <ModalHeader toggle={toggleCloseModal}>
          Select a reason
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className={'d-flex justify-content-end'}>
              <SelectMod
                className={'w-100 me-2'}
                isClearable
                options={getCloseOptions()}
                value={reasonToClose}
                onChange={setCloseOption}
              />
              <Button disabled={!reasonToClose} style={{ minWidth: '50px' }} size={'sm'} color={'danger'}
                      onClick={close}>
                {closingOpportunity ? <Spinner size="sm" color={'light'}/> : 'Close'}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showTaskModal}
        toggle={toggleTaskModal}
        className="modal-lg"
        backdrop={'static'}
      >
        <ModalBody className='p-0 mb-0'>
          <Row>
            <Col>
              <Task
                toggleModal={toggleTaskModal}
                id={task.id || 'new'}
                relatedTo={{data:form}}
                relatedToType={'opportunity'}
                type={1}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={Boolean(quote.id)}
        toggle={toggleQuoteModal}
        keyboard={false}
        className="modal-xxlg"
        backdrop={'static'}
      >
        <ModalBody className='p-0 mb-0'>
          <Quote
            id={quote.id}
            opportunity={form}
            onCloned={() => setQuote({ id: 'new' })}
            toggleModal={toggleQuoteModal}
            contacts={accountContacts}
            activeServices={accountServices}
          />
        </ModalBody>
      </Modal>
      <HeadlessModal
        open={showEntityModal}
        onClosed={() => setEntity(entityInitialState)}
        size={'xlg'}
        toggle={toggleEntityModal}
      >
        <Row>
          <Col>
            {getEntityComponent()}
          </Col>
        </Row>

      </HeadlessModal>
    </div>

  )

};

function mapStateToProps({
  opportunity,
  opportunities,
  authenticationState,
  helpers,
  socketioProgress,
  priceLists
}) {
  return {
    opportunity,
    opportunities,
    user: authenticationState.account,
    users: helpers.systemUsers,
    optionSets: helpers.optionSets.opportunity,
    taskOptionSets: helpers.optionSets.task,
    slas: helpers.products,
    progressBars: socketioProgress.progressBars,
    priceLists: priceLists.list,
  };
}

export default connect(mapStateToProps)(Opportunity);
