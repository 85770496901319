import React, { useState } from 'react';
import { Card, CardBody, CardHeader, CardText, CardTitle, Collapse } from 'reactstrap';
import moment from 'moment';
import LoadingOverlay from '../../components/LoadingOverlay';

const Email = (props) => {
  const { email, loadMore } = props;
  const [collapsed, setCollapsed] = useState(true);
  const [loadedContent, setLoadedContent] = useState(false);

  const showExtraContent = () => {
    if (email.type !== 'Email') {
      return false;
    }
    return !(loadedContent && !email.content);
  };

  const toggleCollapse = () => {
    if (!showExtraContent()) {
      return;
    }
    if (!loadedContent) {
      loadMore()
        .then(() => {
          setLoadedContent(true);
        });
    }
    setCollapsed(!collapsed);
  };

  return (
    <Card>
      <CardHeader className={showExtraContent() && !loadedContent ? 'hoverPointer' : ''}
                  onClick={toggleCollapse}>
        <CardText style={{ whiteSpace: 'pre-line' }}>
          {email.emailAddresses && email.emailAddresses.from ? email.emailAddresses.from : (email.createdBy ? email.createdBy : '')}
        </CardText>
        <CardTitle>{email.subject}</CardTitle>
        <p className={'float-end'}>
          <small>{moment(email.createdOn)
            .format('DD/MM/YYYY HH:mm')}</small>
        </p>
      </CardHeader>
      {showExtraContent() ? (
        <Collapse isOpen={!collapsed}>
          <LoadingOverlay loading={!loadedContent}>
            <CardBody style={{ minHeight: 100 }}>
              <div dangerouslySetInnerHTML={{ __html: email.content }}/>
            </CardBody>
          </LoadingOverlay>
        </Collapse>
      ) : ''}
    </Card>
  );
};

export default Email;
