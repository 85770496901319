import { Button, Card, CardBody, Col, Form, FormGroup, Input, InputGroup, Row } from 'reactstrap';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import isEmpty from 'lodash.isempty';
import { searchAccounts } from '../../actions/accounts';
import { connect, useDispatch } from 'react-redux'
import AccountsTable from '../../components/Tables/Accounts';

const Accounts = ({
  accounts
}) => {

  const dispatch = useDispatch();

  const [searchString, setSearchString] = useState('')
  const [loading, setLoading] = useState(false)
  const [hideNoData, setHideNoData] = useState(true)

  const toggleLoading = () => setLoading((prevState) => !prevState)
  const handleSubmit = (event) => {
    event.preventDefault();
    if(!isEmpty(searchString)){
      toggleLoading()
      dispatch(searchAccounts(searchString, false)).then((result) => {
        setHideNoData(false)
        if(!isEmpty(result)){
          setHideNoData(true)
        }
        toggleLoading()
      })
    }

  }
  const handleInput = (event) => {
    setSearchString(event.target.value)
  }

  return (
    <div className="animated fadeIn">
      <Card className='border-0 mb-0'>
        <CardBody>
          <Row className={'mb-3'}>
            <Col className={"d-flex justify-content-between"}>
              <Form onSubmit={handleSubmit} className='w-50'>
                <FormGroup>
                  <InputGroup>
                    <Input placeholder="Account Name or Number" onChange={handleInput} value={searchString}/>
                    <LaddaButton
                      className="btn btn-info btn-ladda px-4 me-1 text-white"
                      loading={loading}
                      data-style={ZOOM_OUT}
                      onClick={handleSubmit}
                      disabled={isEmpty(searchString)}
                    >
                      <div className="h5 mb-0">Search</div>
                    </LaddaButton>
                  </InputGroup>
                </FormGroup>
              </Form>
              <div>
                <Link to="/accounts/new"><Button className="ms-2"  color={'secondary'} >New Account</Button></Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <AccountsTable noDataText={'No Accounts found.'} hideNoData={hideNoData} accounts={accounts.accounts}/>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  )

}

function mapStateToProps({ accounts, helpers }) {
  return {
    accounts
  };
}

export default connect(mapStateToProps)(Accounts);
