import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, Form, FormGroup, Label, Input, FormText, CardFooter, FormFeedback } from 'reactstrap';
import { AsyncSelectMod } from '../../components/Selects/AsyncSelectMod';
import { searchAccounts } from '../../actions/accounts';
import { connect } from 'react-redux';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import validator from 'validator';
import { api_downloadLOA, api_generateLOA } from '../../utils/ServiceDelivery/LOA';
import { joinRooms } from '../../actions/socketio';
import fileDownload from 'js-file-download';

class LOAGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      company: {},
      errors: {},
      uuid: undefined
    };
  }

    getActiveAccounts = (searchString) =>{
      if (!searchString || searchString.length <= 2) {
        return [];
      }
      return this.props.dispatch(searchAccounts(searchString, true)).then(
        () => {
          return this.props.accounts.accounts;
        }
      );
    };

    handleSubmit = () => {
      this.setState({ loading: true, errors: {} });
      let errors = {};
      if (validator.isEmpty(this.ref.value)) {
        errors.ref = true;
      }
      if (validator.isEmpty(this.location.value)) {
        errors.location = true;
      }
      if (this.state.company === null) {
        errors.company = true;
      } else if (this.state.company.accountid === undefined || validator.isEmpty(this.state.company.accountid + '')) {
        errors.company = true;
      }

      if (Object.keys(errors).length > 0) {
        this.setState({ loading: false, errors });
      } else {
        api_generateLOA({
          ref: this.ref.value,
          company: this.state.company.name,
          location: this.location.value,
          times: this.times.value,
          comments: this.comments.value
        }).then((result) => {
          if (result.status === 200) {
            this.props.dispatch(joinRooms({ rooms: [result.data.uuid] }));
            this.setState({ uuid: result.data.uuid });
            this.interval = setInterval(() => {
              if (this.props.progressBars[result.data.uuid] != undefined) {
                if (this.props.progressBars[result.data.uuid].status === 'COMPLETE') {
                  this.setState({ loading: false });
                  // do download!
                  api_downloadLOA(result.data.uuid).then((result) => {
                    if (result.status === 200) {
                      let splitContentDisposition = result.headers['content-disposition'].split('=');
                      fileDownload(result.data, splitContentDisposition[1]);
                    }
                  });
                  clearInterval(this.interval);
                }
              }
            }, 500);
          }
        });
      }
    }

    render() {
      return (
            <div className="animated fadeIn">
                <Row>
                    <Col md={4}>
                        <Card className="card-accent-info">
                            <CardHeader>
                                LOA Generator
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <FormGroup>
                                        <Label>Reference</Label>
                                        <Input type="text" innerRef={input => this.ref = input} invalid={this.state.errors.ref}/>
                                        <FormText>This is most likely the order number</FormText>
                                        <FormFeedback>Must provide a reference</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Company</Label>
                                        <AsyncSelectMod
                                            isClearable={true}
                                            cacheOptions={true}
                                            getOptionLabel={({ name, accountnumber }) => name + ' (' + accountnumber + ')'}
                                            getOptionValue={({ accountid }) => accountid}
                                            loadOptions={this.getActiveAccounts}
                                            options={this.props.accounts}
                                            onChange={(input) => this.setState({ company: input })}
                                        />
                                        {this.state.errors.company !== undefined
                                        && <FormFeedback color="danger" style={{ display: 'block' }}>Must select a value for company</FormFeedback>
                                        }
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Location</Label>
                                        <Input type="text" innerRef={input => this.location = input} invalid={this.state.errors.location}/>
                                        <FormText>e.g. Telehouse North, TFM2 E31</FormText>
                                        <FormFeedback>Must provide a location</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Times</Label>
                                        <Input type="text" innerRef={input => this.times = input} />
                                        <FormText>Optional - e.g. 1am to 3am</FormText>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Comments</Label>
                                        <Input type="textarea" innerRef={input => this.comments = input} />
                                        <FormText>Optional</FormText>
                                    </FormGroup>

                                </Form>
                            </CardBody>
                            <CardFooter className="text-end">
                                <LaddaButton
                                    className="btn btn-primary btn-ladda"
                                    loading={this.state.loading}
                                    data-color="blue"
                                    data-style={ZOOM_OUT}
                                    onClick={this.handleSubmit}
                                    disabled={false}>
                                    Generate
                                </LaddaButton>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>

      );
    }
}

function mapStateToProps({ accounts, socketioProgress }) {
  return {
    accounts,
    progressBars: socketioProgress.progressBars
  };
}

export default connect(mapStateToProps)(LOAGenerator);
