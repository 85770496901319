import React, { useState } from 'react';
import FilteredTable from '../../../components/FilteredTable';
import { Alert, Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import generateFormFields from '../../../helpers/FormFieldGenerator';
import moment from 'moment';
import { resolveState, stateMap } from '../../../utils/Cases/CasesService';

const ServiceAvailability = ({
    data,
    configItems,
    periodFrom,
    periodTo,
    modifyIncident,
    removeIncident,
    removeConfigItem,
    optionSets,
    sites
  }) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [currentFormData, setCurrentFormData] = useState({});

    const edit = (caseNumber) => {
      setModalOpen(true);
      setCurrentFormData(data.find(incident => incident.caseNumber === caseNumber));
    };

    const incidentsColumns = [
      { accessor: 'caseNumber', Header: 'Case Number' },
      { accessor: 'customer.name', Header: 'Customer' },
      { accessor: 'title', Header: 'Case Title' },
      { accessor: 'createdOn', Header: 'Created On' },
      {
        accessor: 'priority',
        Header: 'Priority',
        Cell: props => {
          const option = optionSets.priority.options.find(optionSet => optionSet.value === Number(props.value));
          if (option) {
            return option.label;
          }
          return props.value;
        }
      },
      { accessor: 'totalElapsedTime', Header: 'Time Open' },
      { accessor: 'totalhSoAndSupplierElapsedTime', Header: 'Total hSo & Supplier Elapsed Time' },
      { accessor: 'totalCustomerElapsedTime', Header: 'Total Customer Elapsed Time' },
      {
        accessor: 'state',
        Header: 'Status',
        Cell: props => {
          const option = resolveState(props.value);
          if (option) {
            return option.label;
          }
          return props.value;
        }
      },
      {
        accessor: 'faultyDeviceId',
        Header: 'CI Name',
        Cell: props => {
          const option = configItems.find(configItem => configItem.id === props.value);
          if (option) {
            return option.name;
          }
          return props.value;
        }
      },
      {
        accessor: 'caseNumber',
        width: 40,
        sortable: false,
        filterable: false,
        Cell: props => <Button size="sm" color="info" onClick={() => edit(props.value)}><i
          className="fa fa-edit"/></Button>
      },
      {
        accessor: 'caseNumber',
        width: 40,
        sortable: false,
        filterable: false,
        Cell: props => <Button size="sm" color="danger" onClick={() => removeIncident(props.value)}><i
          className="fa fa-remove"/></Button>
      }
    ];

    const configItemColumns = [
      { accessor: 'site.name', Header: 'Customer Site' },
      { accessor: 'name', Header: 'Name' },
      { accessor: 'total', Header: 'Total' },
      {
        accessor: 'id',
        width: 40,
        sortable: false,
        filterable: false,
        Cell: props => <Button size="sm" color="danger" onClick={() => removeConfigItem(props.value)}><i
          className="fa fa-remove"/></Button>
      }
    ];

    const toggleForm = () => setModalOpen(prevState => !prevState);

    const handleInput = (event) => {
      setCurrentFormData({ ...currentFormData, [event.target.id]: event.target.value });
    };

    const handleInputDate = (field, value) => {
      setCurrentFormData({ ...currentFormData, [field]: moment(value).format('YYYY-MM-DD HH:mm') });
    };

    const handleSelectInput = (field, selected) => {
      let selectedValue = selected ? selected.value : null;
      if (selectedValue) {
        if (field === 'customer') {
          selectedValue = sites.find((option) => option.accountid === selectedValue);
        } else if (field === 'state') {
          selectedValue = resolveState(Number(selectedValue));
          selectedValue = selectedValue ? selectedValue.id : '';
        } else if (optionSets[field]) {
          selectedValue = optionSets[field].options.find((option) => option.value === Number(selectedValue));
          selectedValue = selectedValue ? selectedValue.value : '';
        }
      }
      setCurrentFormData({ ...currentFormData, [field]: selectedValue });
    };

    const getSelectOptions = (field) => {
      if (field === 'customer') {
        return sites
          .map((option) => {
            return {
              value: option.accountid,
              label: option.name
            };
          });
      } else if (field === 'faultyDeviceId') {
        return configItems
          .map((option) => {
            return {
              value: option.id,
              label: option.name
            };
          });
      } else if (field === 'state') {
        return stateMap
          .map((option) => {
            return {
              value: option.id,
              label: option.label
            };
          });
      } else if (optionSets[field]) {
        return optionSets[field].options.map((option) => {
          return {
            value: option.value,
            label: option.label
          };
        });
      }
      return [];
    };

    const getSelectedOption = (field) => {
      let selected = [];
      if (field === 'customer') {
        selected = sites.filter((option) => currentFormData[field] && option.accountid === currentFormData[field].accountid);
      } else if (field === 'faultyDeviceId') {
        selected = configItems.filter((option) => option.id === currentFormData[field]);
      } else if (field === 'state') {
        selected = stateMap.filter(option => option.id === Number(currentFormData[field]));
      } else if (optionSets[field]) {
        selected = optionSets[field].options.filter(option => option.value === Number(currentFormData[field]));
      }
      if (selected.length) {
        return selected[0];
      }

      return null;
    };

    const form = {
      caseNumber: { label: 'Case Number', type: 'text', readOnly: true },
      customer: { label: 'Customer', type: 'select' },
      title: { label: 'Title', type: 'text' },
      createdOn: { label: 'Created On', type: 'datetime' },
      priority: { label: 'Priority', type: 'select' },
      totalElapsedTime: { label: 'Downtime', type: 'number' },
      totalhSoAndSupplierElapsedTime: { label: 'Downtime', type: 'number' },
      totalCustomerElapsedTime: { label: 'Downtime', type: 'number' },
      state: { label: 'State', type: 'select' },
      faultyDeviceId: { label: 'CI Name', type: 'select' }
    };

    const save = () => {
      modifyIncident(currentFormData);
      setCurrentFormData({});
      setModalOpen(false);
    };

    const resolvedConfigItems = configItems.map(({ id, name, assignedTo }) => {
      return {
        id,
        site: sites.find(site => site.accountid === assignedTo.accountid),
        name,
        total: data.reduce((accumulator, currentValue) => id === currentValue.faultyDeviceId ? accumulator + currentValue.totalhSoAndSupplierElapsedTime : accumulator, 0)
      };
    });

    const totalUnavailability = resolvedConfigItems.reduce((a, { total }) => a + total, 0); // 1-H18/(132*31*24*60)

    const minutesDiff = moment(periodTo).endOf('day').diff(moment(periodFrom), 'minutes');

    const achievedAvailability = 1 - totalUnavailability / (resolvedConfigItems.length * minutesDiff);

    return (
      <>
        <FilteredTable
          data={data}
          columns={incidentsColumns}
          hideFilter={true}
        />
        <Alert color="info">
            <strong>Total Device Unavailability:</strong> {totalUnavailability}<br/>
            <strong>Monthly Achieved Availability (minutes):</strong> {(100 * achievedAvailability).toFixed(2)}%
        </Alert>
        <FilteredTable
          data={resolvedConfigItems}
          columns={configItemColumns}
          defaultSorted={{ id: 'site.name', desc: false }}
          hideFilter={true}
        />
        <Modal isOpen={modalOpen} toggle={toggleForm}
               className="modal-info modal-dialog-centered">
          {currentFormData.caseNumber ? (
            <>
              <ModalHeader toggle={toggleForm}>{currentFormData.title}</ModalHeader>
              <ModalBody>
                <Row className="mb-3">
                  <Col className={'d-flex justify-content-end'}>
                    <Button className="me-2" size={'sm'} color={'primary'} onClick={save}>
                      <i className="fa fa-save"/>
                    </Button>
                  </Col>
                </Row>
                <Row form>
                  {generateFormFields({
                    data: currentFormData,
                    fields: form,
                    getSelectOptions,
                    getSelectedOption,
                    handleSelectInput,
                    handleInput,
                    handleInputDate
                  })}
                </Row>
              </ModalBody>
            </>
          ) : ''}
        </Modal>
      </>
    );
  }
;

export default ServiceAvailability;
