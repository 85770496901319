import isEmpty from 'lodash.isempty';
import { Button, Input, InputGroup } from 'reactstrap';
import React from 'react';
import ReactFileReader from 'react-file-reader';

const NoteForm = (data, fileHandler, removeAttachment, downloadAttachment) => {
  const form = {
    subject: {
      label: 'Subject',
      type: 'text',
      mandatory: true,
      width: 12
    },
    description: {
      label: 'Description',
      type: 'textarea',
      mandatory: true,
      width: 12
    },
    attachment: {
      label: 'Attachment',
      type: 'custom',
      width: 12,
      button: <i className='fa fa-trash'/>,
      callBack: () => {
        return (
          <InputGroup>
            <Input
              disabled={true}
              value={data.attachment?.name}
            />
            <Button onClick={removeAttachment}>
              <i className={'fa fa-trash'}/>
            </Button>
          </InputGroup>
        )
      }
    }
  }

  if(data.id && !isEmpty(data.attachment)){
    form.attachment = {
      label: 'Attachment',
      type: 'custom',
      width:12,
      callBack: () => {
        return (
          <InputGroup>
            <Input
              disabled={true}
              value={data.attachment.name}
            />
            <Button onClick={downloadAttachment}>
              <i className={'fa fa-cloud-download'}/>
            </Button>
            <Button onClick={removeAttachment}>
              <i className={'fa fa-trash'}/>
            </Button>
          </InputGroup>
        )
      }
    }
  }
  if(isEmpty(data.attachment)){
    form.attachment = {
      label: 'Attachment',
      type: 'custom',
      callBack: () => <ReactFileReader
        handleFiles={fileHandler}
        fileTypes={['.mpp', '.png', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.png', '.jpeg', '.txt', '.vsdx']}
      >
        <Button size={'sm'} color={'secondary'}>
          Browse
        </Button>
      </ReactFileReader>,
    }
  }

  return form

}

export default NoteForm
