import React, { useEffect, useState } from 'react'
import {
  createNewZoneRecord,
  deleteZoneRecord,
  getZoneRecord,
  updateZoneRecord
} from '../../utils/DNS/DNS';
import { SpinnerInfo } from '../../components/Spinner/Spinner';
import {
  Alert, Button,
  Card,
  CardBody,
  CardText,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import classnames from 'classnames';
import RecordTable, { RecordTypes } from './Records/RecordTable';
import SOATable from './Records/SOATable';
import NewRecord, { typeMap } from './Records/NewRecord';
import ipRegex from 'ip-regex';
import { successFeedback, errorFeedback } from '../../actions/feedback';
import { useDispatch } from 'react-redux'
import { setConfirmDialog } from '../../actions/dialogs'
import { useParams } from 'react-router-dom'

const DNSZone = () => {
  const { ZoneID } = useParams();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [zone, setZone] = useState({});
  const [records, setRecords] = useState({});
  const [error, setError] = useState({});
  const [updating, setUpdating] = useState({});
  const [creating, setCreating] = useState({});
  const [newRecord, setNewRecord] = useState({});

  useEffect(() => {
    getZoneRecord(ZoneID).then((result) => {
      if (result.status === 200) {
        const newRecords = {};
        result.data.records.forEach((record) => {
          if (!newRecords[record.type]) {
            newRecords[record.type] = [];
          }
          if (record.type === 'SOA') {
            const split = record.content.split(' ');
            record.rns = split[0];
            record.mbox = split[1];
            record.serial = split[2];
            record.expire = split[5];
            record.minimum = split[6];
          }
          newRecords[record.type].push(record);
        });
        delete result.data.records;
        setZone(result.data);
        setRecords(newRecords);
      } else {
        setError({ fetch: true });
      }
      setLoading(false);
    });
  }, [ZoneID]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const isReversedZone = () => {
    return /\.arpa$/.test(zone.name);
  };

  const handleRecordInput = (type, event) => {
    const newRecords = { ...records };
    if (type === 'SOA') {
      newRecords.SOA[0][event.target.id] = event.target.value;
    }
    setRecords(newRecords);
  };

  const handleUpdate = (type) => {
    const newUpdating = { ...updating };
    newUpdating[type] = true;
    setUpdating(newUpdating);
    if (type === 'SOA') {
      const record = records.SOA[0];
      updateZoneRecord(zone.name, record.id, { minimum: record.minimum, expire: record.expire }).then((result) => {
        if (result.status === 200) {
          dispatch(successFeedback('Record successfully updated'));
        } else if (result.status === 422) {
          setError({ update: true, message: result.data });
        } else {
          dispatch(errorFeedback('There was an error with your request'));
        }
        newUpdating[type] = false;
        setUpdating(newUpdating);
      });
    }
  };

  const handleDeleteRecord = (type, recordId) => {
    const newUpdating = { ...updating };
    newUpdating[type] = true;
    setUpdating(newUpdating);
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "You are about to remove this record!",
      proceed: () => {
        deleteZoneRecord(zone.name, recordId).then((result) => {
          if (result.status === 200) {
            removeRecordFromState(type, recordId);
            dispatch(successFeedback('Record successfully deleted'));
          } else {
            dispatch(errorFeedback('There was an error with your request'));
          }
          newUpdating[type] = false;
          setUpdating(newUpdating);
        });
      },
      cancel: () => {
        newUpdating[type] = false;
        setUpdating(newUpdating);
      }
    }));
  };

  const removeRecordFromState = (type, id) => {
    const newRecords = { ...records };
    newRecords[type] = newRecords[type].filter(record => record.id !== id);
    setRecords(newRecords);
  };

  const openNewRecordForm = (type) => {
    const newRecordState = {};
    newRecordState[type] = {};
    setNewRecord(newRecordState);
  };

  const cancelAddRecord = (type) => {
    const newRecordState = { ...newRecord };
    delete newRecordState[type];
    setNewRecord(newRecordState);
  };

  const createNewRecord = () => {
    const key = Object.keys(newRecord);
    if (validateNewRecordInput(key[0])) {
      const newCreating = {};
      newCreating[key[0]] = true;
      setCreating(newCreating);
      createNewZoneRecord(zone.name, key[0], newRecord[key[0]]).then((result) => {
        if (result.status === 200) {
          const newRecords = { ...records };
          if (!newRecords[key[0]]) {
            newRecords[key[0]] = [];
          }
          newRecords[key[0]].unshift(result.data);
          setRecords(newRecords);
          dispatch(successFeedback('New record successfully created'));
          cancelAddRecord(key[0]);
        } else {
          dispatch(errorFeedback('There was an error with your request'));
        }
        newCreating[key[0]] = false;
        setCreating(newCreating);
      });
    }
  };

  const validateNewRecordInput = (type) => {
    const newRecordState = { ...newRecord };
    const recordTemplate = typeMap[type].slice(0);
    const errors = {};
    errors[type] = {};
    recordTemplate.forEach(({ id }) => {
      const value = newRecordState[type][id];
      if (!value) {
        errors[type][id] = 'You must provide a value';
      } else if (type === 'A' && id === 'content' && !ipRegex.v4({ exact: true }).test(value)) {
        errors.A.content = 'You must provide a valid Ipv4 address';
      } else if (type === 'AAAA' && id === 'content' && !ipRegex.v6({ exact: true }).test(value)) {
        errors.AAAA.content = 'You must provide a valid Ipv6 address';
      } else if (type === 'NS' && id === 'content' && !/^[*]?[.a-z0-9-]{3,200}$/.test(value)) {
        errors[type][id] = 'A valid nameserver address must be supplied';
      } else if (id === 'prio' && !/^[0-9]+$/.test(value)) {
        errors[type][id] = 'Priority must be a whole number where a lower number indicates a higher preference.';
      } else if ((id === 'host' || (type === 'CNAME' && id === 'content') || (type === 'MX' && id === 'content')) && !/^[.a-z0-9-_*]+$/.test(value)) {
        errors[type][id] = 'Invalid characters were found in the host. Please use only a-z, 0-9, -, _, * and . (period).';
      } else if (type === 'PTR' && id === 'name' && !/^[0-9.a-fA-F]+$/.test(value)) {
        errors[type][id] = 'A valid IN_ADDR address must be supplied';
      }
    });
    newRecordState.errors = errors;
    setNewRecord(newRecordState);
    return Object.keys(errors[type]).length === 0;
  };

  const handleUpdateNewRecord = (type, event) => {
    const newRecordState = { ...newRecord };
    newRecordState[type][event.target.name] = event.target.value;
    setNewRecord(newRecordState);
  };

  if (loading) {
    return (
      <div className="app flex-row align-items-center animated fadeIn">
        <div className="container">
          <div className="justify-content-center">
            <SpinnerInfo />
          </div>
        </div>
      </div>
    );
  } else if (error.fetch) {
    return (
      <div className="row d-flex justify-content-center">
        <Alert color={'danger'} className={'text-center'}>
          <CardText>Unfortunately we are having difficulties with gathering information related to your DNS service.</CardText>
          <CardText>Please contact support for further assistance.</CardText>
        </Alert>
      </div>
    );
  }

  return (
    <div className="fadeIn">
      <Card>
        <CardHeader>{zone.name}</CardHeader>
        <CardBody className="d-flex flex-column justify-content-center align-items-center">
          <Nav tabs className={'d-flex justify-content-center'}>
            <NavItem>
              <NavLink
                href="#"
                className={classnames({ active: activeTab === 0 })}
                onClick={() => { toggle(0); }}
              >
                SOA
              </NavLink>
            </NavItem>
            {Object.keys(isReversedZone() ? RecordTypes.reverse : RecordTypes.normal).map((key, index) => (
              <NavItem key={key}>
                <NavLink
                  href="#"
                  className={classnames({ active: activeTab === index + 1 })}
                  onClick={() => { toggle(index + 1); }}
                >
                  {key}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent className={'col-md-12'} activeTab={activeTab}>
            <TabPane tabId={0}>
              <Row className={'d-flex justify-content-center'}>
                <Col md={8} sm={12} className={'pt-3'}>
                  {records.SOA && (
                    <SOATable
                      data={records.SOA[0]}
                      handleInput={(event) => handleRecordInput('SOA', event)}
                      handleUpdate={() => handleUpdate('SOA')}
                      updating={updating.SOA}
                    />
                  )}
                  {!records.SOA && <NoDataAlert type={'SOA'} />}
                </Col>
              </Row>
            </TabPane>
            {Object.keys(isReversedZone() ? RecordTypes.reverse : RecordTypes.normal).map((key, index) => (
              <TabPane tabId={index + 1} key={'tab' + index}>
                <Row className={'d-flex justify-content-center'}>
                  <Col md={8} sm={12} className={'pt-3'}>
                    {newRecord[key] && !creating[key] && (
                      <div className="border border-secondary p-3 mb-3">
                        <NewRecord
                          type={key}
                          data={newRecord}
                          cancel={() => cancelAddRecord(key)}
                          updateInput={(event) => handleUpdateNewRecord(key, event)}
                          create={createNewRecord}
                          name={zone.name}
                        />
                      </div>
                    )}
                    {creating[key] && (
                      <div className="border border-secondary p-3 mb-3">
                        <SpinnerInfo />
                      </div>
                    )}
                    {records[key] && records[key].length > 0 && activeTab === (index + 1) && (
                      <RecordTable
                        type={key}
                        data={records[key]}
                        handleDelete={(recordId) => handleDeleteRecord(key, recordId)}
                        updating={updating.NS}
                        openNewRecordForm={() => openNewRecordForm(key)}
                        reverse={isReversedZone()}
                      />
                    )}
                    {(!records[key] || !records[key].length) && !newRecord[key] && <NoDataAlert openNewRecordForm={() => openNewRecordForm(key)} type={key} />}
                  </Col>
                </Row>
              </TabPane>
            ))}
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
};

const NoDataAlert = ({ type, openNewRecordForm }) =>{
  return (
        <div className={'text-center'}>
            <Alert color={'warning'} className={'text-center'}>You have no {type} record for this zone</Alert>
            <Button color={'danger'} outline onClick={openNewRecordForm}>Add new record</Button>
        </div>

  );
};

export default DNSZone;
