import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row, Card, CardHeader, CardBody, Badge } from 'reactstrap';
import { api_circuitReport } from '../../utils/Reports/CircuitReconcilliation';
import { SpinnerInfo } from '../../components/Spinner/Spinner';

class CircuitRow extends React.Component {
  render() {
    let color = 'success';
    if (this.props.circuit.orderStatus !== 2 || this.props.product === null || !this.props.product.isActive) {
      color = 'danger';
    }

    return (
            <Row className={'callout callout-' + color} style={{ paddingBottom: '6px', marginBottom: '10x' }}>
                <Col md={5}><Link to={this.props.circuit.url} target="_blank">{this.props.circuit.carrierCircuitNumber}</Link><p style={{ color: '#808080', fontSize: '9px' }}>Circuit ID</p>
                    {this.props.circuit.name}<p style={{ color: '#808080', fontSize: '9px' }}>Circuit Name</p>
                </Col>
                <Col md={5}>
                    {this.props.circuit.assignedTo.name}<p style={{ color: '#808080', fontSize: '9px' }}>Customer</p>
                    {this.props.product === null ? 'Unknown' : <Link to={this.props.product.url} target="_blank">{this.props.product.productNumber}</Link>}<p style={{ color: '#808080', fontSize: '9px' }}>Product</p>
                </Col>
                <Col md={2}>
                    {this.props.circuit.orderStatus !== 2 ? <Badge color="danger">Circuit Not Live</Badge> : <Badge color="success">Circuit Live</Badge>}
                    <br/>
                    {this.props.product === null ? <Badge color="danger">No Assigned Product</Badge> : (!this.props.product.isActive ? <Badge color="danger">Inactive Product!</Badge> : <Badge color="success">Valid Active Product</Badge>)}
                </Col>
            </Row>
    );
  }
}

class CircuitReconcilliation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      submitted: false,
      data: {}
    };

    this.circuits = React.createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    this.setState({ loading: true, submitted: true });
    api_circuitReport(this.circuits.value).then((result) => {
      if (result.status === 200) {
        this.setState({ loading: false, data: result.data });
      }
    }).catch((error) => {
      alert(error);
    });
  }

  render() {
    return (
            <div className="animated fadeIn">
                <Row>
                    <Col md={2}>
                        <Card>
                            <CardHeader>Enter Circuits</CardHeader>
                            <CardBody>
                                <Form>
                                    <FormGroup>
                                        <Label>Circuit IDs</Label>
                                        <Input style={{ height: '250px' }} type="textarea" innerRef={input => (this.circuits = input)}/>
                                    </FormGroup>
                                    <Button onClick={this.handleSubmit}>Go</Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    {this.state.submitted === true
                    && <Col md={10}>
                            {this.state.loading === true
                            && <div className="justify-content-center">
                                <SpinnerInfo/>
                            </div>
                            }
                            {this.state.loading === false
                                && <div>
                                    {Object.keys(this.state.data).map((circuit) => <Card>
                                            <CardHeader>{circuit}</CardHeader>
                                            <CardBody style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                                            {this.state.data[circuit].length === 0
                                            && <Row className={'callout callout-danger'}>No matching circuits found</Row>
                                            }
                                            {this.state.data[circuit].map((ci) => <CircuitRow {...ci}/>)}
                                            </CardBody>
                                        </Card>)}
                                </div>
                            }
                    </Col>
                    }
                </Row>
            </div>
    );
  }
}

function mapStateToProps({ helpers }) {
  return {
    suppliers: helpers.suppliers,
    optionSets: helpers.optionSets
  };
}
export default connect(mapStateToProps)(CircuitReconcilliation);
