import { connect, useSelector } from 'react-redux'
import generateFormFields from '../../helpers/FormFieldGenerator';
import React, { useEffect, useState } from 'react';
import { p2pReprice, wanReprice } from './forms';
import { Card, CardHeader, CardBody, Row, Col, Button, Alert } from 'reactstrap';
import isFloat from 'validator/lib/isFloat';
import isInt from 'validator/lib/isInt';
import { api_repriceCompareQuote } from '../../utils/PricingTool';
import { useNavigate } from 'react-router-dom'
import LoadingOverlay from '../../components/LoadingOverlay';

const RePrice = (props) => {

  // router
  const navigate = useNavigate();

  // redux
  const quote = useSelector(state => state.pricingToolQuote)

  const [data, setData] = useState({
    sites: [],
    p2p: []
  });
  const [formErrors, setFormErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData({
      name: quote.data.name,
      sites: quote.data.sites.reduce((result, site) => {
        const preferredPrice = site.prices.find(price => price.preferred === true);
        const carrierPrice = site.carrier_prices.find(price => preferredPrice.carrier.id === price.carrier.id);
        result.push({
          id: site.id,
          uuid: site.uuid,
          address: site.address,
          postcode: site.postcode,
          cdr: site.cdr,
          bearer: site.bearer_speed,
          install: carrierPrice.install,
          rental: carrierPrice.rental,
          carrier: { ...preferredPrice.carrier },
          carrierPrice: { ...carrierPrice },
          kit: { ...site.kit },
          kitPrice: site.kit.cost,
          reference: carrierPrice.reference,
          speeds: {
            cdr: site.cdr,
            bearer: site.bearer_speed
          }
        });
        return result;
      }, []),
      p2p: quote.data.p2p.reduce((result, site) => {
        const preferredPrice = site.prices.find(price => price.preferred === true);
        const carrierPrice = site.carrier_prices.find(price => preferredPrice.carrier.id === price.carrier.id);
        result.push({
          id: site.id,
          uuid: site.uuid,
          a_address: site.a_address,
          b_address: site.b_address,
          a_postcode: site.a_postcode,
          b_postcode: site.b_postcode,
          cdr: site.cdr,
          bearer: site.bearer_speed,
          install: carrierPrice.install,
          rental: carrierPrice.rental,
          carrier: { ...preferredPrice.carrier },
          carrierPrice: { ...carrierPrice },
          kit: { ...site.kit },
          kitPrice: site.kit.cost,
          reference: carrierPrice.reference,
          speeds: {
            cdr: site.cdr,
            bearer: site.bearer_speed
          }
        });
        return result;
      }, [])
    });
  }, []);

  const resolveSites = () => {
    return quote.data.sites.length ? 'sites' : 'p2p';
  };
  const updateSiteField = (siteId, field) => {
    const sites = resolveSites();
    setData({
      ...data,
      [sites]: data[sites].map(site => {
        if (siteId === site.id) {
          return { ...site, ...field };
        }
        return site;
      })
    });
  };
  const rePrice = () => {

    if (validated()) {
      setLoading(true);
      let request = {
        name: data.name
      };
      const sites = resolveSites();
      request[sites] = data[sites].map((site) => {
        let result = {
          uuid: site.uuid,
          reference: site.reference,
          carrier: site.carrier.id,
          rental: site.rental,
          install: site.install
        };
        if (parseFloat(site.kitPrice) !== site.kit.cost) {
          result.kitPrice = site.kitPrice;
        }
        if (parseInt(site.cdr) !== site.speeds.cdr) {
          result.cdr = site.cdr;
        }
        if (parseInt(site.bearer) !== site.speeds.bearer) {
          result.bearer = site.bearer;
        }
        return result;
      });
      api_repriceCompareQuote(quote.data.id, request)
        .then((result) => {
          if (result.status === 200) {
            navigate(`/sales/pricing-tool/${result.data.uuid}`);
          } else if (result.status === 422) {
            setFormErrors(Object.values(result.data));
          }
          setLoading(false);
        });
    }
  };
  const validated = () => {
    const sites = resolveSites();
    const errors = [];
    if (!data.name) {
      errors.push('You must provide a name');
    }
    if (!hasChanges()) {
      errors.push('You must give me something new');
    }
    data[sites].forEach((site, index) => {
      ['rental', 'install', 'kitPrice'].forEach(field => {
        if (site[field] === '') {
          errors.push(`You must provide ${field} on Site #${index + 1}`);
        } else if (!isFloat(`${site[field]}`)) {
          errors.push(`${field} is not a valid number on Site #${index + 1}`);
        }
      });
      ['cdr', 'bearer'].forEach(field => {
        if (!site[field]) {
          errors.push(`You must provide ${field} on Site #${index + 1}`);
        } else if (!isInt(`${site[field]}`)) {
          errors.push(`${field} is not a valid integer on Site #${index + 1}`);
        }
      });
      if (!site.reference) {
        errors.push(`You must provide Reference on Site #${index + 1}`);
      }
    });
    setFormErrors(errors);
    return errors.length === 0;
  };

  const hasChanges = () => {
    const sites = resolveSites();
    const changed = data[sites].filter((site) => {
      if (
        parseFloat(site.rental) !== site.carrierPrice.rental ||
        parseFloat(site.install) !== site.carrierPrice.install ||
        parseFloat(site.kitPrice) !== site.kit.cost ||
        parseInt(site.cdr) !== site.speeds.cdr ||
        parseInt(site.bearer) !== site.speeds.bearer

      ) {
        return true;
      }
      return false;
    });

    return changed.length > 0;
  };

  return (
    <div>
      <LoadingOverlay loading={loading}>
        {formErrors.length > 0 &&
        <Row>
          <Col>
            <Alert color={'danger'}>
              {formErrors.map((error, index) => {
                return <p className={'mb-0'} key={`error-${index}`}>{error}</p>;
              })}
            </Alert>
          </Col>
        </Row>
        }
        <Row className={'d-flex flex-row justify-content-between'}>
          {generateFormFields({
            fields: wanReprice.header,
            handleInput: (event) => {
              setData({
                ...data,
                [event.target.id]: event.target.value
              });
            },
            data
          })}
          <Col md={4}>
            <Button
              color={'primary'}
              className={'float-end'}
              onClick={rePrice}
            >
              Re-Price
            </Button>
          </Col>

        </Row>

        <div>
          {data.sites.map((site, index) => {
            return (
              <Card key={`site-${index}`} outline color={'secondary'}>
                <CardHeader className={'bg-secondary d-flex flex-row justify-content-between'}>
                  <span>{`Site #${index + 1}`}</span>
                </CardHeader>
                <CardBody>
                  <Row>
                    {generateFormFields({
                      fields: wanReprice.sites,
                      handleInput: (event) => {updateSiteField(site.id, { [event.target.id]: (event.target.value) });},
                      data: site
                    })}
                  </Row>

                </CardBody>
              </Card>
            );
          })}
          {data.p2p.map((site, index) => {
            return (
              <Card key={`site-${index}`} outline color={'secondary'}>
                <CardHeader className={'bg-secondary d-flex flex-row justify-content-between'}>
                  <span>{`Site #${index + 1}`}</span>
                </CardHeader>
                <CardBody>
                  <Row>
                    {generateFormFields({
                      fields: p2pReprice.sites,
                      handleInput: (event) => {updateSiteField(site.id, { [event.target.id]: (event.target.value) });},
                      data: site
                    })}
                  </Row>

                </CardBody>
              </Card>
            );
          })}
        </div>
      </LoadingOverlay>

    </div>
  );

};
export default RePrice;
