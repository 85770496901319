import React, { useEffect, useState } from 'react';
import {connect} from "react-redux";
import {
  netapi_addRrGroup,
  netapi_getDevices,
  netapi_getRrGroups,
  netapi_setRrGroupMemberMode
} from "../../../actions/netapi";
import {
  Card,
  CardBody,
  Col,
  Row,
  Badge,
  CardHeader,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  FormFeedback
} from "reactstrap";
import {Link} from "react-router-dom";
import {SelectMod} from "../../../components/Selects/SelectMod";

const RrGroups = (props) => {
  useEffect(() => {
    props.fetchRrData();
    props.fetchDevices();
  }, []);

  const [modalOpen, setModalState] = useState(false);
  const [newTitle, setNewTitle] = useState(null);
  const [newPrimary, setNewPrimary] = useState(null);
  const [newSecondary, setNewSecondary] = useState(null);
  const [errors, setErrors] = useState(null);

  return(<Card>
    <CardHeader>
      <Row>
        <Col md={6}>Route Reflector Groups</Col>
        <Col md={6} className="text-end"><Button size={"sm px-4"} onClick={() => { setModalState(true)}}>Add New</Button></Col>
      </Row>
    </CardHeader>
    <CardBody>
    <Row>
    {props.netapi.rrgroups.data.map((g) => {
      let cn = 'card-accent-success';
      if(g.members.filter(m => m.is_secondary).length === 0) {
        cn = 'card-accent-warning';
      }
      if(g.members.filter(m => m.is_primary).length === 0) {
        cn = 'card-accent-danger';
      }
      return (
      <Col md={4} key={'group'+g.id}>
        <Card className={cn}>
          <CardHeader>
            {g.group_name}
          </CardHeader>
          <CardBody>
            {g.members.map(d => <Row style={{marginBottom: '2px'}} key={d.id}>
              <Col md={7}>
                {d.device.hostname}
              </Col>
              <Col md={2}>
                {d.is_primary && <Badge color={"warning"}>PRI</Badge>}
                {d.is_secondary && <Badge color={"info"}>SEC</Badge>}
              </Col>
              <Col md={3}>
                <UncontrolledButtonDropdown size={"sm"}>
                  <DropdownToggle caret color={"primary"}>
                    Action
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => {
                      props.setMemberMode(g.id, d.id, 'primary');
                    }}>
                      Make Primary
                    </DropdownItem>
                    <DropdownItem onClick={() => {
                      props.setMemberMode(g.id, d.id, 'secondary');
                    }}>
                      Make Secondary
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </Col>
            </Row>)}
          </CardBody>
        </Card>
      </Col>
      );
    })}
  </Row>
      <Modal isOpen={modalOpen}>
        <ModalHeader>
          Add a Route Reflector Group
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Name</Label>
            <Input name={"name"} placeholder={"e.g. telehouse"}
                   onChange={(e) => setNewTitle(e.target.value)}
                   invalid={errors?.group_name}
            />
            {errors?.group_name &&
            <FormFeedback>{errors.group_name.map(e => <p>{e}</p>)}</FormFeedback>}
          </FormGroup>
          <FormGroup>
            <Label>Primary VRR</Label>
            <SelectMod
              options={props.netapi.devices.data.filter(d => d.dtype === 'vrr')}
              getOptionLabel={o => o.hostname}
              getOptionValue={o => o.id}
              isOptionDisabled={o => o.id === newSecondary}
              onChange={(o) => {setNewPrimary(o.id); }}
              className={errors?.vrr_1 ? 'is-invalid' : ''}
            />
            {errors?.vrr_1 &&
            <FormFeedback>{errors.vrr_1.map(e => <p>{e}</p>)}</FormFeedback>}
          </FormGroup>
          <FormGroup>
            <Label>Secondary VRR</Label>
            <SelectMod
              options={props.netapi.devices.data.filter(d => d.dtype === 'vrr')}
              getOptionLabel={o => o.hostname}
              getOptionValue={o => o.id}
              isOptionDisabled={o => o.id === newPrimary}
              onChange={(o) => {setNewSecondary(o.id); }}
              className={errors?.vrr_2 ? 'is-invalid' : ''}
            />
            {errors?.vrr_2 &&
            <FormFeedback>{errors.vrr_2.map(e => <p>{e}</p>)}</FormFeedback>}
          </FormGroup>
        </ModalBody>
        <ModalFooter className="text-end">
          <Button color={"danger"} onClick={() => setModalState(false)}>Close</Button>
          <Button color={"primary"} onClick={() => {
            props.addGroup(newTitle, newPrimary, newSecondary).then(() => {
              setModalState(false);
            }).catch((e) => {
              setErrors(e.data.message);
            });
          }}>Add</Button>
        </ModalFooter>
      </Modal>
    </CardBody>
  </Card>);
};

const mapStateToProps = ({netapi}) => ({netapi});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRrData: () => dispatch(netapi_getRrGroups(true)),
    fetchDevices: () => dispatch(netapi_getDevices()),
    setMemberMode: (group, memberid, mode) => dispatch(netapi_setRrGroupMemberMode(group, memberid, mode)),
    addGroup: (group_name, vrr_1, vrr_2) => dispatch(netapi_addRrGroup(group_name, vrr_1, vrr_2))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RrGroups);
