import React from 'react';
import {SpinnerInfo} from '../../components/Spinner/Spinner';
import {
  addRadiusAttribs,
  newAttributeBox,
  RADIUS_SET_ACTIVE_BOX,
  setActiveUser
} from '../../actions/radius';
import {fetchRadiusAttribs} from '../../actions/radius';
import { connect, useDispatch } from 'react-redux'
import {Card, CardBody, CardHeader, Row, Col} from 'reactstrap';
import AttribBox from './AttribBox';
import AddAttribBox from './AddAttribBox';
import GroupAttributeBox from './GroupAttributeBox';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {PulseLoader} from "react-spinners";
import { useParams } from 'react-router-dom'

class RadiusUserLegacy extends React.Component {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired
      }).isRequired,
      staticContext: PropTypes.object
    }).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      expandUsers: false,
      expandGroups: false
    };
    this.setNewAttributes = this.setNewAttributes.bind(this);
  }

  componentDidMount() {
    if (this.props.activeUser) {
      this.props.dispatch(fetchRadiusAttribs(this.props.activeUser));

    } else {
      this.props.dispatch(setActiveUser(this.props.params.username));
      this.props.dispatch(fetchRadiusAttribs(this.props.params.username));
    }
  }

  setNewAttributes(attribObject) {
    // Pass in a RADIUS Attribute Object
    this.props.dispatch(addRadiusAttribs(this.props.activeUser,
      attribObject,
      this.props.activeAttrib));
  }

  render() {
    if (this.props.fetching) {
      return (
        <div className="app flex-row align-items-center animated fadeIn">
          <div className="container">
            <div className="justify-content-center">
              <SpinnerInfo/>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="animate fadeIn">
          <h4>{this.props.activeUser}</h4>

          <Card className="card-accent-primary">
            <CardHeader>
              <Row>
                <Col md={10}>
                  <p className="d-inline-block m-0">User specific</p>
                </Col>
                <Col md={1} className='d-flex justify-content-end'>
                  {
                    this.props.settingAttrib || this.props.deletingAttrib
                      ? <PulseLoader size={10} color="red" className='float-end' />
                      : null

                  }
                </Col>
                <Col md={1} className="d-flex align-items-center justify-content-end">
                  <div
                    data-for="expandUser"
                    data-tip="expandUser"
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() => this.setState({expandUsers: !this.state.expandUsers})}
                  >
                    <i
                      className="fa fa-expand fa-1x ps-2"
                    >
                    </i>
                    <ReactTooltip
                      id={'expandUser'}
                      type="dark"
                      place="left"
                      effect="float"
                      multiline={false}
                    >Expand
                    </ReactTooltip>
                  </div>
                </Col>
              </Row>
            </CardHeader>

            {
              this.props.attribBox
                ? <AddAttribBox type={this.props.activeAttrib}
                                addAttribFunc={this.setNewAttributes}
                />
                : null
            }
            <CardBody>
              <Row>
                <Col md={this.state.expandUsers ? 12 : 6} xs={12}
                     onClick={() =>
                       this.props.dispatch({
                         type: RADIUS_SET_ACTIVE_BOX,
                         activeBox: 'ucheckattribs'
                       })
                     }
                >
                  <AttribBox
                    title="Check Attributes"
                    data={this.props.attribs.ucheckattribs}
                    type={'ucheckattribs'}
                    username={this.props.activeUser}
                    onClick={() => {
                      this.props.dispatch(newAttributeBox(true))
                    }
                    }
                    addAttribFunc={this.setNewAttributes}
                  />
                </Col>
                <Col md={this.state.expandUsers ? 12 : 6} xs={12}
                     onClick={() =>
                       this.props.dispatch({
                         type: RADIUS_SET_ACTIVE_BOX,
                         activeBox: 'ureplyattribs'
                       })
                     }
                >
                  <AttribBox
                    title="Reply Attributes"
                    data={this.props.attribs.ureplyattribs}
                    type={'ureplyattribs'}
                    username={this.props.activeUser}
                    addAttribFunc={this.setNewAttributes}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="card-accent-secondary">
            <CardHeader>
              <Row>
                <Col md={10}>
                  <p className="d-inline-block m-0">Group specific</p>
                </Col>
                <Col md={2} className="d-flex align-items-center justify-content-end">
                  <div
                    data-for="expandGroup"
                    data-tip="expandGroup"
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() => this.setState({expandGroups: !this.state.expandGroups})}
                  >
                    <i
                      className="fa fa-expand fa-1x ps-2"
                    >
                    </i>
                    <ReactTooltip
                      id={'expandGroup'}
                      type="dark"
                      place="left"
                      effect="float"
                      multiline={false}
                    >Expand
                    </ReactTooltip>
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {
                this.props.attribs.groups
                  ? Object.entries(this.props.attribs.groups).map((e, key) => {
                    const [groupName, data] = e;
                    return (
                      <div key={key}>
                        <h6 className="fw-bold text-capitalize">
                          {groupName}
                        </h6>
                        <Row>
                          <Col md={this.state.expandGroups ? 12 : 6} xs={12}>
                            <GroupAttributeBox
                              title="Check Attributes"
                              data={data['gcheckattribs']}
                              type={'ucheckattribs'}
                            />
                          </Col>
                          <Col md={this.state.expandGroups ? 12 : 6} xs={12}>
                            <GroupAttributeBox
                              title="Reply Attributes"
                              data={data['greplyattribs']}
                              type={'ureplyattribs'}
                            />
                          </Col>
                        </Row>
                        <hr/>
                      </div>
                    );
                  })
                  : null
              }
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

const RadiusUser = (props) => {
  const dispatch = useDispatch()
  const params = useParams()
  return <RadiusUserLegacy {...props} dispatch={dispatch} params={params}/>
}

function mapStateToProps(state) {
  return {
    fetching: state.radius.fetchingAttribs,
    activeUser: state.radius.activeUser,
    attribs: state.radius.activeUserAttribs,
    attribBox: state.radius.newAttribBox,
    activeAttrib: state.radius.activeAttrib,
    settingAttrib: state.radius.settingNewAttrib,
    deletingAttrib: state.radius.deletingAttrib,
  };
}

export default connect(mapStateToProps)(RadiusUser);
