import React from 'react';
import ReactTable from 'react-table-v6';
import { UncontrolledTooltip } from 'reactstrap';

export const RecordTypes = {
  normal: {
    NS: [{ header: 'Zone', accessor: 'name' }, { header: 'Nameserver', accessor: 'content' }],
    MX: [{ header: 'Zone', accessor: 'name' }, { header: 'Handled By Host', accessor: 'content' }, { header: 'Preference', accessor: 'prio' }],
    A: [{ header: 'Host', accessor: 'name' }, { header: 'IP Address', accessor: 'content' }],
    AAAA: [{ header: 'Host', accessor: 'name' }, { header: 'IP Address', accessor: 'content' }],
    CNAME: [{ header: 'Host', accessor: 'name' }, { header: 'Points to Host', accessor: 'content' }],
    TXT: [{ header: 'Host', accessor: 'name' }, { header: 'Record', accessor: 'content' }],
    SRV: [{ header: 'Host', accessor: 'name' }, { header: 'Priority', accessor: 'prio' }, { header: 'Data', accessor: 'content' }]
  },
  reverse: {
    NS: [{ header: 'Zone', accessor: 'name' }, { header: 'Nameserver', accessor: 'content' }],
    PTR: [{ header: 'IP Address', accessor: 'name' }, { header: 'Hostname', accessor: 'content' }],
    CNAME: [{ header: 'Host', accessor: 'name' }, { header: 'Points to Host', accessor: 'content' }],
    TXT: [{ header: 'Host', accessor: 'name' }, { header: 'Record', accessor: 'content' }]
  }

};

const RecordTable = ({ type, data, handleDelete, openNewRecordForm, updating, reverse }) => {
  const types = reverse ? RecordTypes.reverse : RecordTypes.normal;
  let columns = (types[type]).map((column) => {
    return {
      Header: column.header,
      accessor: column.accessor,
      sortable: false,
      style: { whiteSpace: 'unset' }
    };
  });
  columns.push({
    Header: () => {
      return <div className="h4 text-danger float-end mb-0" onClick={openNewRecordForm} style={{ cursor: 'pointer' }} id={'add' + type}>
                <i className="icon-plus"></i>
                <UncontrolledTooltip placement="right" target={'add' + type}>
                new record
                </UncontrolledTooltip>
            </div>;
    },
    filterable: false,
    sortable: false,
    accessor: 'id',
    width: 150,
    Cell: props => (
            <div className={'h4 text-danger float-end'} style={{ cursor: 'pointer' }} onClick={handleDelete.bind(null, props.value)}><i className="fa fa-trash"></i></div>
    )
  });
  return <ReactTable
        getTheadProps={() =>{
          return {
            style: { border: 'none' }
          };
        }}
        getTheadTrProps={() =>{
          return {
            style: { border: 'none' }
          };
        }}
        getTheadThProps={() =>{
          return {
            style: { border: 'none' }
          };
        }}
        className="animated fadeIn"
        noDataText={'You have no ' + { type } + ' records'}
        loading={updating}
        loadingText={'Processing...'}
        data={data}
        columns={columns}
        minRows="1"
        defaultPageSize={10}
        showPagination={data.length > 10}
        filterable={data.length > 10}
    />;
};

export default RecordTable;
