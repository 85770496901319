import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  createProductSLA,
  getProductSLAs, resetProductSLAFile,
  setProductSLA, updateProductSLA,
  updateProductSLAField,
  updateProductSLAFile
} from '../../actions/productSLAs';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  Row
} from 'reactstrap';
import LoadingOverlay from '../../components/LoadingOverlay';
import { ButtonIcon } from '../../components/ButtonIcon';
import generateFormFields from '../../helpers/FormFieldGenerator';
import { detailedDiff, updatedDiff } from 'deep-object-diff';
import isEmpty from 'lodash.isempty';
import { ProductSLAForm } from './form';
import ReactFileReader from 'react-file-reader';
import { errorFeedback } from '../../actions/feedback';
import ProductSlasTable from '../../components/Tables/ProductSLAs';

const ProductSLAs = ({
  productSLAs, dispatch
}) => {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showSLA, setShowSLA] = useState(false);
  const [isNew, setIsNew]= useState(false);
  const [errors, setErrors] = useState([]);

  const toggleModal = () => {
    setShowSLA(prevState => !prevState)
  }

  useEffect(() => {
    getData();
  }, [])

  const getData = () => {
    setLoading(true)
    dispatch(getProductSLAs())
      .then(() => {
        setLoading(false)
      })
  }

  const selectSLA = (data) => {
    setIsNew(false)
    toggleModal()
    if(data){
      dispatch(setProductSLA(data))
    }else{
      setIsNew(true)
    }

  }

  const hasChanges = () => {
    const diff = detailedDiff(productSLAs.sla.original, productSLAs.sla.form);
    return !isEmpty(diff.added) || !isEmpty(diff.deleted) || !isEmpty(diff.updated) || productSLAs.file.name
  }
  const saveSLA = () => {
    if(validated()){
      setSaving(true)
      if(isNew){
        dispatch(createProductSLA({
          name: productSLAs.sla.form.name,
          file: {...productSLAs.file}
        })).then((result) => {
          if(result?.errors){
            setErrors(Object.values(result.errors))
          }
          setSaving(false)
        })
      }else{
        const diff = updatedDiff(productSLAs.sla.original, productSLAs.sla.form);
        let payload = {};
        if(diff.name){
          payload.name = productSLAs.sla.form.name
        }
        if(diff.s3Key === null){
          payload.file = {...productSLAs.file}
        }
        dispatch(updateProductSLA(productSLAs.sla.original.id, payload)).then((result) => {
          if(result?.errors){
            setErrors(Object.values(result.errors))
          }
          setSaving(false)
        })
      }

    }

  }
  const validated = () => {
    let result = [];
    setErrors(errors);
    if(!productSLAs.sla.form.name){
      result.push('You must provide a valid name')
    }
    if(isNew && !productSLAs.file.name){
      result.push('You must provide a valid file')
    }
    if(!isNew && !productSLAs.sla.form.s3Key && !productSLAs.file.name  ){
      result.push('You must provide a valid file')
    }

    setErrors(result);
    return isEmpty(result)
  }
  const fileHandler = (files) => {
    setSaving(true);
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      dispatch(updateProductSLAFile({
        type: files[0].type,
        name: files[0].name,
        size: files[0].size,
        body: reader.result.split(',')[1],
      }))
      setSaving(false);
    };
    reader.onerror = () => {
      dispatch(errorFeedback('could not convert file'));
      setSaving(false);
    };
  };

  const resetSLA = () => {
    dispatch(updateProductSLAFile({}))
    dispatch(setProductSLA({}))
    setIsNew(false)
    setErrors([]);
  }

  const removeFile = () => {
    dispatch(resetProductSLAFile())
    dispatch(updateProductSLAFile({}))
    dispatch(updateProductSLAField({file : null}))
  }

  return (
    <div className="animated fadeIn">
      <Row>
        <Col md={6}>
          <Card>
            <CardBody>
              <LoadingOverlay loading={loading}>
                <ProductSlasTable
                  slas={productSLAs.list}
                  fetchData={getData}
                  setSla={selectSLA}
                  toggleModal={toggleModal}
                />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={showSLA} className={'modal-lg'} backdrop="static" onClosed={resetSLA}>
        <ModalBody>
          <div className="animated fadeIn">
            <LoadingOverlay loading={saving}>
              <Row className={'mb-4'}>
                <Col className={'d-flex'}>
                  <span><CardTitle>{isNew ? 'New SLA' : productSLAs.sla.original.name}</CardTitle></span>
                  <div className={'ms-auto'}>
                    <ButtonIcon
                      icon={'fa fa-save'}
                      onClick={saveSLA}
                      colour={'secondary'}
                      disabled={!hasChanges() || saving}
                      tooltip={'save'}
                    />
                    <ButtonIcon
                      icon={'fa fa-close'}
                      onClick={toggleModal}
                      colour={'secondary'}
                      disabled={saving}
                      tooltip={'close'}
                    />
                  </div>
                </Col>
              </Row>
              {errors.length > 0
              && <Row>
                <Col>
                  <Alert color={'danger'}>
                    {errors.map((error, index) => {
                      return <div key={'error' + index}>{error}</div>;
                    })}
                  </Alert>
                </Col>
              </Row>
              }
              <Row>
                {generateFormFields(
                  {
                    fields: ProductSLAForm,
                    data: productSLAs.sla.form,
                    handleInput: (event => dispatch(updateProductSLAField({[event.target.id] : event.target.value})))
                  })
                }
              </Row>
              <Row>
                <Col>
                    <FormGroup className={'required'}>
                      <Label className={'control-label w-100'} for={'fileName'}>File</Label>
                      {(productSLAs.file.name || productSLAs.sla.form.s3Key) ?
                        <p className="text-info">{productSLAs.file.name || productSLAs.sla.form.s3Key} <span className='text-danger ms-2 hoverPointer' onClick={removeFile}><i className="fa fa-close"/></span></p>
                        :
                        <ReactFileReader
                          handleFiles={fileHandler}
                          fileTypes={['image/*', 'application/*']}
                        >
                          <Button size={'sm'} color={'primary'}>
                            Browse
                          </Button>
                        </ReactFileReader>
                      }
                    </FormGroup>

                </Col>
              </Row>
            </LoadingOverlay>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )

}

function mapStateToProps({
  productSLAs,
  authenticationState,
}) {
  return {
    productSLAs,
    user: authenticationState.account
  };
}
export default connect(mapStateToProps)(ProductSLAs)
