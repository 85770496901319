import React, { Fragment, useEffect, useState } from 'react';
import {
  CardBody,
  Card,
  CardHeader,
  InputGroup,
  Input,
  Alert,
  Col,
  Row,
  Form
} from 'reactstrap';
import { getCircuit, resetCircuit } from '../../actions/circuit';
import { connect } from 'react-redux';
import Circuit from '../Circuit';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';

const Circuits = (props) => {
  const [searchText, setSearchText] = useState('');
  const [lookingUp, setLookingUp] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const { circuit } = props;

  const lookupCircuit = (event) => {
    setNotFound(false);
    setLookingUp(true);
    props.dispatch(resetCircuit());
    props.dispatch(getCircuit(searchText)).then(
      (result) => {
        if (result && result.status === 404) {
          setNotFound(true);
        }
        setLookingUp(false);
      }

    );
  };

  const handleInput = (event) => {
    setNotFound(false);
    setSearchText(event.target.value);
  };
  return (
        <div className="animated fadeIn">
            <Row className={'mb-3'}>
                <Col md={4}>
                    <Form onSubmit={lookupCircuit}>
                        <InputGroup>
                            <Input value={searchText} placeholder={'Enter circuit ref...'} onChange={handleInput}/>
                            <LaddaButton
                                className="btn btn-primary btn-ladda px-4 me-1"
                                loading={lookingUp}
                                data-style={ZOOM_OUT}
                                onClick={lookupCircuit}
                            >
                                <div className="h5 mb-0">Search</div>
                            </LaddaButton>
                    </InputGroup>
                    </Form>
                </Col>
            </Row>
            {notFound
                && <Row>
                    <Col>
                        <Alert color={'warning'}>Well, it looks like there is no circuit with ref <strong>{searchText}</strong> !</Alert>
                    </Col>
                </Row>

            }
            {circuit.original.id !== undefined
                && <div className="animated fadeIn">
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <strong>{circuit.data?.name || ''}</strong>
                                </CardHeader>
                                <CardBody>
                                    <Circuit/>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>

                </div>

            }
        </div>
  );
};

function mapStateToProps({ circuit }) {
  return {
    circuit
  };
}

export default connect(mapStateToProps)(Circuits);
