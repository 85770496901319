import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Row,
    Col,
    Form,
    InputGroup,
    Spinner
} from 'reactstrap';

import {netapi_getDevices, netapi_submitArpScannerJob} from "../../../actions/netapi";
import {SelectMod} from "../../../components/Selects/SelectMod";

const ArpPpsScanner = (props) => {

    const [device, setDevice] = useState(undefined);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if(props.devices.data.length === 0) {
            props.dispatch(netapi_getDevices());
        }
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        setProcessing(true);
        props.dispatch(netapi_submitArpScannerJob(device)).then(() => {
            setProcessing(false);
            props.dispatch({ type: 'TOAST', title: 'Job submitted, check log tail for results.', msgtype: 'success' })
            setDevice(undefined);
        })
    };

    if(props.devices.loading !== 0 || props.devices.data.length === 0) {
        return <Spinner/>
    }
    return (
        <div className="animated fadeIn">
            <Row>
                <Col md={{ size: 4, offset: 4 }}>
                    <Card className="card-accent-secondary">
                        <CardHeader>ARP Storm Detector</CardHeader>
                        <CardBody>
                            <Form onSubmit={handleSubmit}>
                                <InputGroup>
                                    <SelectMod
                                        options={props.devices.data}
                                        getOptionLabel={o => o.hostname}
                                        getOptionValue={o => o.hostname}
                                        onChange={o => setDevice(o.hostname)}
                                    />
                                </InputGroup>
                                <div className="clearfix" style={{ marginTop: 10 + 'px', marginBottom: 20 + 'px' }}>
                                    <Button type="submit" color="primary" className="float-end" style={{ minWidth: 113 + 'px', minHeight: 35 + 'px' }} disabled={processing}>{processing ? <div style={{ marginTop: 2 + 'px' }}></div> : 'Check'}</Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        devices: state.netapi.devices
    };
}

export default connect(mapStateToProps)(ArpPpsScanner);
