import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Card, CardBody, CardTitle, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import {
  updateProductBatch,
  updateOrderBatch,
  updateWIPReportItem
} from '../../actions/wipReport';
import LoadingOverlay from '../../components/LoadingOverlay';
import { ButtonIcon } from '../../components/ButtonIcon';
import FormValidationErrors from '../../components/Errors/FormValidationErrors';
import generateFormFields from '../../helpers/FormFieldGenerator';
import isEmpty from 'lodash.isempty';
import omitBy from 'lodash.omitby';
import isNull from 'lodash.isnull';
import ApiErrorResolver from '../../helpers/ApiErrorResolver';
import moment from 'moment';
import EntityMainFormCard from '../../components/Cards/EntityMainFormCard';
import { ProductEnums } from '../../utils/Constants/Product';

const formFields = {
  lifecycleStage: { label: 'Lifecycle Stage', type: 'select', width: 12},
  isDelivered: { label: 'Delivered', type: 'select', width: 12},
  goLiveDate: { label: 'Go Live', type: 'date', width: 12},
  internalRfsDate: { label: 'Internal RFS', type: 'date', width: 12},
  installDate: { label: 'Install', type: 'date', width: 12},
  readyForServiceDate: { label: 'Ready For Service', type: 'date', width: 12},
  nextCustomerUpdate: { label: 'Next Customer Update', type: 'date', width: 12},
}
const initialBatch = {
  lifecycleStage: null,
  isDelivered: null,
  goLiveDate: null,
  internalRfsDate: null,
  installDate: null,
  readyForServiceDate: null,
  nextCustomerUpdate: null,
}

const BatchUpdate = ({
  products,
  dispatch,
  optionSets,
  onClose
}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [batch, setBatch] = useState(initialBatch);
  const [orderIds, setOrderIds] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const productFields = ['lifecycleStage','isDelivered','goLiveDate', 'internalRfsDate', 'installDate', 'readyForServiceDate']
  const orderFields = ['nextCustomerUpdate']
  useEffect(() => {
    setProductIds(products.map(product => product.id))
    setOrderIds([...new Set(products.map(product => product.orderId))])
    return () => setBatch(initialBatch)
  }, []);
  const toggleLoading = () => setLoading(prevState => !prevState);

  const updateWIPItemsInState = (args) => {
    productIds.forEach(id => {
      dispatch(updateWIPReportItem(id, args));
    })
  };

  const getProductFields = (fields) => {
    return omitBy(fields, (value, key) => {
      return !productFields.includes(key)
    })
  }

  const getOrderFields = (fields) => {
    return omitBy(fields, (value, key) => {
      return !orderFields.includes(key)
    })
  }
  const persistParameters = () => {
    setErrors([])
    const fieldsWithValues = omitBy(batch, isNull)

    if(!isEmpty(fieldsWithValues)){
      toggleLoading()
      const orderArgs = getOrderFields(fieldsWithValues)
      const productArgs = getProductFields(fieldsWithValues)
      const updates = []
      if (!isEmpty(productArgs)) {
        updates.push(dispatch(updateProductBatch({
          products: productIds,
          args: productArgs
        })).then(
          (result) => {
            if(result?.errors){
              setErrors([...new Set(ApiErrorResolver(result.errors))]);
            }
            updateWIPItemsInState(productArgs);
          }
        ));
      }
      if (!isEmpty(orderArgs)) {
        updates.push(dispatch(updateOrderBatch({
          orders: orderIds,
          args: orderArgs
        })).then(
          (result) => {
            if(result?.errors){
              setErrors([...new Set(ApiErrorResolver(result.errors))]);
            }
            updateWIPItemsInState(orderArgs);
          }
        ));
      }
      return Promise.all(updates).then(() => {
        toggleLoading()
      })
    }
  };
  const handleInputDate = (field, value) => {
    setBatch({
      ...batch,
      [field] : moment(value).format()
    })
  }
  const getSelectOptions = (field) => {

    const options = optionSets[field]?.options ?? []
    if(field === 'lifecycleStage'){
      return options.filter(option => option.value !== ProductEnums.lifecycleStage.CANCELED)
    }

    return options
  };

  const getSelectedOption = (field) => {

    return optionSets[field].options.find(option => option.value === batch[field]) ?? null
  };

  const handleSelectInput = (key, selected) => {
    setBatch({
      ...batch,
      [key] : selected?.value || null
    })
  }

  const hasChanges = () => {
    return !isEmpty(omitBy(batch, isNull))
  }

  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <Card className='bg-light border-0 mb-0'>
          <CardBody>
            <Row className='mb-2'>
              <Col className='d-flex'>
                <CardTitle>
                  You are editing {productIds.length} products in {orderIds.length} orders.
                </CardTitle>
                <ButtonIcon className='ms-auto' onClick={() => onClose()} icon='fa fa-lg fa-close'/>
              </Col>
            </Row>
            <FormValidationErrors errors={errors}/>
            <Row className="d-flex">
              <Col className={"d-flex"}>
                <EntityMainFormCard>
                  <Alert color={'warning'}>Empty fields will be ignored</Alert>
                  <Row form>
                    {generateFormFields({
                      fields: formFields,
                      getSelectOptions,
                      getSelectedOption,
                      handleSelectInput,
                      handleInputDate,
                      data: batch
                    })}
                    <Button
                      block
                      onClick={persistParameters}
                      disabled={!hasChanges()}
                    >
                      Update
                    </Button>
                  </Row>
                </EntityMainFormCard>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </LoadingOverlay>
    </div>
  );
};

function mapStateToProps({ wipReport, helpers }) {
  return {
    optionSets: helpers.optionSets.product,
    wipReport
  };
}

export default connect(mapStateToProps)(BatchUpdate);
