import React, { useState } from 'react';
import FilteredTable from '../../../components/FilteredTable';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import generateFormFields from '../../../helpers/FormFieldGenerator';
import moment from 'moment';
import { stateMap } from '../../../utils/Cases/CasesService';

const ServiceIncidents = ({ data, modify, remove, optionSets, subjects, sites }) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [currentFormData, setCurrentFormData] = useState({});

    const edit = (caseNumber) => {
      setModalOpen(true);
      setCurrentFormData(data.find(incident => incident.caseNumber === caseNumber));
    };

    const columns = [
      { accessor: 'createdOn', Header: 'Created On' },
      { accessor: 'closedOn', Header: 'Closed On' },
      { accessor: 'caseNumber', Header: 'Case Number' },
      { accessor: 'customer.name', Header: 'Customer' },
      { accessor: 'title', Header: 'Case Title' },
      { accessor: 'subject.name', Header: 'Subject/Service' },
      {
        accessor: 'type',
        Header: 'Type',
        Cell: props => {
          const option = optionSets.type.options.find(optionSet => optionSet.value === Number(props.value));
          if (option) {
            return option.label;
          }
          return props.value;
        }
      },
      { accessor: 'downtime', Header: 'Downtime' },
      {
        accessor: 'priority',
        Header: 'Priority',
        Cell: props => {
          const option = optionSets.priority.options.find(optionSet => optionSet.value === Number(props.value));
          if (option) {
            return option.label;
          }
          return props.value;
        }
      },
      {
        accessor: 'status',
        Header: 'Status Reason',
        Cell: props => {
          const option = optionSets.status.options.find(optionSet => optionSet.value === props.value);
          if (option) {
            return option.label;
          }
          return props.value;
        }
      },
      {
        accessor: 'caseNumber',
        width: 40,
        sortable: false,
        filterable: false,
        Cell: props => <Button size="sm" color="info" onClick={() => edit(props.value)}><i
          className="fa fa-edit"/></Button>
      },
      {
        accessor: 'caseNumber',
        width: 40,
        sortable: false,
        filterable: false,
        Cell: props => <Button size="sm" color="danger" onClick={() => remove(props.value)}><i
          className="fa fa-remove"/></Button>
      }
    ];

    const toggleForm = () => setModalOpen(prevState => !prevState);

    const handleInput = (event) => {
      setCurrentFormData({ ...currentFormData, [event.target.id]: event.target.value });
    };

    const handleInputDate = (field, value) => {
      setCurrentFormData({ ...currentFormData, [field]: moment(value).format('YYYY-MM-DD HH:mm') });
    };

    const handleSelectInput = (field, selected) => {
      let selectedValue = selected ? selected.value : null;
      if (selectedValue) {
        if (field === 'customer') {
          selectedValue = sites.find((option) => option.accountid === selectedValue);
        } else if (field === 'subject') {
          selectedValue = subjects.find((option) => option.id === selectedValue);
        } else if (field === 'state') {
          selectedValue = stateMap.find((option) => option.id === Number(selectedValue));
          selectedValue = selectedValue ? selectedValue.id : '';
        } else if (optionSets[field]) {
          selectedValue = optionSets[field].options.find((option) => option.value === Number(selectedValue));
          selectedValue = selectedValue ? selectedValue.value : '';
        }
      }
      setCurrentFormData({ ...currentFormData, [field]: selectedValue });
    };

    const getSelectOptions = (field) => {
      if (field === 'customer') {
        return Object.values(sites)
          .map((option) => {
            return {
              value: option.accountid,
              label: option.name
            };
          });
      } else if (field === 'subject') {
        return Object.values(subjects)
          .map((option) => {
            return {
              value: option.id,
              label: option.name
            };
          });
      } else if (field === 'state') {
        return Object.values(stateMap)
          .map((option) => {
            return {
              value: option.id,
              label: option.label
            };
          })
      } else if (optionSets[field]) {
        return optionSets[field].options.map((option) => {
          return {
            value: option.value,
            label: option.label
          };
        });
      }
      return [];
    };

    const getSelectedOption = (field) => {
      let selected = [];
      if (field === 'customer') {
        selected = sites.filter((option) => {
          return currentFormData[field] && option.accountid === currentFormData[field].accountid;
        });
      } else if (field === 'subject') {
        selected = subjects.filter((option) => {
          return currentFormData[field] && option.id === currentFormData[field].id;
        });
      } else if (field === 'state') {
        selected = stateMap.filter(option => option.id === Number(currentFormData[field]));
      } else if (optionSets[field]) {
        selected = optionSets[field].options.filter(option => option.value === Number(currentFormData[field]));
      }
      if (selected.length) {
        return selected[0];
      }

      return null;
    };

    const form = {
      createdOn: { label: 'Created On', type: 'datetime' },
      closedOn: { label: 'Closed On', type: 'datetime' },
      caseNumber: { label: 'Case Number', type: 'text', readOnly: true },
      customer: { label: 'Customer', type: 'select' },
      title: { label: 'Title', type: 'text' },
      subject: { label: 'Subject', type: 'select' },
      type: { label: 'Type', type: 'select' },
      priority: { label: 'Priority', type: 'select' },
      status: { label: 'Status Reason', type: 'select' },
      state: { label: 'State', type: 'select' },
      downtime: { label: 'Downtime', type: 'number' },
    };

    const save = () => {
      modify(currentFormData);
      setCurrentFormData({});
      setModalOpen(false);
    };

    return (
      <>
        <FilteredTable
          data={data}
          columns={columns}
          hideFilter={true}
        />
        <Modal isOpen={modalOpen} toggle={toggleForm}
               className="modal-info modal-dialog-centered">
          {currentFormData.caseNumber ? (
            <>
              <ModalHeader toggle={toggleForm}>{currentFormData.title}</ModalHeader>
              <ModalBody>
                <Row className="mb-3">
                  <Col className={'d-flex justify-content-end'}>
                    <Button className="me-2" size={'sm'} color={'primary'} onClick={save}>
                      <i className="fa fa-save"/>
                    </Button>
                  </Col>
                </Row>
                <Row form>
                  {generateFormFields({
                    data: currentFormData,
                    fields: form,
                    getSelectOptions,
                    getSelectedOption,
                    handleSelectInput,
                    handleInput,
                    handleInputDate
                  })}
                </Row>
              </ModalBody>
            </>
          ) : ''}
        </Modal>
      </>
    );
  }
;

export default ServiceIncidents;
