import {Input} from "reactstrap";
import React, {MutableRefObject, useEffect} from "react";
import {ColumnSort, createColumnHelper} from "@tanstack/react-table";
import {PriceListItem, PricingType} from "../../../types";
import FilteredTableV2 from "../../FilteredTableV2";
import numeral from "numeral";
import {SelectMod} from "../../Selects/SelectMod";
import Currency from "../../Currency";

type TOwnProps = {
    priceListItems: PriceListItem[]
    defaultSorted?: ColumnSort
    editable: boolean
    editableKeyToFocus: MutableRefObject<string>
    handleInput: Function
    removeItem: Function
    setDescriptionItem: Function
    pricingOptions: PricingType[]
    currency: number
}

type TEditableInputProps = {
    value: string|number;
    id: string|number;
    field: string;
}

const PriceListItemsTable: React.FC<TOwnProps> = (
    {
        priceListItems,
        defaultSorted,
        editable,
        editableKeyToFocus,
        handleInput,
        removeItem,
        setDescriptionItem,
        pricingOptions,
        currency
    }) => {

    const columnHelper = createColumnHelper<PriceListItem>();

    const EditableInput: React.FC<TEditableInputProps> = ({value, id, field}) => {
        const key = `${id}-${field}`
        return <Input
            value={value}
            onChange={
                (event) => {
                    editableKeyToFocus.current = key;
                    handleInput(id, {[field]: event.target.value})
                }
            }
            autoFocus={editableKeyToFocus.current === key}
        />
    }

    const columns = [
        columnHelper.accessor('name', {
            header: 'Name',
            minSize: 300,
            cell: props => {
                return editable ?
                    <EditableInput
                        value={props.getValue()}
                        id={ props.row.original.id}
                        field={'name'}
                    />
                    : props.getValue() || props.row.original.template.name
            }
        }),
        columnHelper.accessor( 'template.productCode', {
            header: 'Product Code'
        }),
        columnHelper.accessor( 'template.productGroup.name', {
            header: 'Group'
        }),
        columnHelper.accessor( 'install', {
            header: 'Install',
            cell: props => {
                return  editable ?
                    <EditableInput
                        value={props.getValue()}
                        id={props.row.original.id}
                        field={'install'}
                    />
                    :  <Currency currency={currency} value={props.getValue()}/>
            }
        }),
        columnHelper.accessor( 'rental', {
            header: 'Rental',
            cell: props => {
                return editable ?
                    <EditableInput
                        value={props.getValue()}
                        id={props.row.original.id}
                        field={'rental'}
                    />
                    :  <Currency currency={currency} value={props.getValue()}/>
            }
        }),
        columnHelper.accessor( 'pricing.name', {
            header: 'Pricing',
            cell: (props) =>
                editable ?
                    <SelectMod
                        getOptionLabel={(opt: PricingType) => opt.name}
                        getOptionValue={(opt: PricingType) => opt.id}
                        menuPortalTarget={document.body}
                        options={pricingOptions}
                        value={pricingOptions.find(option => option.id === props.row.original.pricing.id)}
                        onChange={(selected: PricingType) => handleInput(props.row.original.id, {pricing: pricingOptions.find(option => option.id === selected.id)})}
                    />
                    : props.getValue()

        }),
        columnHelper.accessor( 'id', {
            header: '',
            cell: props => {
                return (
                    <div className='text-center'>
                      <span className='text-primary hoverPointer me-3 '
                            onClick={() => setDescriptionItem(props.row.original)}>
                          <i className={`fa fa-2x ${props.row.original.description ? 'fa-file-text' : 'fa-file-text-o'}`}/>
                        </span>
                        {editable &&
                          <span className='text-danger hoverPointer' onClick={() => removeItem(props.getValue())}>
                          <i className='fa fa-2x fa-close'/>
                        </span>
                        }
                    </div>
                )

            }

        })
    ]

    return (
        <>
            {/* @ts-ignore */}
            <FilteredTableV2
                showPagination
                showPageSizeOptions
                defaultPageSize={20}
                withFilter={'includesString'}
                columns={columns}
                data={priceListItems}
                minRows={10}
                defaultSorted={defaultSorted || [{
                    id: 'name',
                    desc: false
                }]}
            />
        </>
    )
}

export default PriceListItemsTable;
