import React from 'react';
import { InputGroup, Input, Row, Col } from 'reactstrap';

import ReactTable from 'react-table-v6';

/**
 * @deprecated use FilteredTableV2 instead!
 */
const FilteredTable = ({
  onChange,
  filterable,
  sortable,
  value,
  placeholder,
  columns,
  data,
  defaultSorted,
  noDataText,
  minRows,
  showPagination,
  showPageSizeOptions,
  pageSizeOptions,
  onRowClick,
  hideFilter,
  withExport,
  extraButtons,
  pages,
  defaultPageSize,
  onFetchData,
  onFilteredChange,
  onSortedChange,
  onPageChange,
  onPageSizeChange,
  loading
}) => {

  return (
    <React.Fragment>
        <Row className={`d-flex ${!hideFilter || (withExport || extraButtons) ? 'mb-4' : ''}`}>
          {!hideFilter ? (
            <Col md={4}>
              <InputGroup>
                <Input
                  value={value}
                  placeholder={placeholder || 'Search...'}
                  onChange={(event) => { onChange(event); }}
                />
              </InputGroup>
            </Col>
          ) : ''}
          {extraButtons ? (
            <Col className='d-flex justify-content-end'>
              {extraButtons.map((button, index) => <div key={`extra-btn-${index}`}>{button}</div>)}
            </Col>
          ) : ''}
        </Row>


      <Row className={hideFilter ? 'me-0 ms-0' : 'mt-2 me-0 ms-0' }>
        <Col className='p-0'>
          <ReactTable
            css
            getTheadProps={() => {
              return {
                style: { borderBottom: 'none' }
              };
            }}
            getTdProps={() => {
              return {
                style: { border: 'bottom', whiteSpace: 'unset', overflow: 'visible' }
              };
            }}
            getTrProps={(state, rowInfo, column) =>{
              if (onRowClick) {
                return {
                  style: { cursor: rowInfo ? 'pointer' : 'auto' },
                  onClick: (event, handleOriginal) => {
                    if (onRowClick && !event.target.href) {
                      onRowClick(rowInfo.original, event);
                    }
                    if (handleOriginal) {
                      handleOriginal();
                    }
                  }
                };
              }
              return {};
            }}
            noDataText={noDataText}
            sortable={sortable !== undefined ? Boolean(sortable) : true}
            className={'animated fadeIn -highlight'}
            minRows={minRows || 2}
            data={data}
            columns={columns}
            filterable={filterable || false}
            defaultFilterMethod={(filter, row) => String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1}
            showPagination={showPagination !== undefined ? Boolean(showPagination) : (data?.length || 0) > (defaultPageSize || 20)}
            showPageSizeOptions={showPageSizeOptions === undefined ? true : Boolean(showPageSizeOptions)}
            pageSizeOptions={pageSizeOptions || [5, 10, 20, 25, 50, 100, 200, 500]}
            defaultPageSize={defaultPageSize || 20}
            pages={pages}
            onFetchData={(state, instance) => {
              if (onFetchData) {
                onFetchData(state.page + 1, state.pageSize, state.filtered, state.sorted);
              }
            }}
            onFilteredChange={onFilteredChange}
            onSortedChange={onSortedChange}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            loading={loading || false}
            manual={Boolean(onFetchData)}
            defaultSorted={defaultSorted ? [defaultSorted] : []}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FilteredTable;
