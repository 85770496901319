import React, { Component, useEffect, useState } from 'react';
import {StatusAdmin} from "@highspeedoffice/hso-status-admin-components";
import "@highspeedoffice/hso-status-admin-components/src/styles.css";
import { getAPIToken } from '../../utils/Auth/AuthService';
import { SpinnerInfo } from '../../components/Spinner/Spinner';

const StatusMessages = () => {
  const [token, setToken] = useState(null)

  useEffect(() => {
    getAPIToken().then(tok => setToken(tok))
  }, [])

  if(token === null) {
    return(
        <div className="justify-content-center">
            <SpinnerInfo/>
        </div>
    )
  }

  return (
    <StatusAdmin token={token} baseUrl={process.env.REACT_APP_STATUS_API_URL}/>
  )
}

export default StatusMessages;
