import React, { Component } from 'react';

import PropTypes from "prop-types";
import {SpinnerInfo} from "../../components/Spinner/Spinner";
import {CardBody, CardHeader, Card, Row, Col, Badge} from "reactstrap";
import {connect} from "react-redux";
import moment from 'moment';
import { useParams } from 'react-router-dom'

class ShadowServerHostLegacy extends Component {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired
      }).isRequired,
      staticContext: PropTypes.object
    }).isRequired
  };


  render() {
    let data = this.props.shadowServer.data.filter(d => d.ipaddress === this.props.params.ipaddress);
    if(data.length <= 0) {
      return (<div className="justify-content-center">
        <SpinnerInfo/>
      </div>);
    }
    data = data[0];

    return(<Row>
      <Col>
        <Card  className="w-100 card-accent-primary">
          <CardHeader>ShadowServer Report for {this.props.params.ipaddress} on {this.props.shadowServer.latestDate}</CardHeader>
          <CardBody>
            {data.interface.map((int) => {
              return (
                <Card className={"card-accent-success"}>
                  <CardHeader>
                    <Row>
                      <Col>IP Interface Info</Col>
                      <Col className={'text-end text-muted text-sm-right'}>{moment(int.date_updated).from(moment())}</Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={2}>
                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Device Name</div>
                        <div>{int.hostname}</div>
                      </Col>
                      <Col md={2}>
                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Interface</div>
                        <div>{int.int_name}</div>
                      </Col>
                      <Col md={4}>
                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Description</div>
                        <div>{int.int_description}</div>
                      </Col>
                      <Col md={2}>
                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Network</div>
                        <div>{int.network}/{int.mask}</div>
                      </Col>
                      <Col md={2}>
                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">hSo Managed</div>
                        <div>{int.ipaddress === this.props.params.ipaddress ? <Badge color={"danger"}>Yes</Badge> : <Badge>No</Badge>}</div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )
            })}
            {data.staticroute.map((route) => {
              return (
                <Card className={"card-accent-warning"}>
                  <CardHeader>
                    <Row>
                      <Col>Static Route Info</Col>
                      <Col className={'text-end text-muted text-sm-right'}>{moment(route.date_updated).from(moment())}</Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={2}>
                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Device Name</div>
                        <div>{route.hostname}</div>
                      </Col>
                      <Col md={2}>
                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Interface</div>
                        <div>{route.int_name}</div>
                      </Col>
                      <Col md={4}>
                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Description</div>
                        <div>{route.int_description}</div>
                      </Col>
                      <Col md={2}>
                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Network</div>
                        <div>{route.network}/{route.mask}</div>
                      </Col>
                      <Col md={2}>
                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Next Hop</div>
                        <div>{route.next_hop}</div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )
            })}

            {data.data.sort((a, b) => {
              if (a.report > b.report) {
                return 1;
              }
              if (a.report < b.report) {
                return -1;
              }
              return 0;
            }).map((data) => {
              return(
                <Card className={"card-accent-info"}>
                  <CardHeader>{data.report}</CardHeader>
                  <CardBody>
                    <Row>
                      {Object.keys(data).filter(t => !['ip', 'asn', 'report'].includes(t)).sort((a, b) => {
                        if (a > b) {
                          return 1;
                        }
                        if (a < b) {
                          return -1;
                        }
                        return 0;
                      }).map((i) => {
                        return(
                          <Col md={3}>
                            <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">{i}</div>
                            <div>{data[i] === '' ? '-' : data[i]}</div>
                          </Col>
                        )
                      })}
                    </Row>
                  </CardBody>
                </Card>
              )
            })}
          </CardBody>
        </Card>
      </Col>
    </Row>)
  }
}

const ShadowServerHost = ({shadowServer}) => {
  const params = useParams();
  return <ShadowServerHostLegacy params={params} shadowServer={shadowServer}/>
}


function mapStateToProps(state) {
  return {
    shadowServer: state.shadowServer
  };
}

export default connect(mapStateToProps)(ShadowServerHost);
