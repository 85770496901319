import FormFieldGenerator from '../../helpers/FormFieldGenerator';
import { QuoteProduct as fields} from './form';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { ButtonIcon } from '../../components/ButtonIcon';
import EntityMainFormCard from '../../components/Cards/EntityMainFormCard';
import React from 'react';
const QuoteProduct = ({
  product,
  handleEditInputs,
  getSelectOptions,
  handleSelectInput,
  getSelectedOption,
  onClose
}) => {


  return (
    <div className="animated fadeIn">
        <Card className='bg-light border-0 mb-0'>
          <CardBody>
            <Row className='mb-2'>
              <Col className='d-flex'>
                <ButtonIcon className='ms-auto' onClick={onClose} icon='fa fa-lg fa-close'/>
              </Col>
            </Row>
            <Row className="d-flex">
              <Col className={"d-flex"}>
                <EntityMainFormCard>
                  <Row form>
                    {FormFieldGenerator(
                      {
                        fields,
                        data: product,
                        handleInput: handleEditInputs,
                        getSelectedOption,
                        handleSelectInput,
                        getSelectOptions
                      }
                    )}
                  </Row>
                </EntityMainFormCard>
              </Col>
            </Row>
          </CardBody>
        </Card>
    </div>
  )
}


export default QuoteProduct;
