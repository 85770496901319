import { Button, Spinner } from 'reactstrap';
// @ts-ignore
import ReactTooltip from 'react-tooltip';
import { nanoid } from 'nanoid';
import React from "react";

type TOwnProps = {
  icon: string
  colour? : string
  spinnerColour?: string
  size?: string
  onClick: Function
  tooltip?: string
  loading?: boolean
  disabled?: boolean
  className?: string
  outline?: string
  toolTipPlace?: string
}
export const ButtonIcon: React.FC<TOwnProps> = ({
  icon,
  colour,
  spinnerColour,
  size,
  onClick,
  tooltip,
  loading,
  disabled,
  className,
  outline,
  toolTipPlace
}) => {
  const id = nanoid();

  return (
      <div>
        <div data-for={id} data-tip={id} className={`d-inline-block ${className || 'ms-2'}`}>
          <Button
              outline={Boolean(outline)}
              color={colour || 'secondary'}
              size={size || 'sm'}
              onClick={(e) => {
                onClick(e);
                ReactTooltip.hide();
              }}
              disabled={Boolean(disabled)}
          >
            {loading ?
                <Spinner color={spinnerColour || 'primary'} size={size || 'sm'}/>
                :
                <i className={icon}/>
            }
          </Button>
        </div>
        {tooltip ? (
            <ReactTooltip
                id={id}
                type="info"
                place={toolTipPlace || "top"}
                effect="float"
                delayShow={500}
            >{tooltip}
            </ReactTooltip>
        ) : ''}
      </div>)
};
