import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  getProductTemplates, setProductTemplate,
  updateProductTemplate, updateProductTemplateField
} from '../../actions/productTemplates';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import LoadingOverlay from '../../components/LoadingOverlay';
import isEmpty from 'lodash.isempty';
import { ProductTemplateForm } from './form';
import generateFormFields from '../../helpers/FormFieldGenerator';
import { getAllPricingTypes } from '../../actions/pricingTypes';
import { resetPriceList } from '../../actions/priceList';
import { getProductSLAs } from '../../actions/productSLAs';
import { getProductGroups } from '../../actions/productGroups';
import { diff } from 'deep-object-diff';
import { ButtonIcon } from '../../components/ButtonIcon';
import { formValidator } from '../../helpers/FormValidator';
import ProductTemplatesTable from '../../components/Tables/ProductTemplates';
import FormValidationErrors from '../../components/Errors/FormValidationErrors';
import EntityMainFormCard from '../../components/Cards/EntityMainFormCard';
import HeadlessModal from '../../components/Modals/HeadlessModal';
import resolveArgs from '../../helpers/ArgumentResolver';
import UnsavedChangesAlert from '../../components/Alerts/UnsavedChangesAlert';
import classnames from 'classnames';
import CRMSyncService from '../../utils/CRMSync';
import { defaultErrorFeedback, successFeedback } from '../../actions/feedback';

const ProductTemplates = (
  {
    dispatch, productTemplates, pricingTypes, productSLAs, productGroups
  }
) => {

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);
  const [errors, setErrors] = useState([]);
  const toggleModal = () => setShowTemplate(prevState => !prevState)
  const toggleSaving = () => setSaving(prevState => !prevState)

  useEffect(() => {
    let promises = [];
    setLoading(true)
    if(isEmpty(productTemplates.list)){
      promises.push(dispatch(getProductTemplates()))
    }
    if(isEmpty(pricingTypes.list)){
      promises.push(dispatch(getAllPricingTypes()))
    }
    if(isEmpty(productSLAs.list)){
      promises.push(dispatch(getProductSLAs()))
    }
    if(isEmpty(productGroups.list)){
      promises.push(dispatch(getProductGroups()))
    }
    Promise.all(promises).then(() => {
      setLoading(false)
    })
    return () => {
      dispatch(resetPriceList())
    }

  }, [])

  const selectTemplate = (data) => {
    toggleModal()
    dispatch(setProductTemplate(data))
  }

  const getData = () => {
    setLoading(true)
    dispatch(getProductTemplates()).then(() => {
      setLoading(false)
    })
  }
  const getSelectData = (field) =>{
    switch (field){
      case 'productGroup':
        return productGroups.list
      case 'pricingType':
        return pricingTypes.list.map(type => {
          return {...type, data: null}
        })
      case 'productSLA':
        return productSLAs.list
      default:
        return []
    }
  }

  const getTemplateSelectOptions = (field) =>{
    return getSelectData(field).map((record) => {
      return {label: record.name, value: record.id}
    });
  }

  const getTemplateSelectedOption = (field) =>{
    const selected = getSelectData(field).filter(option => option.id === productTemplates.template.form[field]?.id);
    if (!isEmpty(selected)) {
      return selected[0];
    }
    return null;
  }

  const handleTemplateSelectInput = (field, selected) =>{
    dispatch(updateProductTemplateField(
      {[field] : selected ? getSelectData(field).find(option => option.id === selected.value): []}
    ))
  }

  const handleInput = (event) => {
    dispatch(updateProductTemplateField(
      {[event.target.id] : event.target.value}
    ))
  }

  const saveTemplate = () => {
    if(validated()){
      const toSave = diff(productTemplates.template.original, productTemplates.template.form);
      toggleSaving()
      dispatch(updateProductTemplate(productTemplates.template.form.id, resolveArgs(toSave))).then(() => toggleSaving())
    }
  }

  const validated = () => {
    let errorArr = formValidator(ProductTemplateForm, productTemplates.template.form);
    setErrors(errorArr);
    return isEmpty(errorArr);
  }

  const hasChanges = () => {
    const theDiff = diff(productTemplates.template.original, productTemplates.template.form);
    return !isEmpty(theDiff)
  }

  const sync = () => {
    CRMSyncService.syncProductTemplates().then((result) => {
      if(result.status === 200){
        dispatch(successFeedback('Template sync initiated!'))
      }else{
        dispatch(defaultErrorFeedback())
      }
    })
  }

  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <LoadingOverlay loading={loading}>
            <ProductTemplatesTable
              templates={productTemplates.list}
              onRowClick={(data) => selectTemplate(data)}
              fetchData={getData}
              sync={sync}
            />
          </LoadingOverlay>
        </CardBody>
      </Card>
      <HeadlessModal
        open={showTemplate}
        size={'xlg'}
        toggle={toggleModal}
      >
        <LoadingOverlay loading={saving}>
          <Card className='bg-light border-0 mb-0'>
            <CardBody>
              <Row className={'mb-4'}>
                <Col className={'d-flex'}>
                  <CardTitle>{`${productTemplates.template.form.name} - ${productTemplates.template.form.productCode}`}</CardTitle>

                  <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ms-auto')}>
                    {
                      hasChanges() && <UnsavedChangesAlert save={saveTemplate}/>
                    }
                    <ButtonIcon
                      icon={'fa fa-save'}
                      onClick={saveTemplate}
                      colour={'secondary'}
                      disabled={!hasChanges() || saving}
                      tooltip={'save'}
                    />
                    <ButtonIcon
                      icon={'fa fa-close'}
                      onClick={toggleModal}
                      colour={'secondary'}
                      disabled={saving}
                      tooltip={'close'}
                    />
                  </div>
                </Col>
              </Row>
              <FormValidationErrors errors={errors}/>
              <Row>
                <Col>
                  <EntityMainFormCard>
                    <Row form>
                      {generateFormFields(
                        {
                          fields: ProductTemplateForm,
                          handleInput,
                          getSelectOptions: getTemplateSelectOptions,
                          getSelectedOption: getTemplateSelectedOption,
                          handleSelectInput: handleTemplateSelectInput,
                          data: productTemplates.template.form
                        })
                      }
                    </Row>
                  </EntityMainFormCard>
                </Col>
              </Row>
            </CardBody>
          </Card>

        </LoadingOverlay>
      </HeadlessModal>
    </div>

  )
}


function mapStateToProps({
  productSLAs,
  productTemplates,
  authenticationState,
  pricingTypes,
  productGroups
}) {
  return {
    productSLAs,
    productTemplates,
    user: authenticationState.account,
    pricingTypes,
    productGroups
  };
}
export default connect(mapStateToProps)(ProductTemplates)
