import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import isNaN from 'lodash.isnan';
import classnames from 'classnames';
import isEmpty from 'lodash.isempty';
import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup, Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  FormGroup, Dropdown, Badge, CardTitle, Alert
} from 'reactstrap';
import LoadingOverlay from '../../components/LoadingOverlay';
import generateFormFields from '../../helpers/FormFieldGenerator';
import { NewQuote, newQuoteProductInputForm, ReadOnlyQuote } from './form';
import { ConvertToOrderForm } from './convertToOrderForm';
import {
  addCompareV2Quote,
  addQuoteOrderFormServiceLocation,
  addQuoteProduct, cloneCompareV2Quote,
  cloneQuote,
  convertQuoteToOrder,
  createAccountQuote,
  getAccountSites,
  getQuote,
  removeQuoteOrderFormServiceLocation,
  resetQuote,
  resetQuoteOrderForm,
  resetQuoteOrderFormServiceLocations, setQuoteData, setQuoteLoader,
  toggleQuoteOrderFormLoading,
  updateQuoteField,
  updateQuoteOrderFormField,
  updateQuoteOrderFormLocationField,
  updateQuoteOrderFormNetworkServiceOrder,
  updateQuoteOrderFormOtherServiceOrder,
  updateQuoteProductField,
  updateQuote, addQuoteNote, updateQuoteNote, removeQuoteNote, toggleQuoteCancellationFormLoading, getQuoteAuditHistory
} from '../../actions/quote';
import { addQuoteToList, updatePreferredQuoteInList } from '../../actions/quotes';
import {
  getOpportunity,
  toggleOpportunityLoading,
  updateQuoteRefInProgress
} from '../../actions/opportunity';
import { SelectMod } from '../../components/Selects/SelectMod';
import { api_downloadCancellationForm, api_downloadOrderForm, api_downloadProposal } from '../../utils/Quotes';
import fileDownload from 'js-file-download';
import OrderFormWizard from './OrderFormWizard';
import { defaultErrorFeedback } from '../../actions/feedback';
import moment from 'moment';
import { v4 } from 'uuid';
import QuoteRequest from '../PricingToolQuote/QuoteRequest';
import { ButtonIcon } from '../../components/ButtonIcon';
import { roundToTwo } from '../../helpers/numbers';
import { joinRooms } from '../../actions/socketio';
import {lookupPriceListItem } from '../../actions/priceList';
import {diff } from 'deep-object-diff';
import { setConfirmDialog } from '../../actions/dialogs';
import { ProductEnums } from '../../utils/Constants/Product';
import { useNavigate } from 'react-router-dom'
import QuoteProducts from './QuoteProducts';
import UnsavedChangesAlert from '../../components/Alerts/UnsavedChangesAlert';
import FormValidationErrors from '../../components/Errors/FormValidationErrors';
import CollapsibleCard from '../../components/CollapsibleCard';
import { AccountEnums } from '../../utils/Constants/Account';
import EntitySubFormCard from '../../components/Cards/EntitySubFormCard';
import EntityMainFormCard from '../../components/Cards/EntityMainFormCard';
import { QuoteEnums } from '../../utils/Constants/Quote';
import Notes from '../../components/Notes';
import ApiErrorResolver from '../../helpers/ApiErrorResolver';
import { isCancelProduct } from '../../utils/Products/ProductService';
import HeadlessModal from '../../components/Modals/HeadlessModal';
import CancellationFormWizard from './CancellationFormWizard';
import AuditHistory from "../../components/AuditHistory";
import { resolveCurrencySign } from '../../utils/Helpers/Currency';
import { replaceOpportunityInList } from '../../actions/opportunities';



export const serviceLocationTemplate = {
  siteId: null,
  contactId: null,
  floor: '',
  room: '',
  rackNumber: '',
  buildingOwner: false
};

const Quote = (props) => {
  const {
    id,
    opportunity,
    onCloned,
    contacts,
    activeServices,
    toggleModal
  } = props;

  // route
  const navigate = useNavigate();

  // redux
  const dispatch = useDispatch();

  const quote = useSelector(state => state.quote)
  const user = useSelector(state => state.authenticationState.account)
  const users = useSelector(state => state.helpers.systemUsers)
  const optionSets = useSelector(state => state.helpers.optionSets.quote)
  const priceLists = useSelector(state => state.priceLists)

  const [productToAdd, setProductToAdd] = useState(null);
  const [compareQuoteId, setCompareQuoteId] = useState('');
  const [errors, setErrors] = useState([]);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showCloseDateModal, setShowCloseDateModal] = useState(false);
  const [showOrderWizard, setShowOrderWizard] = useState(false);
  const [showQuickQuote, setShowQuickQuote] = useState(false);
  const [showCancellationForm, setShowCancellationForm] = useState(false);
  const [quoteContact, setQuoteContact] = useState({});
  const [loadingQuoteText, setLoadingQuoteText] = useState('');

  const [orderData, setOrderData] = useState({
    closeDate: new Date()
  });
  const [isCloned, setIsCloned] = useState(false);
  const isNew = (id === 'new' || isCloned) && !quote.original.id;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [auditsLoading, setAuditsLoading] = useState(false);

  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    setErrors([]);
    setIsCloned(false);
    if (!isNew && id) {
      loadQuoteData(id);
    }
    return () => {
      setLoadingQuote(false)
    }
  }, [id]);

  const setLoadingQuote = (load) =>{
    dispatch(setQuoteLoader(load))
  }

  const toggleCancellationForm = () => setShowCancellationForm((prevState) => !prevState)

  const loadQuoteData = (quoteId = null, reset = true) => {
    setLoadingQuote(true);
    setErrors([]);
    if(reset){
      dispatch(resetQuote());
    }
    dispatch(getQuote(quoteId || quote.original.id))
      .then(
        (result) => {
          if (result) {
            setLoadingQuote(false);
            dispatch(updateQuoteRefInProgress(result.id));
            dispatch(joinRooms({ rooms: [result.id] }))
            return result
          } else {
            setLoadingQuote(false);
            return false
          }
        }
      );
  };

  const loadOpportunityData = () => {
    if (opportunity) {
      dispatch(toggleOpportunityLoading());
      dispatch(getOpportunity(user.cui, opportunity.id))
        .then((result) => {
          dispatch(toggleOpportunityLoading())
          if(result){
            dispatch(replaceOpportunityInList(result))
          }
        });
    }

  };
  const getForm = () => {
    return isNew ? NewQuote : ReadOnlyQuote(resolveCurrencySign(opportunity.priceList?.currency.id));
  };

  const hasProductWithoutPriceListItem = () => {
    return quote.form.products.filter(service => !service.priceListItem?.id).length > 0
  }

  const hasOnlyCancellations = () => {
    return isEmpty(quote.orderForm.networkServices) && isEmpty(quote.orderForm.otherServices)
  }
  const hasCancellations = () => {
    return  quote.form.products.filter(service => /^HSO00-101/.test(service.productNumber)).length > 0
  }

  const getCancellationLinkedProducts = () => {
    return quote.original.products.filter(product => /^HSO00-101/.test(product.productNumber)).reduce((carry, product) => {
      product.linkedProducts.forEach(linkedProduct => carry.push(linkedProduct))
      return carry
    }, [])
  }
  const toggleConvertToOrderModal = () => {
    setShowCloseDateModal(prevState => !prevState);
  };
  const toggleContactModal = () => {
    setShowContactModal(prevState => !prevState);
  };
  const toggleOrderWizard = () => {
    if(hasOnlyCancellations()){
      setErrors(['There are not enough valid products to create an Order Form from. A quote should have at least one product other than HSO00-101.']);
      return
    }
    if (!showOrderWizard && quote.sites.length === 0) {
      dispatch(toggleQuoteOrderFormLoading());
      dispatch(getAccountSites(quote.form.customer.accountid))
        .then(() => dispatch(toggleQuoteOrderFormLoading()));
    }
    setShowOrderWizard(!showOrderWizard);
  };

  const toggleQuickQuote = () => {
    setShowQuickQuote(!showQuickQuote);
  };

  const updatePreferred = () => {
    setLoadingQuote(true);
    dispatch(updateQuote( quote.original.id, {preferredQuote: true}))
      .then(
        (result) => {
          if (result) {
            dispatch(updatePreferredQuoteInList(quote.original.id));
            dispatch(updateQuoteField({ preferredQuote: true }));
            loadOpportunityData();
          }
          setLoadingQuote(false);
        });
  };

  const addProduct = () => {
    if (productToAdd) {
      dispatch(addQuoteProduct({
        id: `new-${v4()}`,
        name: productToAdd.template.name,
        productNumber: productToAdd.template.productCode,
        install: productToAdd.install,
        rental: productToAdd.rental,
        quantity: 1,
        term: 12,
        description: productToAdd.name,
        pricingToolQuote: null,
        pricingToolQuoteSite: null,
        sla: productToAdd.template.productSLA?.id,
        priceListItem: productToAdd.id,
        orderType: isCancelProduct(productToAdd.template.productCode) ? ProductEnums.orderType.CANCEL : ProductEnums.orderType.NEW_ITEM ,
        billingCycle: opportunity.customer.billingFrequency === AccountEnums.billingFrequency.MONTHLY ? ProductEnums.billingCycle.MONTHLY : ProductEnums.billingCycle.QUARTERLY,
        linkedProducts: []
      }));
    }
  };

  const addOrderFormLocation = (serviceLocation) => {
    dispatch(addQuoteOrderFormServiceLocation(serviceLocation || serviceLocationTemplate));
  };

  const resetOrderFormServiceLocations = () => {
    dispatch(resetQuoteOrderFormServiceLocations());
  };

  const removeOrderFormServiceLocation = (id) => {
    dispatch(removeQuoteOrderFormServiceLocation(id));
  };

  const handleServiceLocationInput = (siteId, field) => {
    dispatch(updateQuoteOrderFormLocationField(siteId, field));
  };



  const importCompareQuote = () => {
    setLoadingQuote(true);
    dispatch(addCompareV2Quote(compareQuoteId))
      .then(() => {
        setLoadingQuote(false);
      });

  };

  const hasCompareQuote = () => {
    return quote.form.products.filter((service) => Boolean(service.pricingToolQuote)).length > 0;
  };

  const isCrmReady = () => {

    return quote.form.crmReady === true
  }

  const downloadProposal = (format = 'pdf') => {
    setLoadingQuote(true);
    api_downloadProposal(user.cui, quote.original.id, format, quoteContact.label)
      .then((result) => {
        if (result.status === 200) {
          let splitContentDisposition = result.headers['content-disposition'].split('=');
          fileDownload(result.data, splitContentDisposition[1]);
        }
        setLoadingQuote(false);
      });
  };

  const downloadOrderForm = () => {
    dispatch(toggleQuoteOrderFormLoading());
    api_downloadOrderForm(quote.original.id, resolveOrderFormArgs())
      .then((result) => {
        if (result.status === 200) {
          let splitContentDisposition = result.headers['content-disposition'].split('=');
          fileDownload(result.data, splitContentDisposition[1]);
        } else {
          dispatch(defaultErrorFeedback());
        }
        dispatch(toggleQuoteOrderFormLoading());
      });
  };

  const downloadCancellationForm = (data) => {
    dispatch(toggleQuoteCancellationFormLoading())
    api_downloadCancellationForm(quote.original.id, data)
      .then((result) => {
        if (result.status === 200) {
          let splitContentDisposition = result.headers['content-disposition'].split('=');
          fileDownload(result.data, splitContentDisposition[1]);
        } else {
          dispatch(defaultErrorFeedback());
        }
        dispatch(toggleQuoteCancellationFormLoading());
      });
  };
  const resolveOrderFormArgs = () => {
    let result = {};
    let serviceIndexes = {};
    quote.orderForm.networkServices.reduce((result, product, index) => {
      result[product.productNumber] = index + 1;
      return result;
    }, serviceIndexes);
    quote.orderForm.otherServices.reduce((result, product, index) => {
      result[product.productNumber] = index + 1;
      return result;
    }, serviceIndexes);
    result.serviceIndexes = serviceIndexes;
    result.serviceLocations = quote.orderForm.serviceLocations.map((location) => {
      return {
        siteId: location.siteId,
        contactId: location.contactId,
        floor: location.floor,
        room: location.room,
        rackNumber: location.rackNumber,
        buildingOwner: location.buildingOwner
      };
    });
    result.supplySiteId = quote.orderForm.supplySite;
    result.supplyContactId = quote.orderForm.supplyContact;
    if (quote.orderForm.billingSite) {
      result.billingSiteId = quote.orderForm.billingSite;
    }
    if (quote.orderForm.billingContact) {
      result.billingContactId = quote.orderForm.billingContact;
    }
    if (quote.orderForm.comments) {
      result.comments = quote.orderForm.comments;
    }
    if (quote.orderForm.companyRegSub) {
      result.companyRegSub = quote.orderForm.companyRegSub;
    }

    return result;
  };

  const convertToOrder = () => {
    setLoadingQuote(true);
    toggleConvertToOrderModal();
    setErrors([])
    const data = {
      closeDate: moment(orderData.closeDate)
        .format('DD/MM/YYYY')
    }
    if(orderData.order){
      data.order = orderData.order
    }
    dispatch(convertQuoteToOrder(
      user.cui,
      quote.original.id,
      data)
    )
      .then((result) => {
        if(result.errors){
          setErrors(ApiErrorResolver(result.errors))
        }
        else if (result) {
          navigate(`/sales/orders/${result.id}`);
        }
        setLoadingQuote(false);

      });
  };

  const resolveProducts = (products) => {
    const result = [];
    products.forEach((product, i) => {
      const resolvedProduct = {
        ...product,
        term: isNaN(product.term) ? 0 : product.term,
        rental: isNaN(product.rental) ? 0 : product.rental,
        install: isNaN(product.install) ? 0 : product.install,
        linkedProducts: !isEmpty(product.linkedProducts) ? product.linkedProducts.map(product => product.id) : []
      }
      if(resolvedProduct.committedDataRate === '' || resolvedProduct.committedDataRate === null){
        delete resolvedProduct.committedDataRate;
      }
      if(resolvedProduct.overageRate === '' || resolvedProduct.overageRate === null){
        delete resolvedProduct.overageRate;
      }
      result.push(resolvedProduct);
    })
    return result
  }

  const create = () => {
    if (validated()) {
      if (isNew) {
        setLoadingQuoteText('')
        const args = {
          'name': quote.form.name,
          'opportunity': opportunity.id,
          'pricingToolQuote': quote.form.pricingToolQuote,
          'preferredQuote': quote.form.preferredQuote,
          'products': [...resolveProducts(quote.form.products)]
        };
        setLoadingQuote(true);
        dispatch(createAccountQuote(opportunity.customer.accountid, args))
          .then(
            (result) => {
              if(result?.errors){
                setLoadingQuote(false)
                setErrors(Object.values(result.errors))
              }
              else if (result) {
                loadOpportunityData()
                setIsCloned(false)
                dispatch(setQuoteData(result))
                dispatch(addQuoteToList(result))
                dispatch(updateQuoteRefInProgress(result.id))
                dispatch(joinRooms({ rooms: [result.id] }))

              }
              setLoadingQuote(false)
            });
      }
    }
  };

  const update = () => {
    setErrors([]);
    const changes = diff(quote.original, quote.form);
    if (!isEmpty(changes.products)) {
      setLoadingQuote(true);
      const products = Object.keys(changes.products)
        .map((index) => {
          return {
            id: quote.form.products[index].id,
            ...changes.products[index]
          };
        });
      dispatch(updateQuote(quote.original.id, { products }))
        .then((result) => {
          if(result.errors){
            setErrors(ApiErrorResolver(result.errors))
          } else{
            dispatch(resetQuoteOrderForm());
          }
          setLoadingQuote(false);
        });
    }
  };
  const clone = () => {
    setIsCloned(true);
    setErrors([]);
    onCloned();
    dispatch(cloneQuote());
    if (hasCompareQuote()) {
      setLoadingQuote(true);
      dispatch(cloneCompareV2Quote(quote.form.pricingToolQuote))
        .then(() => {
          setLoadingQuote(false);
        });
    }

  };

  const validated = () => {
    let errors = [];
    setErrors(errors);
    for (const [key, value] of Object.entries(NewQuote)) {
      if (value.mandatory && (quote.form[key] === undefined || quote.form[key] === null || quote.form[key].length === 0)) {
        errors.push(`You must provide a valid ${value.label}`);
      }
    }
    if (quote.form.products.length === 0) {
      errors.push(`You must provide at least one product`);
    }
    if (isNew) {
      quote.form.products.forEach((product) => {
        if (product.pricingToolQuote) {
          const site = quote.pricingToolQuoteSites.find(site => product.pricingToolQuoteSite === site.id);
          const preferredPrice = site.prices.find(price => price.preferred);
          const technology = site.technology?.name;
          if (!preferredPrice) {
            errors.push(`Compare Quote has no preferred price, ${product.name}}`);
          } else if (
            /^HSO10-010|HSO10-011/.test(product.productNumber) &&
            (technology !== 'RO2' && product.rental < preferredPrice.rental) ||
            (technology === 'RO2' && roundToTwo(preferredPrice.rental / 2) < product.rental)
          ) {
            errors.push(`Rentals of pricing tool quotes cannot be less than the original price, ${product.name} - £${preferredPrice.rental}`);
          }
        }
        if(product.orderType !== ProductEnums.orderType.NEW_ITEM && isEmpty(product.linkedProducts)) {
          errors.push(`You must select at least one linked product for ${product.name}`);
        }
        if(isCancelProduct(product.productNumber) && product.orderType !== ProductEnums.orderType.CANCEL) {
          errors.push(`The Order Type must be Cancel for product ${product.name}`);
        }

      });
    }
    if (errors.length === 0) {
      return true;
    }
    setErrors(errors);
  };

  const getSelectOptions = (field) => {
    if (optionSets[field]) {
      let result;
      if (field === 'status' && isNew) {
        result = optionSets[field].options.reduce((reduced, option) => {
          if (option.state === 0) {
            reduced.push({
              value: option.value,
              label: option.label
            });
          }
          return reduced;
        }, []);
      }
      else {
        result = optionSets[field].options.map((option, index) => {
          return {
            value: option.value,
            label: option.label
          };
        });
      }
      return result;
    }
    else if (field === 'priceList'){
      return priceLists.list.filter(list => list.status.name === 'Active' && list.type.name !== 'Portal')
    }
    return [];
  };
  const getSelectedOption = (field) => {
    let selected = [];
    if (optionSets[field]) {
      selected = optionSets[field].options.filter(option => option.value === quote.form[field]);
    }
    if (selected.length) {
      return selected[0];
    }
    return null;
  };

  const handleSelectInput = (field, selected) => {
    dispatch(updateQuoteField({ [field]: selected.value }));
  }

  const handleAsyncSelected = (field, selected) => {
    if(field === 'product'){
      setProductToAdd(selected)
      return
    }
    dispatch(updateQuoteField({ [field]: selected }));
  };

  const filterUsers = (search) => {
    return users.filter((option) => {
      return option.name.toLowerCase()
        .includes(search.toLowerCase());
    });
  };

  const handleInput = (event) => {
    const formField = getForm()[event.target.id];
    if (formField && !formField.plaintext) {
      dispatch(updateQuoteField({ [event.target.id]: event.target.value }));
    }
  };

  const handleQuoteProductInput = (id, field) => {
    dispatch(updateQuoteProductField(id, field));
  };

  const handleOrderFormServiceOrderChange = (serviceType, from, to) => {
    if (serviceType === 'network' && to >= 0) {
      dispatch(updateQuoteOrderFormNetworkServiceOrder(from, to));
    }
    if (serviceType === 'other' && to >= 0) {
      dispatch(updateQuoteOrderFormOtherServiceOrder(from, to));
    }
  };

  const handleOrderFormInput = (field) => {
    dispatch(updateQuoteOrderFormField(field));
  };

  const handleAsyncInput = (entity, key, length, search) => {
    if (!search || search.length < length) {
      return new Promise(() => []);
    }
    if (entity === 'user') {
      return new Promise(resolve => {
        resolve(filterUsers(search));
      });
    }
    if(entity === 'product'){
      return dispatch(lookupPriceListItem(opportunity.priceList.id, search)).then((result) => {
        if(result){
          return result.map(item => {
            return {
              ...item,
              name: `${item.template.productCode} - ${item.name}`}
          })
        }
        return []
      })
    }
  };
  const getContactOptions = () => {
    return contacts.map((contact) => {
      return {
        label: `${contact.fullName} (${contact.email})`,
        value: contact.id
      };
    });
  };

  const hasChanges = () => {
    const changes = diff(quote.original, quote.form);
    return !isEmpty(changes)
  }

  const isClosed = () => {
    return quote.original.status === QuoteEnums.status.WON || quote.original.status === QuoteEnums.status.CLOSED
  }
  const onClosing = () => {
    if(hasChanges() || isNew){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => toggleModal()
      }))
    }else{
      toggleModal()
    }

  }

  const getAudits = () => {
    toggleAuditsLoading();
    dispatch(getQuoteAuditHistory(quote.original.id)).then(() => toggleAuditsLoading())
  }

  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={quote.loading} text={loadingQuoteText}>
        <Card className='mb-0'>
          <CardBody className={'bg-light border-0'}>
            <Row className='mb-2'>
              <Col className='d-flex'>
                <CardTitle>
                  <strong>{quote.original.number || (isNew ? 'New Quote' : '')}</strong>
                  {quote.form.preferredQuote ? <Badge className='ms-2' color='success'>Preferred</Badge> : ''}
                </CardTitle>
                <div className={classnames('d-flex','align-items-center', 'fadeIn', 'ms-auto')}>
                  {
                    !quote.loading && hasChanges() && <UnsavedChangesAlert/>
                  }
                  <ButtonIcon  disabled={quote.loading || (!isNew && !hasChanges() && isCrmReady())} icon={'fa fa-save'} tooltip={'Save'}  onClick={isNew ? create : update}/>
                  <ButtonIcon  disabled={quote.loading || isNew} icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={() => loadQuoteData()}/>
                  <Dropdown isOpen={dropdownOpen} toggle={toggle} direction='left'>
                    <DropdownToggle tag='span' data-toggle="dropdown"
                                    aria-expanded={dropdownOpen}>
                      <ButtonIcon icon='fa fa-lg fa-bars' onClick={() => {}}/>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={clone}
                        disabled={quote.loading || isCloned || isNew || hasChanges() || hasProductWithoutPriceListItem()}
                      >Clone
                      </DropdownItem>
                      <DropdownItem
                        disabled={quote.form.preferredQuote || quote.loading || isCloned || isNew}
                        onClick={quote.form.preferredQuote ? () => {} : updatePreferred}
                      >
                        Set as Preferred
                      </DropdownItem>
                      <DropdownItem header>Order</DropdownItem>
                      <DropdownItem
                        disabled={!isCrmReady()}
                        onClick={toggleOrderWizard}
                      >Order Form
                      </DropdownItem>
                      <DropdownItem
                        disabled={!isCrmReady() || !hasCancellations()}
                        onClick={toggleCancellationForm}
                      >Cancellation Form
                      </DropdownItem>
                      <DropdownItem
                        disabled={quote.loading || !isCrmReady() || isCloned || isNew}
                        onClick={toggleConvertToOrderModal}
                      >Convert to Order
                      </DropdownItem>
                      <DropdownItem header>Download</DropdownItem>
                      <DropdownItem
                        disabled={!isCrmReady()}
                        onClick={() => downloadProposal('xls')}
                      >Quote.xls</DropdownItem>
                      <DropdownItem
                        disabled={!isCrmReady()}
                        onClick={toggleContactModal}
                      >Quote.pdf
                      </DropdownItem>

                    </DropdownMenu>
                  </Dropdown>
                  {toggleModal &&
                    <ButtonIcon onClick={onClosing} icon='fa fa-lg fa-close' tooltip={'Close Popup'}/>
                  }
                </div>
              </Col>
            </Row>
            {isClosed() &&
              <Row>
                <Col>
                  <Alert color={'warning'}>
                    <span>This quote is now closed.</span>
                  </Alert>

                </Col>
              </Row>
            }
            <FormValidationErrors errors={errors}/>
            <Row className="d-flex justify-content-between">
              <Col className={"d-flex"}>
                <EntityMainFormCard grow >
                  <Row form>
                    {generateFormFields({
                      fields: getForm(),
                      handleInput,
                      getSelectOptions,
                      getSelectedOption,
                      handleSelectInput,
                      handleAsyncSelected,
                      handleAsyncInput,
                      data: quote.form,
                      optionSets
                    })}
                  </Row>
                </EntityMainFormCard>
              </Col>
              {isNew &&
                <>
                  <Col className={"d-flex"}>
                    <EntitySubFormCard title={'Products'} grow>
                      <Row form>
                        <>
                          {generateFormFields({
                            fields: newQuoteProductInputForm,
                            getSelectOptions,
                            getSelectedOption,
                            handleSelectInput,
                            handleAsyncSelected,
                            handleAsyncInput,
                            data: {serviceToAdd: productToAdd, products: quote.form.products, opportunity},
                            optionSets
                          })}
                        </>
                        <Col>
                          <Button block color='light' onClick={() => addProduct()}>Add Product</Button>
                        </Col>
                      </Row>
                    </EntitySubFormCard>
                  </Col>
                  <Col className={'d-flex'}>
                    <EntitySubFormCard title={'Pricing Tool'} grow>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label className={'control-label w-100 fw-bold'}>Pricing Tool</Label>
                            <InputGroup>
                              <Input placeholder="Enter Quote Id"
                                     onChange={(event) => setCompareQuoteId(event.target.value)}/>
                              <Button color='secondary' disabled={hasCompareQuote()} onClick={importCompareQuote}>Import</Button>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <Button block color='light' disabled={hasCompareQuote()} onClick={toggleQuickQuote}>Launch Pricing Tool</Button>
                        </Col>
                      </Row>
                    </EntitySubFormCard>
                  </Col>
                </>
              }
            </Row>
            <Row>
              <Col>
                <EntitySubFormCard title={'Products'}>
                  <QuoteProducts
                    activeServices={activeServices}
                    newQuote={isNew}
                    inputHandler={handleQuoteProductInput}
                    currency={opportunity.priceList.currency.id}
                  />
                </EntitySubFormCard>

              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                  title={'Notes'}
                >
                  <Notes
                    withNew={!isNew && isCrmReady()}
                    notes={quote.form.notes}
                    relatedTo={{type: 'quote', data: quote.form}}
                    onCreated={note => dispatch(addQuoteNote(note))}
                    onUpdated={note => dispatch(updateQuoteNote(note))}
                    onDeleted={note => dispatch(removeQuoteNote(note))}
                  />
                </CollapsibleCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                    title={'Audit History'}
                    onEntering={() => isEmpty(quote.audits) ? getAudits() : () => {}}
                >
                  <AuditHistory auditHistory={quote.audits} loading={auditsLoading} fetchData={getAudits}/>
                </CollapsibleCard>
              </Col>
            </Row>
        </CardBody>
      </Card>
      </LoadingOverlay>
      <Modal
        isOpen={showContactModal}
        toggle={toggleContactModal}
        backdrop={'static'}
      >
        <ModalHeader color={'warning'} toggle={toggleContactModal}>Quote Contact</ModalHeader>
        <ModalBody>
          <p>Please select a <strong>{quote.form.customer ? quote.form.customer.name : ''}</strong> contact to proceed.
          </p>
          <div>
            <SelectMod
              getOptionLabel={(opt) => opt.label || opt.name}
              getOptionValue={(opt) => opt.value || opt.id}
              options={getContactOptions()}
              isSearchable
              onChange={setQuoteContact}
            />
          </div>
          <div className='d-flex justify-content-end mt-3'>
            <Button
              disabled={!quoteContact.value}
              color='primary'
              onClick={() => {
                downloadProposal('pdf');
                toggleContactModal();
              }}
            >
              Proceed
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showOrderWizard}
        toggle={toggleOrderWizard}
        backdrop={'static'}
        keyboard={false}
        size='xlg'
      >
        <ModalHeader color={'primary'} toggle={toggleOrderWizard}>hSo Order Form</ModalHeader>
        <ModalBody>
          <OrderFormWizard
            quote={quote}
            contacts={contacts}
            serviceOrderChangeHandler={handleOrderFormServiceOrderChange}
            handleOrderFormInput={handleOrderFormInput}
            addServiceLocation={addOrderFormLocation}
            resetServiceLocations={resetOrderFormServiceLocations}
            handleServiceLocationInput={handleServiceLocationInput}
            removeServiceLocation={removeOrderFormServiceLocation}
            generateOrderForm={downloadOrderForm}
            priceList={opportunity.priceList}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showCloseDateModal}
        toggle={toggleConvertToOrderModal}
        backdrop={'static'}
      >
        <ModalHeader color={'warning'} toggle={toggleConvertToOrderModal}>Convert To Order</ModalHeader>
        <ModalBody>
          <Row>
            {generateFormFields({
              fields: ConvertToOrderForm(quote.original.customer),
              handleInputDate: (field, date) => {
                setOrderData({
                  ...orderData,
                  closeDate: date
                });
              },
              handleInput: event => {
                setOrderData({
                  ...orderData,
                  [event.target.id] :  event.target.value
                })
              },
              data: orderData
            })}
          </Row>
          <div className='d-flex justify-content-end mt-3'>
            <Button
              disabled={!orderData.closeDate}
              color='primary'
              onClick={convertToOrder}
            >
              Go
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showQuickQuote}
        toggle={toggleQuickQuote}
        backdrop={'static'}
        keyboard={false}
        size='xlg'
      >
        <ModalHeader color={'primary'} toggle={toggleQuickQuote}>hSo Compare - Quick Quote</ModalHeader>
        <ModalBody>
          <QuoteRequest
            name={`Quick Quote - ${opportunity.customer.name}`}
            onComplete={() => setShowQuickQuote(false)}
          />
        </ModalBody>
      </Modal>
      <HeadlessModal
        size={'xlg'}
        open={showCancellationForm}
        toggle={toggleCancellationForm}
      >
        <CancellationFormWizard
          products={getCancellationLinkedProducts()}
          closeModal={toggleCancellationForm}
          submit={downloadCancellationForm}
          loading={quote.cancellationForm.loading}
        />
      </HeadlessModal>
    </div>
  );
};

export default Quote;
