import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import ReactTable from 'react-table-v6'
import { SpinnerInfo } from '../../components/Spinner/Spinner'
import { getPrefixLists } from '../../utils/PrefixLists/PrefixListService'
import { Link, useNavigate } from 'react-router-dom'

const PrefixLists = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    setLoading(true)
    getPrefixLists().then((result) => {
      if (result.status === 200) {
        setLoading(false)
        setData(result.data)
      }
    }).catch((error) => {
      alert(error)
    })
  }, [])

  const columns = useMemo(() => [{
    filterable: true,
    sortable: true,
    Header: 'Name',
    accessor: 'name'
  }, {
    filterable: false,
    Header: 'IP Protocol',
    accessor: 'protocol'
  },
    {
      filterable: true,
      Header: 'Macro',
      accessor: 'macro'
    }, {
      filterable: false,
      Header: 'Source',
      accessor: 'source'
    }, {
      filterable: false,
      Header: 'Last Updated',
      accessor: 'last_change'
    }, {
      id: 'devicescount',
      filterable: false,
      Header: 'Routers',
      accessor: d => d.devices.length
    }, {
      Header: '',
      filterable: false,
      sortable: false,
      accessor: 'name',
      width: 50,
      Cell: ({ value }) => <Link to={`/toolbox/prefixlists/${value}`}><i className="icon-arrow-right"></i></Link>
    }
  ], [])
  return (
    <div>
      {loading
        && <div className="justify-content-center">
          <SpinnerInfo/>
        </div>
      }
      {!loading && data.length > 0
        && <Row>
          <Col className={'d-flex'}>
            <Card className="w-100">
              <CardBody>
                {!loading
                  && <ReactTable
                    getTheadProps={() => {
                      return {
                        style: { border: 'none' }
                      }
                    }}
                    getTheadTrProps={() => {
                      return {
                        style: { border: 'none' }
                      }
                    }}
                    getTheadThProps={() => {
                      return {
                        style: { border: 'none' }
                      }
                    }}
                    getTdProps={(state, rowInfo, column) => {
                      return {
                        style: { border: 'none', fontWeight: column.Header === 'Name' ? 'bold' : 'regular' }
                      }
                    }}
                    getTrProps={(state, rowInfo, column) => {
                      return {
                        style: { cursor: rowInfo ? 'pointer' : 'auto' },
                        onClick: () => {
                          navigate('/toolbox/prefixlists/' + rowInfo.row.name)
                        }
                      }
                    }}
                    className="animated fadeIn -highlight -striped"
                    noDataText="No Prefix Lists found."
                    data={data}
                    columns={columns}
                    minRows="1"
                    filterable={false}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                    defaultSorted={[{ id: 'name', desc: false }]}
                    showPagination={data.length > 20}
                  />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>}
    </div>
  )
}

export default PrefixLists
