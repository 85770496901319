import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Row, Col, Button, CardFooter, Alert } from 'reactstrap';
import moment from 'moment';
import Avatar from '../Avatar';
import Loader from 'react-spinners/BeatLoader';
import isArray from 'lodash.isarray';

const Shifts = ({
  shifts,
  team,
  title
}) => {
  const [day, setDay] = useState(  moment().startOf('day'))

  const handleChangeDay = (delta) => {
    let cd = day.clone();
    cd.add(delta, 'days');
    setDay(cd);
  };

  const getColor = (color) => {
    const cmap = {
      green: '#448d46',
      pink: '#e83e8c',
      blue: '#6BA4B8',
      yellow: '#bea455'
    };
    return cmap[color] ? cmap[color] : color;
  }

  const getResponse = () => {
    if (shifts.shiftdata[team] === undefined || shifts.timeoffdata[team] === undefined) {
      return (<p className="text-center"><Loader color={'grey'}/></p>);
    }

    const sd = isArray(shifts.shiftdata[team]) ? shifts.shiftdata[team].filter(({ start }) => moment(start).startOf('day').unix() === day.unix()) : [];
    const hd = isArray(shifts.timeoffdata[team]) ? shifts.timeoffdata[team].filter(({start, end}) => moment(start).startOf('day').unix() <= day.unix() && moment(end).endOf('day').unix() >= day.unix()) : [];
    sd.sort(function (a, b) {
      if(a.name === null) { return 1; }
      if(b.name === null) { return -1; }
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      if (a.user?.name < b.user?.name) {
        return -1;
      }
      if (a.user?.name > b.user?.name) {
        return 1;
      }
      return 0;
    });
    if (sd.length === 0) {
      return (<div>
        <Alert color="warning">No shifts today</Alert>
      </div>);
    }
    return (<div>
      {sd.map((shift, index) => {
        let isLeave = 1;
        hd.forEach((item) => {
          if (item.userId === shift.userId) {
            isLeave = 0.25;
          }
        });
        return (<Row key={`${shift.userId}${index}`} style={{
          borderLeftColor: getColor(shift.theme),
          opacity: isLeave
        }} className={'callout callout-info'}>
          <Col sm={4}>
            <Avatar name={shift.user.name} email={shift.user.email ? shift.user.email.toLowerCase() : ''}
                    src={process.env.REACT_APP_API_URL + '/shifts/userphoto/' + shift.userId} size={48}
                    className="img-avatar"/>
          </Col>
          <Col sm={8}>
            <h5 style={{ color: getColor(shift.theme) }}>{isLeave !== 1 &&
            <span><i className="fa fa-plane"></i> </span>}{shift.name}</h5>
            <p>{shift.user.name}</p>
          </Col>
        </Row>);
      })}
    </div>);
  };

  if (shifts.shiftdata[team] === undefined) {
    return (<Loader/>);
  }


  return (
    <div>
      <Card>
        <CardHeader>
          {title}
        </CardHeader>
        <CardBody style={{ minHeight: '380px' , maxHeight: '600px', overflowY: 'scroll'}}>
          {getResponse()}
        </CardBody>
        <CardFooter>
          {shifts.shiftdata[team].length >= 1 &&
          <Row>
            <Col md={2}>
              <Button size={'sm'} className={'px-2'}
                      disabled={day.unix() === moment().startOf('day').unix()}
                      onClick={() => handleChangeDay(-1)}><i className="fa fa-chevron-left"/></Button>
            </Col>
            <Col md={8} className="text-center text-muted">
              {day.format('dddd Do MMM')}
            </Col>
            <Col md={2} className="text-end">
              <Button size={'sm'}
                      disabled={day.unix() === moment(shifts.shiftdata[team][shifts.shiftdata[team].length - 1].start).startOf('day').unix()}
                      onClick={() => handleChangeDay(1)}><i className="fa fa-chevron-right"/></Button>
            </Col>
          </Row>
          }
        </CardFooter>
      </Card>
    </div>
  );
}

function mapStateToProps({ shifts }) {
  return {
    shifts
  };
}

export default connect(mapStateToProps)(Shifts);
