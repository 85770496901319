import React, { useState } from 'react';
import {
  Alert,
  Col,
  Form,
  Input,
  InputGroup,
  Row
} from 'reactstrap';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';

export default ({ onSubmit }) => {
  const [searchText, setSearchText] = useState('');
  const [searching, setSearching] = useState(false);
  const [error, setError] = useState(false);

  const handleSearchInput = (event) => {
    if (error && searchText.length >= 5) {
      setError(false);
    }
    setSearchText(event.target.value);
  };

  const valid = () => {
    setError(false);
    if (searchText.length < 5) {
      setError(true);
      return false;
    }
    return true;
  };

  const submit = () => {
    if (valid()) {
      setSearching(false);
      onSubmit(searchText)
        .then(() => {
          setSearching(false);
        });
    }
  };

  return (
    <Row className={'mb-3'}>
      <Col md={4}>
        <Form onSubmit={submit}>
          <InputGroup>
            <Input value={searchText} placeholder={'Enter case ref...'}
                   onChange={handleSearchInput}/>
            <LaddaButton
              className="btn btn-primary btn-ladda"
              loading={searching}
              data-style={ZOOM_OUT}
              disabled={false}
              onClick={(e) => {
                e.preventDefault();
                submit();
              }}
            >Search</LaddaButton>
          </InputGroup>
        </Form>
      </Col>
      {error ? (
        <Col md={4}>
          <Alert color={'danger'} className={'py-1 m-0'}>
            <p className={'mb-0'}>Please enter at least 5 characters</p>
          </Alert>
        </Col>
      ) : ''}
    </Row>
  );
}
;
