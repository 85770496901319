import React, {Component} from 'react';
import {Card, CardBody, Row, Col, Input} from 'reactstrap';
import {
  addRadiusAttribs,
  deleteRadiusAttribs, deleteRadiusUser, fetchRadiusUsers,
  newAttributeBox, RADIUS_SET_ACTIVE_BOX,
  setActiveAttrib,
  setActiveBox, settingNewAttributes
} from '../../actions/radius';
import {connect} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {errorFeedback, successFeedback} from '../../actions/feedback';
import {Button} from 'reactstrap';
import {setConfirmDialog} from "../../actions/dialogs";

class AttribBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: '',
      activeText: '',
      activeAttribs: {},
      originalAttribs: {},
      activeKey: null,
      activeType: '',
      activeBox: '',
      toolTips: {},
      addAttrib: false
    };
    this.toggleToolTip = this.toggleToolTip.bind(this);
    this.deleteAttribs = this.deleteAttribs.bind(this);
    this.editAttribute = this.editAttribute.bind(this);
    this.returnAttribObject = this.returnAttribObject.bind(this);
  }

  toggleToolTip(tooltipId) {
    if (!this.state.toolTips[tooltipId]) {
      this.setState({
        ...this.state,
        toolTips: {[tooltipId]: true}
      });
    } else {
      this.setState({
        ...this.state,
        toolTips: {}
      });
    }
  }

  returnAttribObject = (id, attrib, op, value) => {
    return {
      id: id,
      attribute: attrib,
      op: op,
      value: value
    };
  };

  updateAttribs = (attrib, op, value) => {
    this.setState({activeText: value});
    this.setState({activeAttribs: this.returnAttribObject(attrib, op, value)});
  };

  getAttributeType = name => {
    return name === 'ucheckattribs' ? 'check' : 'reply'
  }

  deleteAttribs = (editing = false, id) => {
    this.props.dispatch(setActiveBox(this.props.type));
    this.props.dispatch({type: RADIUS_SET_ACTIVE_BOX, activeBox: this.props.type});
    this.props.dispatch(setConfirmDialog({
      color: 'danger',
      text: 'Are you sure you want to delete?',
      proceed: () => {
        this.props.dispatch(deleteRadiusAttribs(
          id,
          this.props.activeUser,
          this.getAttributeType(this.props.type),
        )).then((r) => {
          let action = editing ? 'edited' : 'deleted';
          if (r) {
            this.props.dispatch(successFeedback(`Attribute successfully ${action}`));
          } else {
            this.props.dispatch(errorFeedback('Attribute deletion failed'));
          }
        });
      },
      cancel: () => {}
    }));

  };

  editAttribute = () => {
    // Add new attribute and delete the existing one after to prevent deletion on the DB
    this.setState({editing: ''});

    this.props.dispatch(settingNewAttributes(true));

    const newObj = this.returnAttribObject(
      this.state.originalAttribs['id'],
      this.state.originalAttribs['attribute'],
      this.state.originalAttribs['op'],
      this.state.activeText,
    )

    this.props.dispatch(addRadiusAttribs(
      this.props.activeUser, newObj, this.getAttributeType(this.props.type),
    ));
  };

  render() {
    const title = this.props.title;
    return (
      <Card className="card-accent-secondary animated fadeIn">
        <CardBody>
          <Row>
            <Col>
              <h6>{this.props.title}</h6>
            </Col>
            <Col
              md={2} sm={6}
              className="pe-0"
            >
            </Col>
            <Col md={1} sm={6}>
              <i
                data-for="add"
                data-tip="add"
                className="fa fa-plus-square ps-2 float-end"
                onClick={() => {
                  this.props.dispatch(setActiveAttrib(this.props.type));
                  this.props.dispatch(newAttributeBox(true));
                }}
                style={{
                  cursor: 'pointer'
                }}
              />
              <ReactTooltip
                id={'add'}
                type="dark"
                place="left"
                effect="float"
                multiline={false}
              >add
              </ReactTooltip>
            </Col>
          </Row>

          <hr/>
          {
            this.props.data
              ? this.props.data.map((value, key) => {
                return (
                  <div key={key}>
                    <Row>
                      <Col md={3} sm={4}>
                        <p className="d-inline font-sm" key={key}>{value.attribute}</p>
                      </Col>
                      <Col md={1} sm={1} className="p-0">
                        <p className="d-inline font-sm" key={key}>{value.op}</p>
                      </Col>
                      <Col md={this.state.editing ? 8 : 7} sm={this.state.editing ? 6 : 6}>
                        {
                          this.state.editing === `${this.props.type}-${key}`
                            ? <>
                              <Input className="animated fadeIn fw-bolder w-100"
                                     bsSize="sm"
                                     onChange={(e) => {
                                       this.updateAttribs(value.attribute, value.op, e.target.value);
                                     }}
                                     value={this.state.activeText}
                              />
                              <div className="d-inline justify-content-center mt-1 mb-1">
                                <Button color="danger"
                                        outline
                                        size="sm"
                                        onClick={this.editAttribute}
                                        className="mt-1 mb-1 me-1"
                                >Save <i className="ps-1 icon-like"/>
                                </Button>
                                <Button color="warning"
                                        outline
                                        size="sm"
                                        onClick={() => this.setState({
                                          editing: ''
                                        })}
                                >Cancel <i className="ps-1 icon-dislike"/>
                                </Button>
                              </div>
                            </>
                            : <p id={`${this.props.type}-${key}`}
                                 className="d-inline font-sm"
                                 key={key}>{value.value}
                            </p>
                        }

                      </Col>
                      {
                        !this.state.editing
                          ? <Col md={1} sm={1} className="d-flex justify-content-center p-0">
                            <div
                              className="me-1 -right animated fade-exit"
                              style={{cursor: 'pointer'}}
                            >
                              <i
                                data-for={`${key}-${title.split(' ')[0].toLowerCase()}-edit`}
                                data-tip="edit"
                                className="fa fa-wrench"
                                onClick={
                                  () => {
                                    this.setState(
                                      {
                                        editing: `${this.props.type}-${key}`,
                                        activeText: value.value,
                                        originalAttribs: this.returnAttribObject(
                                          value.id,
                                          value.attribute,
                                          value.op,
                                          value.value
                                        ),
                                        activeKey: key
                                      }
                                    );
                                  }
                                }>
                              </i>
                              <ReactTooltip
                                id={`${key}-${title.split(' ')[0].toLowerCase()}-edit`}
                                type="dark"
                                place="top"
                                effect="float"
                                multiline={false}
                              >edit
                              </ReactTooltip>
                            </div>
                            <div className="d-inline me-1 justify-content-right ps-0 pe-0"
                                 style={{cursor: 'pointer'}}>
                              <i
                                data-for={`${key}-${title.split(' ')[0].toLowerCase()}-delete`}
                                data-tip="delete"
                                onClick={() => {
                                  this.deleteAttribs(false, value.id,
                                    key);
                                }}
                                className="fa fa-trash ps-1">
                              </i>
                              <ReactTooltip
                                id={`${key}-${title.split(' ')[0].toLowerCase()}-delete`}
                                type="error"
                                place="top"
                                effect="float"
                                multiline={false}
                              >delete
                              </ReactTooltip>
                            </div>
                          </Col>
                          : null
                      }
                    </Row>
                  </div>
                );
              })
              : null
          }
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    attribBox: state.radius.newAttributeBox,
    activeUser: state.radius.activeUser,
    deletingAttrib: state.radius.deletingAttrib,
    activeAttrib: state.radius.activeAttrib,
    activeBox: state.radius.activeBox,
    settingAttrib: state.radius.settingNewAttrib
  };
}

export default connect(mapStateToProps)(AttribBox);
