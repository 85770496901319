import { connect, useDispatch } from 'react-redux'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash.isempty';
import {
  addCrossConnectNote,
  createCrossConnect, deleteCrossConnect,
  getCrossConnect,
  getCrossConnectAuditHistory, removeCrossConnectNote, resetCrossConnect, updateCrossConnect,
  updateCrossConnectField, updateCrossConnectNote
} from '../../../actions/crossConnect';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { Badge, Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import classnames from 'classnames';
import UnsavedChangesAlert from '../../../components/Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../../../components/ButtonIcon';
import { canAccessServiceDb, canCreateCarrierCircuit } from '../../../utils/Auth/AuthService';
import FormValidationErrors from '../../../components/Errors/FormValidationErrors';
import DeactivatedEntityWarning from '../../../components/Alerts/DeactivatedEntityWarning';
import EntityMainFormCard from '../../../components/Cards/EntityMainFormCard';
import GenericForm from '../../../components/GenericForm';
import CarrierCircuitForm from '../CarrierCircuit/form';
import CollapsibleCard from '../../../components/CollapsibleCard';
import Notes from '../../../components/Notes';
import AuditHistory from '../../../components/AuditHistory';
import HeadlessModal from '../../../components/Modals/HeadlessModal';
import { diff } from 'deep-object-diff';
import Rack from '../Rack';
import NNI from '../NNI';
import { ProductEnums } from '../../../utils/Constants/Product';
import { setConfirmDialog } from '../../../actions/dialogs';
import { createCircuitAssociation, deleteCircuitAssociation } from '../../../actions/product';
import { RackService } from '../../../utils/ServiceDB/Rack';
import { searchProducts } from '../../../actions/circuit';
import CrossConnectForm from './form';
import ApiErrorResolver from '../../../helpers/ApiErrorResolver';
import resolveArgs from '../../../helpers/ArgumentResolver';
import { formValidator } from '../../../helpers/FormValidator';
import { addBreadcrumbs, resetBreadcrumbs } from '../../../actions/breadcrumbs';
import OrderProductsTable from '../../../components/Tables/OrderProducts';
import { useNavigate, useParams } from 'react-router-dom'
import { NavigationBlocker } from '../../../components/NavigationBlocker';

const include = [
  'aEnd',
  'bEnd',
  'notes',
  'notes.createdBy',
  'crmCircuit',
  'orderProducts',
  'orderProducts.site',
];

const entityInitialState = {type: '', id: ''}
const CrossConnect = ({
  id = null,
  crossConnect: {
    original,
    data,
    audits
  },
  suppliers,
  presentationTypes,
  orderProduct = null,
  closeModal = null,
  onCreated = null,
  onUpdated = null
}) => {

  // router
  const navigate = useNavigate();
  const params = useParams();

  // redux
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [auditsLoading, setAuditsLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const [showEntityModal, setShowEntityModal] = useState(false);
  const [entity, setEntity] = useState(entityInitialState);

  const crossConnectId = useMemo(() => (id || params.crossConnectId), [id, params.crossConnectId])
  const isNew = useMemo(() => crossConnectId === 'new' && !original.id, [crossConnectId, original.id])

  const toggleLoading = () => setLoading(prevState => !prevState)
  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState);
  const toggleProductsLoading = () => setProductsLoading(prevState => !prevState)
  const toggleEntityModal = () => setShowEntityModal(prevState => !prevState)

  useEffect(() => {
    if(!isNew){
      fetchData()
    }
    return () => dispatch(resetCrossConnect())
  }, [])

  useEffect(() => {
    if(entity.id){
      toggleEntityModal()
    }
  }, [entity]);

  useEffect(() => {
    if(!closeModal){
      dispatch(addBreadcrumbs([{ name: original.name ?? 'New' }]))
    }

    return () => {
      if(!closeModal){
        dispatch(resetBreadcrumbs());
      }

    };
  }, [original?.name]);
  const fetchData = () => {
    toggleLoading()
    setErrors([])
    dispatch(getCrossConnect(original.uuid ?? crossConnectId, include)).then(() => toggleLoading())
  }

  const save = () => {
    if(validated()){
      if(isNew){
        toggleLoading()
        dispatch(createCrossConnect(resolveArgs(data), include)).then((result) => {
          if(result?.errors){
            setErrors(ApiErrorResolver(result.errors))
            toggleLoading()
          } else if(result){
            if(orderProduct){
              dispatch(createCircuitAssociation(orderProduct.id, result.uuid, 'crossConnect')).then(result2 => {
                if(result2 && onCreated){
                  onCreated(result);
                }
                toggleLoading()
              })
            } else{
              onCreated?.(result);
              toggleLoading()
            }
            if(!closeModal){
              navigate(`/sdb/cross-connects/${result.uuid}`)
            }
          }else{
            toggleLoading()
          }
        })
      }else{
        const toSave = diff(original, data);
        toggleLoading()
        dispatch(updateCrossConnect(crossConnectId, resolveArgs(toSave), include)).then((result) => {
          if(result?.errors){
            setErrors(ApiErrorResolver(result.errors))
          }
          else if(result && onUpdated){
            onUpdated(result)
          }
          toggleLoading()
        })
      }
    }
  }

  const validated = () => {
    const form= CarrierCircuitForm()
    let errorArr = formValidator(CrossConnectForm(),  data);
    setErrors(errorArr);
    return isEmpty(errorArr);
  };
  const hasChanges = () => {
    const changes = diff(original, data);
    return !isEmpty(changes)
  }

  const getEntityComponent = () => {
    if(entity.type === 'rack'){
      return <Rack
        id={entity.id}
        closeModal={() => {
          toggleEntityModal()
          setEntity(entityInitialState)
        }}
      />
    }
    if(entity.type === 'nni'){
      return <NNI
        id={entity.id}
        closeModal={() => {
          toggleEntityModal()
          setEntity(entityInitialState)
        }}
      />
    }
  }
  const options = {
    supplier: Object.values(suppliers).map(({id, name, accountNumber, isActive}) => ({
      value: id,
      label: `${name} (${accountNumber})`,
      isDisabled: !isActive
    })),
    presentationType: presentationTypes,
    type: [
      {label: 'Copper', value: 'copper'},
      {label: 'SMF', value: 'smf'},
      {label: 'MMF', value: 'mmf'},
    ]
  };

  const onClose  = () => {
    if(hasChanges()){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => closeModal()
      }))
    }else{
      closeModal()
    }
  }

  const handleClick = (entityType) => {
    if(entityType === 'bEnd'){
      setEntity({type: 'rack', id: data.bEnd.id})
    }
    if(entityType === 'aEnd' && data.aEnd.type === 'nni'){
      setEntity({type: 'nni', id: data.aEnd.data.id, size: 'xxlg'})
    }
    if(entityType === 'aEnd' && data.aEnd.type === 'rack'){
      setEntity({type: 'rack', id: data.aEnd.data.id})
    }
  }

  const isProductOptionDisabled = (lifecycleStage) => {
    return lifecycleStage === ProductEnums.lifecycleStage.CEASED ||
      lifecycleStage === ProductEnums.lifecycleStage.CANCELED
  }

  const onDeleting = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "You are about to delete this carrier circuit!",
      proceed: () => {
        toggleLoading()
        dispatch(deleteCrossConnect(crossConnectId)).then(result => {
          toggleLoading()
          if(result){
            navigate('/sdb/cross-connects')
          }
        })
      }
    }))
  }
  const canDelete = () => {
    return isEmpty(original.deviceModel) && isEmpty(original.component)
  }

  const isProductAssignable = () => {
    return isEmpty(data.orderProduct)  ||
      (data.orderProduct?.lifecycleStage !== ProductEnums.lifecycleStage.CEASED &&
        data.orderProduct?.lifecycleStage !== ProductEnums.lifecycleStage.CANCELED)
  }

  const onRemoveProductAssociation = (productId) => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "You are about to remove the link between this product and the circuit!",
      proceed: () => {
        toggleProductsLoading()
        dispatch(deleteCircuitAssociation(productId, original.id)).then(result => {
          if(result){
            //dispatch(removeCarrierCircuitProduct(productId))
          }
          toggleProductsLoading()
        })
      }
    }))
  }

  const aSyncOptions = {
    rack: (search) => {
      return RackService.list(1, 100, search)
        .then((result) => result?.data?.items || [])
    },
    orderProduct: search => {
      return dispatch(searchProducts([], [
        { id: 'productNumber', value: search }
      ], [], 'or', [], undefined)).then((result) => result.map(product => ({...product, isDisabled: isProductOptionDisabled(product.lifecycleStage)})))
    }
  };

  const setField = (field, value) => {
    dispatch(updateCrossConnectField(field, value ?? null));
  };

  const selectValue = {
    supplier: () => options.supplier.find(opt => data.supplier?.id ? data.supplier?.id === opt.value : data.supplier === opt.value ) || null,
  };

  const getAudits = () => {
    toggleAuditsLoading()
    dispatch(getCrossConnectAuditHistory(original.uuid)).then(() => toggleAuditsLoading())
  }


  return (
    <>
      <div className="animated fadeIn">
        <NavigationBlocker shouldBlock={hasChanges()}/>
        <LoadingOverlay loading={loading}>
          <Card className='bg-light border-0 mb-3'>
            <CardBody>
              <Row className='mb-2'>
                <Col className="d-flex justify-content-between">
                  <CardTitle>
                    {original?.name || 'New Cross Connect'}
                    <Badge className={'ms-2 p-2 faded-info'}>ServiceDB</Badge>
                  </CardTitle>
                  <div className={classnames('d-flex', 'align-items-center', 'animated', 'fadeIn')}>
                    {
                      hasChanges() && <UnsavedChangesAlert save={save}/>
                    }
                    {!isNew && <ButtonIcon
                      disabled={!canDelete()}
                      icon={'fa fa-trash'}
                      tooltip={canDelete() ? 'Delete' : `Carrier circuit has existing ${!isEmpty(original.deviceModel) ? 'device model' : 'connection'}`}
                      onClick={onDeleting}
                    />}
                    <ButtonIcon disabled={!canAccessServiceDb() || loading || !hasChanges()}
                                icon={'fa fa-save'} tooltip={'Save'} onClick={save}/>
                    <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'} onClick={fetchData}/>
                    {closeModal &&
                      <ButtonIcon onClick={onClose} icon="fa fa-lg fa-close" tooltip={'Close Popup'}/>
                    }
                  </div>
                </Col>
              </Row>
              <FormValidationErrors errors={errors}/>
              <DeactivatedEntityWarning deactivated={!isProductAssignable()} message={'The associated Product is not in use.'}/>
              <Row className={'d-flex'}>
                <Col className={"d-flex col-12 col-sm-12 col-md-6 col-lg-6"}>
                  <EntityMainFormCard grow>
                    <GenericForm
                      data={data}
                      form={CrossConnectForm(handleClick)}
                      options={options}
                      selectValue={selectValue}
                      aSyncOptions={aSyncOptions}
                      setField={setField}
                    />
                  </EntityMainFormCard>
                </Col>
              </Row>
              {!isNew &&
                <>
                  <Row>
                    <Col>
                      <CollapsibleCard
                        title={'Linked Products'}
                      >
                        <LoadingOverlay loading={productsLoading}>
                          <OrderProductsTable
                            excludeColumns={['term', 'install', 'rental']}
                            fetchData={fetchData}
                            remove={(event, productId) => {
                              event.stopPropagation()
                              onRemoveProductAssociation(productId)
                            }}
                            products={original.orderProducts ?? []}
                          />
                        </LoadingOverlay>

                      </CollapsibleCard>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CollapsibleCard
                        title={'Notes'}
                      >
                        <Notes
                          withNew
                          notes={data.notes}
                          relatedTo={{type: 'crossConnect', data}}
                          onCreated={note => dispatch(addCrossConnectNote(note))}
                          onUpdated={note => dispatch(updateCrossConnectNote(note))}
                          onDeleted={note => dispatch(removeCrossConnectNote(note))}
                        />
                      </CollapsibleCard>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CollapsibleCard
                        title={'Cases'}
                      >
                        Coming soon...
                      </CollapsibleCard>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CollapsibleCard
                        title={'Audit History'}
                        onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
                      >
                        <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
                      </CollapsibleCard>
                    </Col>
                  </Row>
                </>
              }
            </CardBody>
          </Card>
        </LoadingOverlay>
        <HeadlessModal
          open={showEntityModal}
          onClosed={() => setEntity(entityInitialState)}
          size={entity.size ?? 'xxlg'}
          toggle={toggleEntityModal}
        >
          <Row>
            <Col>
              {getEntityComponent()}
            </Col>
          </Row>

        </HeadlessModal>
      </div>
    </>
  )



}

function mapStateToProps({ crossConnect, helpers }) {
  return {
    crossConnect,
    suppliers: helpers.suppliers,
    presentationTypes: helpers.optionSets.crossConnect.presentationType.options
  };
}

export default connect(mapStateToProps)(CrossConnect)
