import React, { Fragment } from 'react';
import classnames from 'classnames';
import { Col, FormGroup, Input, InputGroup, Label, Button } from 'reactstrap';
import { SelectMod } from '../components/Selects/SelectMod';
import { AsyncSelectMod } from '../components/Selects/AsyncSelectMod';
import { DateTimePicker } from 'react-widgets';
import moment from 'moment';
import numeral from 'numeral';
import debounce from 'debounce-promise';
import Select from 'react-select';
import ConditionalWrapper from '../components/ConditionalWrapper';
import { AsyncCreatableSelectMod } from '../components/Selects/AsyncCreatableSelectMod';
import { Link } from 'react-router-dom';

export default (props) => {
  const {
    fields,
    handleInput,
    handleInputGroup,
    handleInputGroupClick,
    handleClick,
    getSelectOptions,
    getAsyncSelectDefaultOptions,
    getSelectedOption,
    handleSelectInput,
    handleAsyncSelected,
    handleAsyncInput,
    handleInputDate,
    handleAsyncCreateOption,
    colWidth,
    data,
    optionSets,
    permissions
  } = props;

  const debouncedAsync = debounce((entity, key, length, search) => handleAsyncInput(entity, key, length, search), 500);

  const formatText = (format, value) => {
    if(format?.money){
      return `${format.money}${numeral(value)
        .format('0,0.00')}`;
    }
    if (format === 'money') {
      return `£${numeral(value)
        .format('0,0.00')}`;
    }
    if (format === 'integer') {
      const parsed = parseInt(value);
      return isNaN(parsed) ? '' : parsed;
    }
    if (format === 'date') {
      return moment(value).format('DD/MM/YYYY');
    }
    if (format === 'datetime') {
      return moment(value).format('DD/MM/YYYY HH:mm');
    }
    return value;
  };

  const resolveReadOnly = (readOnly, key, field) => {
    if (typeof readOnly === 'undefined') {
      return false;
    }
    if (typeof readOnly === 'boolean') {
      return readOnly;
    }
    return readOnly(key, field, data, permissions);
  };

  const resolvePlainText = (plaintext, key, field) => {
    if (typeof plaintext === 'undefined') {
      return false;
    }
    if (typeof plaintext === 'boolean') {
      return plaintext;
    }
    return plaintext(key, field, data, permissions);
  };

  const resolveShow = (show, key, field) => {
    if (typeof show === 'undefined') {
      return true;
    }
    if (typeof show === 'boolean') {
      return show;
    }
    return show(field, data, permissions);
  };

  const resolveType = (type, key, field) => {
    if (typeof type === 'string') {
      return type;
    }
    return type(key, field, data);
  };

  const resolveWidth = (width, field) => {
    if (typeof width === 'string' || typeof width === 'number') {
      return width;
    }
    return width(field, data);
  };

  const resolveMandatory = (mandatory) => {
    if (typeof mandatory === 'function') {
      return mandatory(data);
    }
    return mandatory;
  };

  const resolveClearable = (field) => {
    if (resolveMandatory(field.mandatory)) {
      return false;
    }
    return field.clearable === undefined ? true : Boolean(field.clearable);
  };

  const resolveEntity = (entity) => {
    if (typeof entity === 'function') {
      return entity(data);
    }
    return entity;
  };

  const resolveOptionLabel = (opt, field) => {
    opt = opt.data || opt;
    return field.optionLabelCallback
      ? field.optionLabelCallback(opt)
      : (opt.label || opt.orderNumber || opt.name || opt.hostname);
  };

  return Object.entries(fields)
    .map(([key, field]) => {
      if (!resolveShow(field.show, key, field)) {
        return '';
      }
      const plaintext = resolvePlainText(field.plaintext, key, field);
      const type = plaintext ? 'text' : resolveType(field.type, key, field);
      return (
        <Fragment key={'field' + key}>
          <Col md={resolveWidth(field.width || colWidth || 6, field)}>
            <FormGroup className={classnames({
              'required': resolveMandatory(field.mandatory)
            })} onClick={field.clickable && handleClick ? () => handleClick(key) : () => {}}>
              <Label className={'control-label w-100 fw-bold'}>{field.labelCallback ? field.labelCallback(data) : field.label}</Label>
              <ConditionalWrapper condition={field.addon}
                                  wrapper={children => <InputGroup>{children}</InputGroup>}>
                {(type === 'text')
                  && <Input
                    plaintext={plaintext}
                    className={classnames({
                      'form-plaintext-font': plaintext,
                      'hoverPointer': Boolean(field.clickable)
                    })}
                    type="text"
                    name={key}
                    id={key}
                    value={field.callBack ? field.callBack(key, field, data, optionSets) ?? '' : ((data[key] || data[key] === 0) ? formatText(field.format, data[key]) : '')}
                    readOnly={resolveReadOnly(field.readOnly, key, field)}
                    onChange={!plaintext ? handleInput : () => {}}
                    maxLength={field.maxlength}
                  />
                }
                {(type === 'number')
                  && <Input
                    plaintext={plaintext}
                    className={plaintext ? 'form-plaintext-font' : ''}
                    type="number"
                    name={key}
                    id={key}
                    value={field.callBack ? field.callBack(key, field, data, optionSets) : (data[key] || data[key] === 0) ? formatText(field.format, data[key]) : ''}
                    readOnly={resolveReadOnly(field.readOnly, key, field)}
                    onChange={!plaintext ? handleInput : () => {}}
                    max={field.max}
                    min={field.min}
                    minLength={field.minLength}
                    maxLength={field.maxLength}
                  />
                }
                {(type === 'inputgroup')
                  &&
                  <InputGroup>
                    <Input
                      id={key}
                      placeholder={field.placeholder}
                      value={data[key]}
                      onChange={(event) => handleInputGroup(key, event.target.value)}
                    />
                    <Button
                      color="secondary"
                      onClick={() => handleInputGroupClick(key)}
                    >
                      {field.button}
                    </Button>
                  </InputGroup>
                }

                {type === 'textarea'
                  && <Input
                    type="textarea"
                    name={key}
                    id={key}
                    value={data[key] || ''}
                    readOnly={resolveReadOnly(field.readOnly, key, field)}
                    onChange={handleInput}
                    rows={field.rows || 10}
                  />
                }
                {type === 'select'
                  && <SelectMod
                    getOptionLabel={(opt) => resolveOptionLabel(opt, field)}
                    getOptionValue={(opt) => opt.value || opt.id}
                    handleClick={field.handleClick}
                    openMenuOnClick={!field.handleClick}
                    options={getSelectOptions(key)}
                    isClearable={resolveClearable(field)}
                    isSearchable
                    placeholder={field.placeholder}
                    value={getSelectedOption(key)}
                    onChange={handleSelectInput.bind(null, key)}
                    isDisabled={resolveReadOnly(field.readOnly, key, field)}
                  />
                }
                {type === 'select-boolean'
                  && <SelectMod
                    options={[{
                      label: 'No',
                      value: false
                    }, {
                      label: 'Yes',
                      value: true
                    }]}
                    isSearchable
                    placeholder=""
                    value={data[key] === true ? {
                      label: 'Yes',
                      value: true
                    } : data[key] === false ? {
                      label: 'No',
                      value: false
                    } : undefined}
                    onChange={handleSelectInput.bind(null, key)}
                    isDisabled={resolveReadOnly(field.readOnly, key, field)}
                  />
                }
                {(type === 'select-async' || type === 'select-async-multi')
                  && <AsyncSelectMod
                    openMenuOnClick={!field.handleClick}
                    handleClick={field.handleClick}
                    isClearable={resolveClearable(field)}
                    isDisabled={resolveReadOnly(field.readOnly, key, field)}
                    onChange={handleAsyncSelected.bind(null, key)}
                    value={data[key]}
                    cacheOptions={field.cacheOptions || false}
                    getOptionLabel={(opt) => resolveOptionLabel(opt, field)}
                    getOptionValue={(opt) => opt.id || opt.accountid}
                    placeholder={field.placeholder || null}
                    loadOptions={(search) => debouncedAsync(resolveEntity(field.entity), key, field.minSearchLength || 2, search)}
                    defaultOptions={getAsyncSelectDefaultOptions ? getAsyncSelectDefaultOptions(key) : []}
                    isMulti={type === 'select-async-multi'}
                  />
                }
                {(type === 'select-async-creatable' || type === 'select-async-creatable-multi')
                  && <AsyncCreatableSelectMod
                    isClearable={field.clearable === undefined ? true : Boolean(field.clearable)}
                    isDisabled={resolveReadOnly(field.readOnly, key, field)}
                    onChange={handleAsyncSelected.bind(null, key)}
                    value={data[key]}
                    cacheOptions
                    getOptionLabel={(opt) => resolveOptionLabel(opt, field)}
                    getOptionValue={(opt) => opt.id || opt.accountid}
                    placeholder={field.placeholder || null}
                    loadOptions={(search) => debouncedAsync(resolveEntity(field.entity), key, field.minSearchLength || 2, search)}
                    defaultOptions={getAsyncSelectDefaultOptions ? getAsyncSelectDefaultOptions(key) : []}
                    onCreateOption={handleAsyncCreateOption.bind(null, key)}
                    isMulti={type === 'select-async-multi'}
                  />
                }
                {type === 'select-multi'
                  && <Select
                    value={getSelectedOption(key)}
                    isMulti
                    isFocused
                    closeMenuOnSelect={false}
                    options={getSelectOptions(key)}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleSelectInput.bind(null, key)}
                    isDisabled={resolveReadOnly(field.readOnly, key, field)}
                  />
                }
                {type === 'date'
                  && <DateTimePicker
                    format="DD-MM-YYYY"
                    id={key}
                    name={key}
                    time={false}
                    min={field.min}
                    max={field.max}
                  value={data[key] ? moment(data[key])
                      .toDate() : null}
                    onChange={handleInputDate.bind(null, key)}
                    disabled={resolveReadOnly(field.readOnly, key, field)}
                  />
                }
                {type === 'button-link'
                  && <Link to={field.getLink(data)} style={{textDecoration: 'none'}}>
                    <Button
                      color={'light'}
                      block
                      disabled={field.isDisabled(data)}
                      className={'d-flex align-items-start'}
                    >
                      {field.callBack ? field.callBack(key, field, data, optionSets) : (data[key] || data[key] === 0) ? formatText(field.format, data[key]) : ''}
                    </Button>
                  </Link>
                }
                {type === 'datetime'
                  && <DateTimePicker
                    format="DD-MM-YYYY HH:mm"
                    id={key}
                    name={key}
                    time={true}
                    min={field.min}
                    disabled={resolveReadOnly(field.readOnly, key, field)}
                    value={data[key] ? moment(data[key])
                      .toDate() : null}
                    onChange={handleInputDate.bind(null, key)}
                  />
                }
                {type === 'custom'
                  && <>
                    {field.callBack ? field.callBack(key, field, data, optionSets) : ''}
                  </>
                }
                {field.addon ? (
                  <Button>{field.addon}</Button>
                ) : ''}
              </ConditionalWrapper>
            </FormGroup>
          </Col>
          {field.unit
            && <Col>
              <FormGroup className={resolveMandatory(field.mandatory) ? 'required' : ''}>
                <Label className={'control-label'} for={key}>Unit</Label>
                <SelectMod
                  getOptionLabel={(opt) => opt.label || opt.name}
                  getOptionValue={(opt) => opt.value || opt.id}
                  options={getSelectOptions(field.unit)}
                  isSearchable
                  placeholder=""
                  value={getSelectedOption(field.unit)}
                  onChange={handleSelectInput.bind(null, field.unit)}
                />
              </FormGroup>
            </Col>
          }
        </Fragment>
      );
    });
};
