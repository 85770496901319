import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import { Alert, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { ButtonIcon } from '../ButtonIcon';
import isEmpty from 'lodash.isempty';
import { diff } from 'deep-object-diff';
import generateFormFields from '../../helpers/FormFieldGenerator';
import resolveArgs from '../../helpers/ArgumentResolver';
import LoadingOverlay from '../LoadingOverlay';
import debounce from 'debounce-promise';
import ApiErrorResolver from '../../helpers/ApiErrorResolver';
import { setConfirmDialog } from '../../actions/dialogs';

const GenericCardForm = ({
  formConfig,
  id,
  isNew,
  title,
  data,
  original,
  extraErrors,
  form,
  extraFormData,
  onFetch,
  onFetchAll,
  onFetched,
  onReset,
  setField,
  options,
  aSyncOptions,
  onCreateOption,
  requestStructure,
  extraFieldValidation,
  saveButtonDisabledCallback,
  onCreate,
  onCreated,
  onUpdate,
  onUpdated,
  onDelete,
  onDeleted,
  onSubmitted,
  extraButtonsBefore,
  extraButtons,
  selectValue,
  canEdit,
  deleteButtonDisabled,
  deleteButtonDisabledTooltip,
  withoutLoader
}) => {
  // redux
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const forms = formConfig
    ? formConfig.map((formConfigItem, index) => ({
      ...formConfigItem,
      fields: Object.fromEntries(Object.entries(form).filter(([, field]) => (index === 0 && !field.form) || field.form === formConfigItem.name))
    }))
    : [{ title, fields: form }];

  const fetch = () => {
    const promises = [];
    if (!isNew && onFetch) {
      promises.push(onFetch());
    }
    if (onFetchAll) {
      promises.push(onFetchAll());
    }
    if (promises.length) {
      setLoading(true);
      Promise.all(promises)
        .then(() => {
          setLoading(false);
          if (onFetched) {
            onFetched()
          }
        });
    }
  };

  useEffect(() => {
    fetch();

    return () => {
      if (onReset) {
        onReset();
      }
    };
  }, [id]);

  const handleInput = (event) => {
    setField(event.target.id, event.target.value);
  };

  const handleSelectInput = (key, selected) => {
    setField(key, selected?.value);
  };

  const handleInputDate = (key, value) => {
    setField(key, value);
  };

  const handleAsyncSelected = (key, selected) => {
    setField(key, selected);
  };

  const handleAsyncInput = (entity, key, length, search) => {
    if (!search || search.length < length) {
      return new Promise(() => []);
    }
    if (aSyncOptions && aSyncOptions[entity]) {
      return aSyncOptions[entity](search, key);
    }
  };

  const handleAsyncCreateOption = (key, input) => {
    return onCreateOption[key](input);
  };

  const getSelectOptions = (field) => options[field];

  const getSelectedOption = (field) => {
    if (selectValue && selectValue[field]) {
      return selectValue[field]();
    }
    if (typeof data[field] === 'object' && data[field] !== null && data[field].id) {
      return options[field]?.find(option => option.value.id === data[field].id);
    }
    return options[field]?.find(option => option.value === data[field]);
  };

  const confirmDelete = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: 'Are you sure you want to delete this item?',
      proceed: destroy,
      cancel: () => {}
    }));
  };

  const destroy = () => {
    setLoading(true);
    onDelete()
      .then(result => {
        if (!result) {
          setErrors(['There was an issue deleting this item. Please try again.']);
        } else {
          if (onDeleted) {
            onDeleted();
          }
        }
        setLoading(false);
      });
  };

  const validated = () => {
    const errorArr = [];
    Object.entries(form)
      .forEach(([key, value]) => {
        if (extraFieldValidation) {
          const extra = extraFieldValidation(key, value);
          if (extra) {
            errorArr.push(...extra);
          }
        }
        if (data[key] === undefined || data[key] === null || data[key].length === 0) {
          const mandatory = value.mandatory === true || (typeof value.mandatory === 'function' && value.mandatory(data));
          if (mandatory) {
            errorArr.push(`You must provide a valid ${value.label}.`);
          }
        } else {
          if (value.minlength && data[key].length < value.minlength) {
            errorArr.push(`${value.label} must be at least ${value.minlength} characters.`);
          }
          if (value.min && data[key] < value.min) {
            errorArr.push(`${value.label} must be at least ${value.min}.`);
          }
          if (value.max && data[key] > value.max) {
            errorArr.push(`${value.label} must not be greater than ${value.max}.`);
          }
        }
      });
    setErrors([...new Set(errorArr)]);
    return errorArr.length === 0;
  };

  const save = () => {
    if (validated()) {
      if (!isNew) {
        const toUpdate = diff(requestStructure(original), requestStructure(data));
        if (!isEmpty(toUpdate)) {
          setSaving(true);
          onUpdate(resolveArgs(requestStructure(data)))
            .then(result => {
              setSaving(false);
              if (result?.status === 422) {
                setErrors([...new Set(ApiErrorResolver(result.data))]);
              } else if (result && (result.status === undefined || result.status === 200)) {
                if (onUpdated) {
                  onUpdated(result.status !== undefined ? result.data : result);
                }
                if (onSubmitted) {
                  onSubmitted(result.status !== undefined ? result.data : result);
                }
              } else {
                setErrors(['There was an issue updating this item. Please try again.']);
              }
            });
        }
      } else {
        setSaving(true);
        onCreate(resolveArgs(requestStructure(data)))
          .then(result => {
            setSaving(false);
            if (result?.status === 422) {
              setErrors([...new Set(ApiErrorResolver(result.data))]);
            } else if (result && (result.status === undefined || result.status === 200)) {
              if (onCreated) {
                onCreated(result.status !== undefined ? result.data : result);
              }
              if (onSubmitted) {
                onSubmitted(result.status !== undefined ? result.data : result);
              }
            } else {
              setErrors(['There was an issue creating this item. Please try again.']);
            }
          });
      }
    }
  };

  const extraButtonsMap = (button, i) => {
    if (button.icon && button.onClick) {
      return <ButtonIcon
        key={`extra-button-${i}`}
        icon={`fa fa-${button.icon}`}
        loading={button.loading}
        tooltip={button.tooltip}
        onClick={button.onClick}
      />;
    } else {
      return button;
    }
  };

  return (
    <LoadingOverlay loading={!withoutLoader && loading}>
      <Row>
        {forms.map((form, index) => (
          <Col { ...form.col } key={`${index}-${form.label}`}>
            <Card>
              <CardHeader>
                <Row>
                  <Col><strong>{form.title || title}</strong></Col>
                  {index === 0 ? (
                    <Col className="d-flex justify-content-end">
                      {extraButtonsBefore ? extraButtonsBefore.map(extraButtonsMap) : ''}
                      {canEdit !== false ? (
                        <ButtonIcon
                          icon={'fa fa-save'}
                          disabled={saveButtonDisabledCallback ? saveButtonDisabledCallback() : isEmpty(diff(requestStructure(original), requestStructure(data)))}
                          tooltip="Save"
                          loading={saving}
                          onClick={save}/>
                      ) : ''}
                      {!isNew ? (
                        <>
                          <ButtonIcon
                            icon="fa fa-refresh"
                            tooltip="Refresh"
                            onClick={fetch}/>
                          {(onDelete && canEdit !== false) ? (
                            <ButtonIcon
                              icon="fa fa-trash"
                              tooltip={deleteButtonDisabledTooltip && deleteButtonDisabled ? deleteButtonDisabledTooltip : 'Delete'}
                              disabled={deleteButtonDisabled || false}
                              onClick={confirmDelete}/>
                          ) : ''}
                        </>
                      ) : ''}
                      {extraButtons ? extraButtons.map(extraButtonsMap) : ''}
                    </Col>
                  ) : ''}
                </Row>
              </CardHeader>
              <CardBody>
                {index === 0 && errors.concat(extraErrors || []).length > 0 ? (
                  <Row>
                    <Col>
                      <Alert color={'danger'}>
                        {errors.concat(extraErrors || [])
                          .map((error, i) => <div key={`error${i}`}>{error}</div>)}
                      </Alert>
                    </Col>
                  </Row>
                ) : ''}
                <Row form>
                  {generateFormFields({
                    fields: form.fields,
                    data: { ...data, ...extraFormData },
                    handleInput,
                    getSelectOptions,
                    getSelectedOption,
                    handleSelectInput,
                    handleAsyncSelected,
                    handleAsyncInput: debounce(handleAsyncInput, 500),
                    handleInputDate,
                    handleAsyncCreateOption
                  })}
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </LoadingOverlay>);
};

export default connect()(GenericCardForm);
