import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Form, FormGroup, Button, Card, CardBody, CardHeader, Col, Input, Row} from "reactstrap";
import {configTemplateRequest} from "../../utils/ConfigTemplate";
import {errorFeedback, successFeedback} from "../../actions/feedback";
import {SET_NOTE} from "../../actions/configTemplate";
import {checkPermission} from "../../utils/Auth/AuthService";

const Notes = ({activeEntry, dispatch, auth}) => {
    const [buttonColor, setButtonColor] = useState("info");
    const [data, setData] = useState(undefined);

    useEffect(() => (
        setData(activeEntry.notes)
    ), [activeEntry.notes])

    const toggleSaveButtonColor = () => {
        setButtonColor("danger");
        setTimeout(() => setButtonColor("info"), 500);
    }

    const handleNotesSave = () => {
        toggleSaveButtonColor();

        configTemplateRequest(
            activeEntry.id,
            `configtemplate/${activeEntry.id}`,
            'put',
            {description: activeEntry.description, notes: data}
        ).then(r => {
            if (r.status === 200) {
                dispatch(successFeedback('Saved Note'));
                dispatch({type: SET_NOTE, data: data});
            } else {
                dispatch(errorFeedback('Notes save failed'));
            }
        })
    }

    return (
        <Card className='card-accent-dark fadeIn animated'>
            <CardHeader style={{paddingBottom: '8px'}}>
                <Row className="justify-content-center align-content-center align-items-center">
                    <Col md={6}>
                        <h5 className="m-0 mirror d-inline">Notes</h5>
                    </Col>
                    <Col>
                        {
                            checkPermission("ConfigTemplates", auth.account.permissions) ?
                                <Button
                                    size='sm'
                                    style={{top: 50, right: 30, zIndex: 10, height: 33, width: 33}}
                                    color={buttonColor}
                                    className='m-1 float-end'
                                    onClick={() => handleNotesSave()}
                                ><i className="fa fa-save"/>
                                </Button>
                                : null
                        }
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Form>
                    <FormGroup>
                        <Input
                            type="textarea"
                            name="text"
                            value={data || ""}
                            onChange={e => setData(e.target.value)}
                        ></Input>
                    </FormGroup>
                </Form>
            </CardBody>

        </Card>
    )
}

function mapStateToProps({configTemplate, authenticationState}) {
    return {
        auth: authenticationState,
        activeEntry: configTemplate.activeEntry
    };
}

export default connect(mapStateToProps)(Notes);
