import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import LoadingOverlay from '../../components/LoadingOverlay';
import FormValidationErrors from '../../components/Errors/FormValidationErrors';
import React, { useState } from 'react';
import EntityMainFormCard from '../../components/Cards/EntityMainFormCard';
import generateFormFields from '../../helpers/FormFieldGenerator';
import CollapsibleCard from '../../components/CollapsibleCard';
import OrderProductsTable from '../../components/Tables/OrderProducts';
import { ButtonIcon } from '../../components/ButtonIcon';
import classnames from 'classnames';
import { formValidator } from '../../helpers/FormValidator';
import isEmpty from 'lodash.isempty';


const form = {
  servicesInInitialPeriod : { label: 'Services in initial period?', type: 'select-boolean', width: 3, mandatory: true},
  servicesOutOfInitialPeriod : { label: 'Services out of initial period?', type: 'select-boolean', width: 3, mandatory: true},
  noticePeriod : { label: 'Notice Period', type: 'number',  min: 1, max: 365,width: 3, mandatory: true},
  endDate : { label: 'End Date', type: 'date', width: 3, mandatory: true},
  reason : { label: 'Reason', type: 'textarea', rows: 10, width: 12},
}

const CancellationFormWizard = ({
  products,
  closeModal,
  submit,
  loading
}) => {
  const [errors, setErrors] = useState([]);
  const [cancellation, setCancellation] = useState({
    servicesInInitialPeriod: null,
    servicesOutOfInitialPeriod: null,
    noticePeriod: null,
    endDate: null,
    reason: '',
  });
  const onClosing = () => {
    closeModal()
  }



  const onGenerate = () => {
    if(validated()){
      submit(cancellation)
    }
  }

  const validated = () => {
    let errorArr = formValidator(form, cancellation);
    setErrors(errorArr);
    return isEmpty(errorArr);
  }
  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <Card className='bg-light border-0 mb-0'>
          <CardBody>
            <Row className={'mb-2'}>
              <Col className={'d-flex'}>
                <CardTitle>Cancellation Form</CardTitle>
                <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ms-auto')}>
                  <Button size={'sm'} className={'faded-primary ms-auto'} color={'primary'} onClick={onGenerate}>Generate</Button>
                  {closeModal &&
                    <ButtonIcon onClick={onClosing} icon='fa fa-lg fa-close' tooltip={'Close Popup'}/>
                  }
                </div>
              </Col>

            </Row>
            <FormValidationErrors errors={errors}/>
            <Row>
              <Col>
                <EntityMainFormCard>
                  <Row form>
                    {generateFormFields({
                      fields: form,
                      handleInput: (event) => {setCancellation({...cancellation, [event.target.id] : event.target.value})},
                      handleInputDate: (key, value) => {setCancellation({...cancellation, [key] : value})},
                      handleSelectInput: (key, selected) => {setCancellation({...cancellation, [key] : selected.value})},
                      data: cancellation
                    })}
                  </Row>
                </EntityMainFormCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard open={true} title={'Products'}>
                  <OrderProductsTable
                    products={products}
                    includeColumns={['productNumber', 'name', 'rental', 'term', 'goLiveDate']}
                  />
                </CollapsibleCard>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </LoadingOverlay>
    </div>
  )

}

export default CancellationFormWizard
