import FilteredTableV2 from '../../FilteredTableV2';
import React, { useState } from 'react';
import HeadlessModal from '../../Modals/HeadlessModal';
import Site from '../../../views/Site';
import isEmpty from 'lodash.isempty';
import ExtraButtons from '../../../utils/Helpers/ExtraButtons';
import { Badge, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ButtonIcon } from '../../ButtonIcon';
import { CSVLink } from 'react-csv';
import moment from 'moment/moment';
import omit from 'lodash.omit';

const SitesTable = ({
  account,
  sites,
  fetchData
}) => {

  const [selectedSite, setSelectedSite] = useState(null)
  const [showSiteModal, setShowSiteModal] = useState(false)
  const toggleModal = () => {
    setShowSiteModal(prevState => !prevState)
  }

  const getExportData = () => {
    return sites.map(site =>  {
      return {
        ...omit(site, ['accountid', 'primaryContact']),
        primaryContact: site.primaryContact?.name || ''
      }
    })
  }
  const columns =
    [
      {
        header: 'Id',
        accessorKey: 'accountnumber',
        maxSize: 50,
      },
      {
        header: 'Name',
        accessorKey: 'name',
        minSize: 200,
      },
      {
        header: 'Address',
        accessorKey: 'fullAddress',
        minSize: 200,
      },
      {
        header: 'Phone',
        accessorKey: 'telephone',
      },
      {
        header: 'Primary Contact',
        accessorKey: 'primaryContact',
        cell: props => !isEmpty(props.getValue()) ?
          <div>
            <span>{props.getValue().name}</span>
            {!props.getValue().isActive && <span className={'ms-2 text-warning'}>Deactivated Contact!</span>}
          </div>
           : ''
      },
      {
        header: 'Status',
        accessorKey: 'isActive',
        cell: props =>
          <Badge style={{minWidth: '86px'}} className={`p-2 faded-${props.getValue() ? 'success' : 'warning'}`}>{props.getValue() ? 'Active' : 'Deactivated'}</Badge>
      },
    ]

  const tableFilter = (row, columnId, value) => {
    return (String(row.original.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.accountnumber)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.orderNumber)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.fullAddress)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1 ||
      String(row.original.primaryContact?.name)
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1
    )
  }
  return (
    <>
      <FilteredTableV2
        withDeactivatedRow
        withFilter={tableFilter}
        minRows={10}
        columns={columns}
        data={sites}
        showPagination={sites.length > 20}
        defaultPageSize={20}
        pageSizeOptions={[20,50,100,200]}
        noDataText={'No Sites'}
        onRowClick={(site) => {
          setSelectedSite(site)
          toggleModal()

        }}
        defaultSorted={[{ id: 'isActive', desc: true }]}
        extraButtons={[
          <Button size={'sm'} color={'secondary'} onClick={() => {
            setSelectedSite({id: "new"})
            toggleModal()
          }}>New Site</Button>,
          <div data-for={'export'} data-tip={'export'} >
            <CSVLink id={'export'}
                     data={getExportData()} filename={`contacts-${moment().format('DD-MM-YYYY')}.csv`}
                     style={{ textDecoration: 'none' }}>

              <ButtonIcon onClick={() => {}} icon={'fa fa-cloud-download'} tooltip={'export'}/>
            </CSVLink>
          </div>,

          <ButtonIcon icon={'fa fa-refresh'} tooltip={'reload'} onClick={fetchData}/>
        ]}
      />
      <HeadlessModal
        open={showSiteModal}
        size={'xlg'}
        toggle={toggleModal}
        onClosed={() => setSelectedSite(null)}
      >
       <Site
         id={selectedSite?.accountid}
         account={account}
         closeModal={toggleModal}
       />
      </HeadlessModal>
    </>
  )

}

export default SitesTable
