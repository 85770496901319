import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import { ButtonIcon } from '../../../components/ButtonIcon';
import { Equipment, Rack as RackD, Resizable } from 'react-network-diagrams-hso';
import { backStyle, equips, pattern, rackStyle1 } from '../constants';
import React, { useState } from 'react';
import EntitySubFormCard from '../../../components/Cards/EntitySubFormCard';

const RackDiagram = ({ rack, showAddDeviceButton, setAddDeviceOpen, setSelected, selected }) => {

  const [showHeight, setShowHeight] = useState(false);
  const [showOverlap, setShowOverlap] = useState(false);

  const renderEquipment = (devices, orientation) => {
    return devices.map((equipment) => {

      let labelPosition = 'top';
      let labelDirection = 'horizontal';
      let width = equipment.stockItem.deviceModel.rackWidth === 'full' ? 17.52 : 17.52 / 2;
      if (equipment.rackStartPosition === 0) {
        width = 1.75;
        labelDirection = 'vertical';
        labelPosition = 'center';
      }
      return (
        <Equipment
          key={`${equipment.id}-${equipment.rackStartPosition}`}
          equipmentHeight={1.75 * equipment.stockItem.deviceModel.rackUnits}
          equipmentWidth={width}
          rmu={equipment.rackStartPosition}
          side={equipment.stockItem.deviceModel.rackWidth === 'full' ? null : orientation === 'front' ? equipment.rackSide : equipment.rackSide === 'L' ? 'R' : 'L'}
          facing={equipment.rackOrientation.charAt(0)
            .toUpperCase() + equipment.rackOrientation.slice(1)
            .toLowerCase()}
          style={equips[equipment.type]}
          backStyle={backStyle}
          overlapStyle={showOverlap ? { fill: '#ff6666' } : {}}
          selected={selected === equipment.id}
          label={equipment.hostname}
          labelDirection={labelDirection}
          labelPosition={labelPosition}
          navTo={equipment.id}
          showHeight={showHeight}
          onSelectionChange={setSelected ? (e, val) => setSelected(val) : () => {}}
        />
      );
    });
  };

  return (
    <EntitySubFormCard grow title={'Rack Diagram'}>
      <Row className={"d-flex "}>
        <Col className="d-flex justify-content-between">
                    <span className={'text-muted'}
                          style={{ marginRight: '6px' }}>Show Heights</span>
            <Label
              className="switch switch-text switch-sm switch-pill switch-primary-outline-alt m-0">
              <Input type="checkbox" className="switch-input" checked={showHeight}
                     onChange={() => setShowHeight(prevState => !prevState)}/>
              <span className="switch-label" data-on="On" data-off="Off"/>
              <span className="switch-handle"/>
            </Label>
            <span className={'text-muted'} style={{
              marginLeft: '20px',
              marginRight: '6px'
            }}>Highlight Overlaps</span>
            <Label
              className="switch switch-text switch-sm switch-pill switch-primary-outline-alt m-0">
              <Input type="checkbox" className="switch-input" checked={showOverlap}
                     onChange={() => setShowOverlap(prevState => !prevState)}/>
              <span className="switch-label" data-on="On" data-off="Off"/>
              <span className="switch-handle"/>
            </Label>
            {showAddDeviceButton ?
                <Button
                  className={'ms-auto'}
                  disabled={false}
                  size={'sm'}
                  color={'secondary'}
                  onClick={() => setAddDeviceOpen(prevState => !prevState)}
                >
                  Add Device
                </Button> : ''}
        </Col>
      </Row>
      <Row>
        <Col>
          <Resizable>
            <RackD
              rackHeight={Number(rack.size)}
              rackWidth={19}
              pxToInch={10}
              label={rack.name}
              rackStyle={rackStyle1}
              facing={'Front'}
              pattern={pattern}
              displayRmu={true}
              descending={false}
            >
              {renderEquipment(rack.deviceDeployments, 'front')}
            </RackD>
          </Resizable>
        </Col>
        <Col>
          <Resizable>
            <RackD
              rackHeight={Number(rack.size)}
              rackWidth={19}
              pxToInch={10}
              label={rack.name}
              rackStyle={rackStyle1}
              facing={'Back'}
              pattern={pattern}
              displayRmu={true}
              descending={false}
            >
              {renderEquipment(rack.deviceDeployments, 'back')}
            </RackD>
          </Resizable>
        </Col>
      </Row>
    </EntitySubFormCard>
  );
};

export default RackDiagram;
