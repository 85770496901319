import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import LoadingOverlay from '../../../components/LoadingOverlay';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import UnsavedChangesAlert from '../../../components/Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../../../components/ButtonIcon';
import { diff, detailedDiff } from 'deep-object-diff';
import isEmpty from 'lodash.isempty';
import { setConfirmDialog } from '../../../actions/dialogs';
import DeactivatedEntityWarning from '../../../components/Alerts/DeactivatedEntityWarning';
import FormValidationErrors from '../../../components/Errors/FormValidationErrors';
import EntityMainFormCard from '../../../components/Cards/EntityMainFormCard';
import generateFormFields from '../../../helpers/FormFieldGenerator';
import { BillTemplateGroupForm } from './form';
import CollapsibleCard from '../../../components/CollapsibleCard';
import {
  createBillTemplateGroup, deactivateBillTemplateGroup,
  getBillTemplateGroup,
  removeBillTemplateGroupProduct,
  removeBillTemplateGroupProductFromOriginal,
  resetBillTemplateGroup, restoreBillTemplateGroup,
  updateBillTemplateGroup,
  updateBillTemplateGroupFormField
} from '../../../actions/billTemplateGroup';
import BillTemplateGroupProductsTable from '../../../components/Tables/BillTemplateGroupProducts';
import { formValidator } from '../../../helpers/FormValidator';
import resolveArgs from '../../../helpers/ArgumentResolver';
import { updateOrderProduct } from '../../../actions/order';
import { updateProduct } from '../../../actions/product';
import orderProduct from '../../OrderProduct';
const BillTemplateGroup = ({
  id,
  billTemplate,
  billTemplateGroup : {
    form,
    original
  },
  closeModal,
  onCreate,
  onUpdate,
  dispatch
}) =>{
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [productsToRemove, setProductsToRemove] = useState([]);
  const isNew = !id && !original?.id
  const toggleLoading = () => {
    setLoading(prevState => !prevState)
  }

  useEffect(() => {
    setProductsToRemove([])
    if(!isNew){
      refreshData()
    }

    return () => {
      dispatch(resetBillTemplateGroup())
    }
  }, [])
  const refreshData = () =>{
    toggleLoading()
    dispatch(getBillTemplateGroup(billTemplate.original.id, id || original.id)).then(() => toggleLoading())
  }
  const save = () => {
    if(validated()){
      if(isNew){
        toggleLoading()
        dispatch(createBillTemplateGroup(billTemplate.original.id, resolveArgs(form))).then((result) => {
          toggleLoading()
          if(result && onCreate){
            onCreate(result)
          }
        })
      }else{
        const toSave = diff(original, form);
        const promises = []
        toggleLoading()
        if(!isEmpty(productsToRemove)){
          productsToRemove.forEach((productId) => {
            promises.push(dispatch(updateProduct(productId, {billTemplateGroup: null} )).then(() => {
              dispatch(removeBillTemplateGroupProductFromOriginal(productId))
            }))
          })
        }
        Promise.all(promises).then(() => {
          if(!isEmpty(toSave)){
            dispatch(updateBillTemplateGroup(billTemplate.original.id, id, resolveArgs(toSave))).then(() => toggleLoading())
          }else{
            toggleLoading()
          }
        })

      }
    }
  }


  const restore = () => {
    toggleLoading()
    dispatch(restoreBillTemplateGroup(billTemplate.original.id, original.id)).then((result) => {
      toggleLoading()
      if(result && onUpdate){
        onUpdate(result)
      }
    })
  }

  const deactivate = () => {
    toggleLoading()
    dispatch(deactivateBillTemplateGroup(billTemplate.original.id, original.id)).then((result) => {
      toggleLoading()
      if(result && onUpdate){
        onUpdate(result)
      }
    })
  }

  const validated = () => {
    let errorArr = formValidator(BillTemplateGroupForm, form);
    setErrors(errorArr);
    return isEmpty(errorArr);
  }

  const onClosing = () => {
    if(hasChanges()){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => {
          dispatch(resetBillTemplateGroup())
          closeModal()
        }
      }))
    }else if(closeModal){
      closeModal()
      dispatch(resetBillTemplateGroup())
    }
  }
  const hasChanges = () => {
    const changes = diff(original, form);
    return !isEmpty(changes) && isActive()
  }
  const isActive = () => {
    return original.isActive || isNew
  }

  const onDeactivate = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "This will deactivate the template group and remove all product reference to it.",
      proceed: () => deactivate()
    }))
  }
  const onRestore = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "This will reactivate the template group.",
      proceed: () => restore()
    }))
  }
  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <Card className='bg-light border-0 mb-0'>
          <CardBody>
            <Row className='mb-2'>
              <Col className='d-flex'>
                <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ms-auto')}>
                  {
                    hasChanges() && <UnsavedChangesAlert save={save}/>
                  }
                  {isActive() && !isNew &&
                    <Button
                      onClick={onDeactivate}
                      color={'secondary'}
                      size={'sm'}
                      className={'ms-2'}
                    >
                      Deactivate
                    </Button>
                  }
                  {!isActive() &&
                    <Button
                      onClick={onRestore}
                      color={'secondary'}
                      size={'sm'}
                      className={'ms-2'}
                    >
                      Restore
                    </Button>
                  }
                  <ButtonIcon disabled={loading || !hasChanges()} icon={'fa fa-save'} tooltip={'Save'}  onClick={save}/>
                  <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={refreshData}/>
                  {closeModal &&
                    <ButtonIcon onClick={onClosing} icon='fa fa-lg fa-close' tooltip={'Close Popup'}/>
                  }
                </div>
              </Col>
            </Row>
            <DeactivatedEntityWarning deactivated={!original.isActive} message={'This template group has been deactivated.'}/>
            <FormValidationErrors errors={errors}/>
            <Row>
              <Col>
                <EntityMainFormCard>
                  <Row form>
                    {generateFormFields({
                      fields: BillTemplateGroupForm,
                      handleInput: (event) => dispatch(updateBillTemplateGroupFormField({[event.target.id] : event.target.value})),
                      data: form
                    })}
                  </Row>
                </EntityMainFormCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                  title={'Products'}
                >
                  <BillTemplateGroupProductsTable
                    products={form.orderProducts ?? []}
                    onRemove={(product) => {
                      dispatch(removeBillTemplateGroupProduct(product))
                      setProductsToRemove([...productsToRemove, product])
                    }}
                  />
                </CollapsibleCard>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </LoadingOverlay>
    </div>
  )
}

const mapStateToProps = ({
  billTemplate,
  billTemplateGroup
}) => ({
  billTemplate,
  billTemplateGroup
});

export default connect(mapStateToProps)(BillTemplateGroup)
