import React, { Component, useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Badge, Button } from 'reactstrap'
import { SpinnerInfo } from '../../components/Spinner/Spinner'
import {
  getPrefixListContents, getPrefixListInfo, savePrefixListInfo, addPrefix, deletePrefix, addJob,
  importPrefixList, deletePrefixList
} from '../../utils/PrefixLists/PrefixListService'
import PropTypes from 'prop-types'
import LaddaButton, { ZOOM_OUT } from 'react-ladda'
import { connect, useDispatch } from 'react-redux'
import { errorFeedback, successFeedback } from '../../actions/feedback'
import { setConfirmDialog } from '../../actions/dialogs'
import { useNavigate, useParams } from 'react-router-dom'

const PrefixList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { listName } = useParams()

  const [loadingData, setLoadingData] = useState(true)
  const [loadingRouters, setLoadingRouters] = useState(true)
  const [loadingInfo, setLoadingInfo] = useState(true)
  const [loadingSave, setLoadingSave] = useState(false)
  const [loadingAdd, setLoadingAdd] = useState(false)
  const [publishing, setPublishing] = useState(false)
  const [contents, setContents] = useState([])
  const [info, setInfo] = useState({ devices: [] })

  useEffect(() => {
    setLoadingData(true)
    setLoadingInfo(true)
    getPrefixListContents(listName).then((result) => {
      if (result.status === 200) {
        setContents(result.data)
        setLoadingData(false)
      }
    }).catch((error) => {
      alert(error)
      setLoadingData(false)
    })
    getPrefixListInfo(listName).then((result) => {
      if (result.status === 200) {
        setInfo(result.data)
        setLoadingInfo(false)
        setLoadingRouters(false)
      }
    }).catch((error) => {
      alert(error)
      setLoadingInfo(false)
      setLoadingRouters(false)
    })
  }, [listName])

  const importList = (event, router) => {
    event.preventDefault()
    setLoadingData(true)
    importPrefixList(listName, router).then((result) => {
      if (result.status === 200) {
        getPrefixListContents(listName).then((result) => {
          if (result.status === 200) {
            setContents(result.data)
            setLoadingData(false)
          }
        }).catch((error) => {
          alert(error)
          setLoadingData(false)
        })
      }
    }).catch((error) => {
      alert(error)
      setLoadingData(false)
    })
  }

  const deleteList = (event) => {
    event.preventDefault()
    if (
      info.devices.length === 0 &&
      ((info.source === 'internal' && contents.length === 0) || info.source !== 'internal')
    ) {
      dispatch(setConfirmDialog({
        color: 'danger',
        text: `Are you sure you want to delete the prefix list ${listName}?`,
        proceed: () => {
          deletePrefixList(listName).then((result) => {
            if (result.status === 204) {
              navigate('/toolbox/prefixlists')
              alert('List deleted')
            } else {
              alert(result.data.message)
            }
          }).catch((error) => {
            alert(error)
          })
        },
        cancel: () => { }
      }))
    } else {
      alert('Cannot delete a list that is assigned to routers or has local data.')
    }
  }

  const publishRequest = (event) => {
    event.preventDefault()
    setPublishing(true)
    if (contents.length >= 1) {
      addJob({
        job: 'tasks.publish_prefix_lists',
        params: [listName]
      }).then((result) => {
        if (result.status === 200) {
          dispatch(successFeedback('Prefix List Publish Requested'))
          setPublishing(false)
        }
      }).catch((error) => {
        dispatch(errorFeedback('Publish Request Failed'))
        setPublishing(false)
      })
    } else {
      alert('You can\'t publish an empty list!')
      setPublishing(false)
    }
  }

  const delPrefix = (event, prefix) => {
    event.preventDefault()
    dispatch(setConfirmDialog({
      color: 'danger',
      text: `Are you sure you want to delete the prefix ${prefix}?`,
      proceed: () => {
        deletePrefix(listName, {
          ipaddress: prefix.split('/')[0],
          mask: prefix.split('/')[1]
        }).then((result) => {
          if (result.status === 200) {
            dispatch(successFeedback('Prefix Removed'))
            setLoadingInfo(true)
            setContents([])
            getPrefixListContents(listName).then((result) => {
              if (result.status === 200) {
                setContents(result.data)
                setLoadingInfo(false)
              }
            }).catch((error) => {
              alert(error)
            })
          }
        }).catch((error) => {
          dispatch(errorFeedback('Prefix Delete Failed'))
        })
      },
      cancel: () => { }
    }))
  }

  const addPrefixHandler = (event) => {
    event.preventDefault()
    setLoadingAdd(true)
    const ip = event.target.elements.prefix.value
    const mask = event.target.elements.prefixlen.value
    addPrefix(listName, { ipaddress: ip, mask: mask }).then((result) => {
      if (result.status === 200) {
        setContents([...contents, { exact: true, prefix: `${ip}/${mask}` }])
        setLoadingAdd(false)
        dispatch(successFeedback('Saved new prefix'))
        event.target.reset()
      }
    }).catch((error) => {
      dispatch(errorFeedback('Add Prefix Failed'))
      setLoadingAdd(false)
    })
  }

  const handleSave = (event) => {
    event.preventDefault()
    setLoadingSave(true)
    savePrefixListInfo(listName, {
      macro: event.target.elements.macro.value,
      protocol: event.target.elements.protocol.value,
      source: event.target.elements.source.value
    }).then((result) => {
      if (result.status === 200) {
        setInfo(result.data)
        setLoadingSave(false)
        dispatch(successFeedback('Saved prefix list info'))
      }
    }).catch((error) => {
      alert(error)
      dispatch(errorFeedback('Save prefix list info Failed'))
      setLoadingSave(false)
    })
  }

  const updateMacro = (e) => {
    setInfo({ ...info, macro: e.target.value })
  }

  return (
    <div>
      <Row>
        <Col>
          <Card className="card-accent-info">
            <CardHeader>
              <Row>
                <Col>{listName} Settings</Col>
                <Col style={{ textAlign: 'right' }}>
                  <Button
                    className="btn btn-danger btn-sm px-4"
                    onClick={deleteList}
                    disabled={!(
                      info.devices.length === 0 &&
                      (
                        (info.source === 'internal' && contents.length === 0) ||
                        info.source !== 'internal'
                      )
                    )}
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {loadingInfo ? (
                <div className="justify-content-center">
                  <SpinnerInfo/>
                </div>
              ) : (
                <Form onSubmit={handleSave}>
                  <FormGroup>
                    <Label>Last Updated</Label>
                    <p>{info.last_change}</p>
                  </FormGroup>
                  <FormGroup>
                    <Label>Name</Label>
                    <p>{info.name}</p>
                  </FormGroup>
                  <FormGroup>
                    <Label for="macro">Macro</Label>
                    <Input type="text" name="macro" id="macro" value={info.macro} onChange={updateMacro}/>
                  </FormGroup>
                  <FormGroup>
                    <Label for="source">Source</Label>
                    <Input type="select" name="source" id="source" defaultValue={info.source}>
                      <option>-- Select</option>
                      <option value="bgpq3">BGPQ3</option>
                      <option value="internal">Internal</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="protocol">Protocol</Label>
                    <Input type="select" name="protocol" id="protocol" defaultValue={info.protocol}>
                      <option>-- Select</option>
                      <option value="4">IPv4</option>
                      <option value="6">IPv6</option>
                    </Input>
                  </FormGroup>
                  <p style={{ textAlign: 'right' }}>
                    <LaddaButton
                      className="btn btn-primary btn-ladda px-4"
                      loading={loadingSave}
                      data-color="blue"
                      data-style={ZOOM_OUT}
                    >
                      Save Changes
                    </LaddaButton>
                  </p>
                </Form>
              )}
            </CardBody>
          </Card>
          <Card className="card-accent-warning">
            <CardHeader>
              <Row>
                <Col>Present on Routers <Badge>{info.devices.length}</Badge></Col>
                <Col style={{ textAlign: 'right' }}>
                  <LaddaButton
                    className="btn btn-warning btn-ladda btn-sm px-4"
                    loading={publishing}
                    data-color="blue"
                    data-style={ZOOM_OUT}
                    onClick={publishRequest}
                    disabled={!(contents.length > 0)}
                  >
                    Force Publish
                  </LaddaButton>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {loadingRouters ? (
                <div className="justify-content-center">
                  <SpinnerInfo/>
                </div>
              ) : (
                <ul>
                  {info.devices.map((rt) => (
                    <li key={rt.hostname}>
                      {rt.hostname}
                      {info.source === 'internal' && (
                        <i onClick={(e) => importList(e, rt.hostname)} className="icon-cloud-download"
                           style={{ marginLeft: '10px' }}></i>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="card-accent-secondary">
            <CardHeader>
              <Row>
                <Col>{listName} Contents</Col>
              </Row>
            </CardHeader>
            <CardBody>
              {loadingData ? (
                <div className="justify-content-center">
                  <SpinnerInfo/>
                </div>
              ) : (
                <div>
                  {info.source === 'internal' && (
                    <Form inline onSubmit={addPrefixHandler} id="add-prefix-form" style={{ marginBottom: '20px' }}>
                      <FormGroup>
                        <Input type="text" name="prefix" id="prefix" placeholder="Prefix"/>
                      </FormGroup>
                      <FormGroup>
                        <span className="input-group-addon">/</span>
                        <Input type="text" name="prefixlen" id="prefixlen" placeholder="Len" style={{ width: '52px' }}/>
                      </FormGroup>
                      <LaddaButton
                        className="btn btn-primary btn-ladda px-4"
                        loading={loadingAdd}
                        data-color="blue"
                        data-style={ZOOM_OUT}
                      >
                        Add Prefix
                      </LaddaButton>
                    </Form>
                  )}
                  <div>
                    {contents.map((tp) => (
                      <Row key={tp.prefix}>
                        {info.source === 'internal' && (
                          <Col sm={1}>
                            <a href="#" onClick={(e) => delPrefix(e, tp.prefix)} data-prefix={tp.prefix}>
                              <i className="icon-trash"></i>
                            </a>
                          </Col>
                        )}
                        <Col>{tp.prefix}</Col>
                      </Row>
                    ))}
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default PrefixList
