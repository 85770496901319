import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  UncontrolledCollapse,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Row,
  Col,
  Progress,
  Form,
  InputGroup,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Spinner
} from 'reactstrap';
import { submitCoreDeviceCheck, getJobStatus } from '../../utils/DeviceCheck/DeviceCheckService';
import { joinRooms } from '../../actions/socketio';
import CoreCheckResult from './CoreCheckResult';
import {netapi_getDevices} from "../../actions/netapi";
import {SelectMod} from "../../components/Selects/SelectMod";

class CoreCheck extends Component {
    static contextTypes = {
      router: PropTypes.shape({
        history: PropTypes.shape({
          push: PropTypes.func.isRequired,
          replace: PropTypes.func.isRequired
        }).isRequired,
        staticContext: PropTypes.object
      }).isRequired
    };

    constructor(props) {
      super(props);

      this.state = {
        data: {
          loaded: false,
          processing: false
        },
        errors: {},
        state: 0,
        device: '',
        progress: 0,
        taskname: '',
        res: {}
      };

      this.handleValidSubmit = this.handleValidSubmit.bind(this);
      this.resetState = this.resetState.bind(this);
    }

    componentDidMount() {
      if(this.props.devices.data.length === 0) {
        this.props.dispatch(netapi_getDevices());
      }
    }

  resetState() {
      this.setState({
        data: {
          loaded: false,
          processing: false
        },
        errors: {},
        state: 0,
        device: '',
        progress: 0,
        taskname: '',
        res: {},
        processing: false
      });
    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }

    handleValidSubmit(event) {
      event.preventDefault();
      submitCoreDeviceCheck(this.state.device).then((result) => {
        if (result.status === 200) {
          this.props.dispatch(joinRooms({ rooms: [result.data.uuid] }));
          this.setState({ state: 1, testid: result.data.uuid });
          this.interval = setInterval(() => {
            if (this.props.progressBars[result.data.uuid] != undefined) {
              if (this.props.progressBars[result.data.uuid].status === 'COMPLETE') {
                this.setState({ state: 2 });
                clearInterval(this.interval);
              }
            }
          }, 500);
        }
      }).catch((error) => {
        this.setState({
          state: 0,
          processing: false,
          errors: {
            errMessage: 'Fatal Error (' + error.status + ') ' + error.statusText
          }
        });
      });
      this.setState({ processing: true });
    }

    render() {
      if (this.state.state == 1) {
        if (this.props.progressBars[this.state.testid] != undefined) {
          let progress = (this.props.progressBars[this.state.testid].current / this.props.progressBars[this.state.testid].total) * 100;
          return (<Row className="animated fadeIn">
                    <Col md={{ size: 4, offset: 4 }}>
                        <Card className="card-accent-secondary">
                            <CardHeader>Performing tests on {this.state.device}...</CardHeader>
                            <CardBody>
                                <Progress value={progress}/>
                                <p>Running test {this.props.progressBars[this.state.testid].current} of {this.props.progressBars[this.state.testid].total}: {this.props.progressBars[this.state.testid].message}</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>);
        }

        return (<Row className="animated fadeIn">
                    <Col md={{ size: 4, offset: 4 }}>
                        <Card className="card-accent-secondary">
                            <CardBody>
                                <p>Initializing funky progress bar...</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>);
      }

      if (this.state.state == 2) {
        return (<div className="animated fadeIn"><Row>
                <Col><p style={{ textAlign: 'right' }}><Button onClick={this.resetState} color="warning"><i className="icon-star"/> New Test</Button> <Button id="UncontrolledPopover" type="button" className="secondary">
                    <i className="icon-share"/> Share
                </Button>
                    <UncontrolledPopover placement="bottom" target="UncontrolledPopover">
                        <PopoverHeader>Sharing Link</PopoverHeader>
                        <PopoverBody>https://admin.hso-group.net/toolbox/coreconfig/{this.state.testid}</PopoverBody>
                    </UncontrolledPopover></p></Col>
            </Row>
                <Row>
                <Col>
                <CoreCheckResult uuid={this.state.testid}/>
            </Col>
                </Row></div>);
      }
      if(this.props.devices.loading !== 0 || this.props.devices.data.length === 0) {
        return <Spinner/>
      }
      return (
            <div className="animated fadeIn">
                <Row>
                    <Col md={{ size: 4, offset: 4 }}>
                        <Card className="card-accent-secondary">
                            <CardHeader>Core Config Checker</CardHeader>
                            <CardBody>
                                {this.state.errors.infoMessage && <Card inverse="true" color="info" className="animated zoomIn"><CardBody><CardText>{this.state.errors.infoMessage}</CardText></CardBody></Card>}
                                {this.state.errors.errMessage && <Card inverse="true" color="danger" className="animated zoomIn"><CardBody><CardText>{this.state.errors.errMessage}</CardText></CardBody></Card>}
                                <Form onSubmit={this.handleValidSubmit}>
                                    <InputGroup>
                                        <SelectMod
                                          options={this.props.devices.data}
                                          getOptionLabel={o => o.hostname}
                                          getOptionValue={o => o.hostname}
                                          onChange={o => this.setState({device: o.hostname})}
                                        />
                                    </InputGroup>
                                    <div className="clearfix" style={{ marginTop: 10 + 'px', marginBottom: 20 + 'px' }}>
                                        <Button type="submit" color="primary" className="float-end" style={{ minWidth: 113 + 'px', minHeight: 35 + 'px' }} disabled={this.state.processing}>{this.state.processing ? <div style={{ marginTop: 2 + 'px' }}></div> : 'Check'}</Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
      );
    }
}

function mapStateToProps(state) {
  return {
    progressBars: state.socketioProgress.progressBars,
    devices: state.netapi.devices
  };
}

export default connect(mapStateToProps)(CoreCheck);
