import { Table } from 'reactstrap';
import numeral from 'numeral';
import ReactTooltip from 'react-tooltip';
import React, { useState } from 'react';
import { checkPermission } from '../../../utils/Auth/AuthService';
import classnames from 'classnames';
import { GenericModal } from '../../Modals/GenericModal';
import isEmpty from 'lodash.isempty';
import JSONPretty from 'react-json-pretty';
import JSONPretty1337 from 'react-json-pretty/dist/1337';

export const CarrierPrices = ({
  data,
  user,
  setPreferred
}) => {

  const [carrierPrice, setCarrierPrice] = useState(null);

  return (
    <>
      <Table className={'bg-light'} responsive={true}>
        <thead>
        <tr>
          <th>Carrier</th>
          <th>NNI</th>
          <th>Ref</th>
          <th>TCV</th>
          <th>Install</th>
          <th>Rental</th>
          {checkPermission('PreSales', user.permissions) &&
          <>
            <th>Install (Carrier)</th>
            <th>Rental (Carrier)</th>
          </>

          }
          <th>Status</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {data.map((price) => {
          return (
            <tr
              key={price.id}
              onClick={() => price.status === 'COMPLETE' ? setPreferred(price.id) : () => {}}
              className={classnames('hoverPointer', {
                'bg-warning fw-bold text-dark': price.preferred
              })}
            >
              <td>{price.carrier}</td>
              <td>{price.nni}</td>
              <td>{price.ref}</td>
              <td>£{numeral(price.tcv)
                .format('0,0.00')}</td>
              <td>£{numeral(price.install)
                .format('0,0.00')}</td>
              <td>£{numeral(price.rental)
                .format('0,0.00')}</td>
              {checkPermission('PreSales', user.permissions) &&
              <>
                <td>£{numeral(price.installCost)
                  .format('0,0.00')}</td>
                <td>£{numeral(price.rentalCost)
                  .format('0,0.00')}</td>
              </>
              }
              <td className={price.status === 'COMPLETE' ? 'text-success' : 'text-danger'}>
                <>
                  <div data-for={`${price.id}`} data-tip={`${price.id}`}>{price.status}</div>
                  {price.statusMessage && <ReactTooltip
                    id={`${price.id}`}
                    type="dark"
                    place="left"
                    effect="float"
                  >{price.statusMessage}
                  </ReactTooltip>}
                </>
              </td>
              <td>{!isEmpty(price.meta) && price.status === 'COMPLETE' ?
                <i onClick={(event) => {
                  event.stopPropagation()
                  setCarrierPrice(price)
                }} className='fa fa-info-circle'></i>
                :
                ''
              }</td>
            </tr>
          );
        })}
        </tbody>
      </Table>
      <GenericModal
        show={!isEmpty(carrierPrice)}
        className={'modal-lg'}
        toggle={() => setCarrierPrice(null)}
        onClosed={() => setCarrierPrice(null)}
        title={carrierPrice?.carrier}
      >
        <div>
          <JSONPretty id="json-pretty" data={carrierPrice?.meta} theme={JSONPretty1337}></JSONPretty>
        </div>
      </GenericModal>
    </>

  );
};
