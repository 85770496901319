

export const BorderedText = ({
  text,
  borderColour,
  textColour
}) => {
  return <span
    style={{fontSize: '80%', fontWeight: 'bold', borderRadius: '2px',padding: '2px 7px 3px 7px'}}
    className={`text-${textColour || 'primary'} border border-${borderColour || 'primary'} me-2 mt-1 d-inline-block`}
  >{text}
  </span>
}
