import { Col, Row, List } from 'reactstrap';
import LoadingOverlay from '../LoadingOverlay';
import FilteredTableV2 from '../FilteredTableV2';
import moment from 'moment/moment';
import ExtraButtons from '../../utils/Helpers/ExtraButtons';
import { ButtonIcon } from '../ButtonIcon';
import React, { useCallback } from 'react'
import isArray from 'lodash.isarray';
import isObject from 'lodash.isobject';

const AuditHistory = ({
  auditHistory,
  loading,
  fetchData
}) => {

  const resolveUser = useCallback((value) => {
    if (!value?.data?.name) {
      return ''
    }
    const type = value?.type === 'portalUser' ? ' (Portal User)' : ''
    return `${value?.data?.name}${type}`
  })

  const columns = [
    {
      header: 'Date',
      accessorKey: 'createdAt',
      cell: (props) => moment(props.getValue()).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      header: 'User',
      accessorKey: 'user',
      cell: (props) => resolveUser(props.getValue())
    },
    {
      header: 'IP Address',
      accessorKey: 'ipAddress'
    },
    {
      header: 'Event',
      accessorKey: 'event',
    },
    {
      header: 'Old Values',
      accessorKey: 'oldValues',
      cell: (props) => <ValuesCell values={props.getValue()} type={'old'}/>,
      minSize: 400
    },
    {
      header: 'New Values',
      accessorKey: 'newValues',
      cell: (props) => <ValuesCell values={props.getValue()} type={'new'}/>,
      minSize: 400
    },
  ]
  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <Row>
          <Col>
            <FilteredTableV2
              minRows={5}
              data={auditHistory}
              columns={columns}
              defaultPageSize={50}
              noDataText={'No Audit History'}
              defaultSorted={[{id: 'createdAt', desc: true}]}
              extraButtons={ExtraButtons({
                others: [
                  <ButtonIcon icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={fetchData}/>
                ]
              })}
            />
          </Col>
        </Row>
      </LoadingOverlay>

    </div>
  )
}

const ValuesCell = ({
  values,
  type
}) => {

  const resolveData = (values, key) => {
    if(isArray(values)){
      return <List>
          {values.map((item, index) => {
             return <li>
               <List type="unstyled">
                 {isObject(item) ? Object.keys(item).map((attr, attrIndex) => {
                   return <li key={`${key}-${index}-${attr}-${attrIndex}`}>
                     <span className={'fw-bold me-2'}>{attr}:</span>{resolveData(item[attr], attr)}
                   </li>
                 }) : item}
               </List>
            </li>
          })}

      </List>
    }
    else if(isObject(values)){
      return <List>
           <li>
            <List type="unstyled">
              {Object.keys(values).map((attr, attrIndex) => {
                return <li key={`${key}-${attr}-${attrIndex}`}>
                  <span className={'fw-bold me-2'}>{attr}:</span>{resolveData(values[attr], attr)}
                </li>
              })}
            </List>
          </li>
      </List>

    }else{
      return values
    }

  }


  return <List type={"unstyled"}>
    {Object.keys(values).map(key => {
      if(isArray(values[key]) || isObject(values[key])){
        return <li><span className={'fw-bold me-2'}>{key}:</span>
          {
            resolveData(values[key], key)
          }
        </li>
      }
      return <li key={`${key}-${type}`}>
        <span className={'fw-bold me-2'}>{key}:</span>{values[key]}
      </li>
    })}
  </List>
}
export default AuditHistory;
