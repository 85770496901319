import React, { useEffect, useState } from 'react';
import LoadingOverlay from '../../components/LoadingOverlay';
import {
  Card, CardBody, CardTitle,
  Col,
  Row
} from 'reactstrap';
import generateFormFields from '../../helpers/FormFieldGenerator';
import classnames from 'classnames';
import Form from '../Task/form';
import {
  addNewTaskNote,
  createTask, getTaskAuditHistory,
  getTaskData, removeTaskNote,
  resetTask,
  updateTask,
  updateTaskField, updateTaskNote
} from '../../actions/task';
import { connect, useDispatch } from 'react-redux'
import { diff } from 'deep-object-diff';
import isEmpty from 'lodash.isempty';
import {updateOpportunityTask } from '../../actions/opportunity';
import { ButtonIcon } from '../../components/ButtonIcon';
import {
  updateOrderTask
} from '../../actions/order';
import ApiErrorResolver from '../../helpers/ApiErrorResolver';
import CollapsibleCard from '../../components/CollapsibleCard';
import UnsavedChangesAlert from '../../components/Alerts/UnsavedChangesAlert';
import { setConfirmDialog } from '../../actions/dialogs';
import HeadlessModal from '../../components/Modals/HeadlessModal';
import Order from '../Order';
import Opportunity from '../Opportunity';
import FormValidationErrors from '../../components/Errors/FormValidationErrors';
import EntityMainFormCard from '../../components/Cards/EntityMainFormCard';
import AuditHistory from '../../components/AuditHistory';
import Notes from '../../components/Notes';
import CreditRequest from '../CreditRequest';


const Task = (props) => {
  // redux
  const dispatch = useDispatch();
  const {
    id,
    optionSets,
    task,
    relatedTo,
    toggleModal,
    onCreated,
    onUpdated,
    defaultType,
  } = props;
  const {
    form,
    original,
    audits
  } = task;
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const isNew = (!id || id === 'new') && !original.id;
  const [showEntityModal, setShowEntityModal] = useState(false);
  const [auditsLoading, setAuditsLoading] = useState(false);
  const [entity, setEntity] = useState({});
  const toggleEntityModal = () => setShowEntityModal(prevState => !prevState)
  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState);

  useEffect(() => {
    if (!isNew) {
      getTask(id);
    } else {
      dispatch(updateTaskField({ relatedTo }));
      dispatch(updateTaskField({ type: defaultType }));
      dispatch(updateTaskField({ statusReason: 2 }));
      dispatch(updateTaskField({ priority: 1 }));
    }
    return () => {
      dispatch(resetTask());
    };
  }, [id]);

  const getTask = (id) => {
    setLoading(true);
    dispatch(getTaskData(id))
      .then(() => {
        setLoading(false);
      });
  };

  const getAudits = () => {
    toggleAuditsLoading()
    dispatch(getTaskAuditHistory(original.id)).then(() => toggleAuditsLoading())
  }
  const getEntityComponent = () => {
    if (form.relatedTo?.type === 'order') {
      return <Order id={entity.id} closeModal={toggleEntityModal}/>
    } else if (form.relatedTo?.type === 'opportunity') {
      return <Opportunity id={entity.id} toggleModal={toggleEntityModal}/>
    }else if (form.relatedTo?.type === 'creditRequest') {
        return <CreditRequest id={entity.id} closeModal={toggleEntityModal}/>
    }

    return ''
  }
  const getSelectOptions = (field) => {
    const options = optionSets[field].options.map((option, index) => {
      return {
        ...option
      };
    });

    if(field === 'type'){
      return options.filter(option => form.relatedTo?.type && option.entity.includes(form.relatedTo?.type))
    }
    return options

  };
  const getSelectedOption = (field) => {
    let selected = [];
    if (optionSets[field]) {
      selected = optionSets[field].options.filter(option => option.value === form[field]);
    }
    if (selected.length) {
      return selected[0];
    }
    return null;
  };

  const handleInput = (event) => {
    if (Form[event.target.id] && !Form[event.target.id].plaintext) {
      props.dispatch(updateTaskField({ [event.target.id]: event.target.value }));
    }
  };
  const handleSelectInput = (field, selected) => {
    dispatch(updateTaskField({ [field]: selected.value }));
  };

  const hasChanges = () => {
    const theDiff = diff(original, form);
    return !isNew && !loading && !isEmpty(theDiff)
  }

  const handleEntityClick = () => {
    setEntity({
      ...form.relatedTo.data,
      size: form.relatedTo.type === 'creditRequest' ? 'xlg' : 'xxlg'
    })
    toggleEntityModal()
  }

  const updateRelatedEntityTask = (task) => {
    if (form.relatedTo.type === 'opportunity') {
      dispatch(updateOpportunityTask(task));
    }else if(form.relatedTo.type === 'order'){
      dispatch(updateOrderTask(task));
    }
  }
  const save = () => {

    if (validated()) {
      if (isNew) {
        setLoading(true);
        dispatch(createTask({
          relatedToType: task.form.relatedTo.type,
          relatedTo: task.form.relatedTo.data.id,
          subject: task.form.subject,
          description: task.form.description,
          type: task.form.type,
          priority: task.form.priority,
        }))
          .then((result) => {
            if(result.errors){
              setErrors(ApiErrorResolver(result.errors))
            }
            else if (result && onCreated) {
              onCreated(result)
            }
            setLoading(false);
          });
      } else {
        const toSave = diff(task.original, task.form);
        if (!isEmpty(toSave)) {
          setLoading(true);
          dispatch(updateTask(task.form.id, toSave))
            .then((result) => {
              if(result.errors){
                setErrors(Object.values(result.errors))
              }
              else if (result) {
                updateRelatedEntityTask(result)
                if(onUpdated){
                  onUpdated(result)
                }

              }
              setLoading(false);
            });
        }
      }

    }
  };
  const validated = () => {
    let errors = [];
    setErrors(errors);
    for (const [key, value] of Object.entries(Form)) {
      if (value.mandatory && (task.form[key] === undefined || task.form[key] === null || task.form[key].length === 0)) {
        errors.push(`You must provide a valid ${value.label}`);
      }
      if(value.max && task.form[key]?.length > value.max){
        errors.push(`${value.label} may not be grater then ${value.max} characters`);
      }
    }
    if (errors.length === 0) {
      return true;
    }
    setErrors(errors);
  };

  const onClosing = () => {
    if(hasChanges() || isNew){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => toggleModal()
      }))
    }else{
      toggleModal()
    }

  }
  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <Card className='bg-light border-0 mb-0'>
          <CardBody>
            <Row className={'mb-2'}>
              <Col className='d-flex'>
                <CardTitle>
                  Task
                </CardTitle>
                <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ms-auto')}>
                  {
                    !isNew && hasChanges() && <UnsavedChangesAlert save={save}/>
                  }
                  <ButtonIcon disabled={loading || (!isNew && !hasChanges())} icon={'fa fa-save'} tooltip={'save'}  onClick={save}/>
                  <ButtonIcon disabled={loading || isNew} icon={'fa fa-refresh'} tooltip={'reload'}  onClick={() => getTask(id)}/>
                  {toggleModal &&
                    <ButtonIcon onClick={onClosing} icon='fa fa-lg fa-close' tooltip={'Close Popup'}/>
                  }
                </div>
              </Col>
            </Row>
            <FormValidationErrors errors={errors}/>
            <Row>
              <Col>
                <EntityMainFormCard>
                  <Row form>
                    {generateFormFields({
                      handleClick:() =>  handleEntityClick(),
                      fields: Form,
                      handleInput,
                      getSelectOptions,
                      getSelectedOption,
                      handleSelectInput,
                      data: form
                    })}
                  </Row>
                </EntityMainFormCard>

              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                  open
                  title={'Notes'}
                >
                  <Row>
                    <Col>
                      <Notes
                        withNew
                        notes={form.notes}
                        relatedTo={{type: 'task', data: form}}
                        onCreated={note => dispatch(addNewTaskNote(note))}
                        onUpdated={note => dispatch(updateTaskNote(note))}
                        onDeleted={note => dispatch(removeTaskNote(note))}
                      />
                    </Col>
                  </Row>
                </CollapsibleCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <CollapsibleCard
                  title={'Audit History'}
                  onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
                >
                  <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
                </CollapsibleCard>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </LoadingOverlay>
      <HeadlessModal
        open={showEntityModal}
        onClosed={() => setEntity({})}
        size={entity.size ?? 'xxlg'}
        toggle={toggleEntityModal}
      >
        <Row>
          <Col>
            {getEntityComponent()}
          </Col>
        </Row>

      </HeadlessModal>
    </div>
  )

};

function mapStateToProps({
  task,
  authenticationState,
  helpers
}) {
  return {
    task,
    optionSets: helpers.optionSets.task,
    user: authenticationState.account
  };
}

export default connect(mapStateToProps)(Task);
