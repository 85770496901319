import React, { useState } from 'react';
import FilteredTable from '../../../components/FilteredTable';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import generateFormFields from '../../../helpers/FormFieldGenerator';

const ServiceUtilisation = ({ data, modify, remove }) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [currentFormData, setCurrentFormData] = useState({});

    const edit = (id) => {
      setModalOpen(true);
      setCurrentFormData(data.find(service => service.id === id));
    };

    const columns = [
      {
        accessor: 'code',
        Header: 'Product Code',
        width: 200
      },
      { accessor: 'name', Header: 'Name' },
      {
        accessor: 'id',
        width: 40,
        sortable: false,
        filterable: false,
        Cell: props => <Button size="sm" color="info" onClick={() => edit(props.value)}><i
          className="fa fa-edit"/></Button>
      },
      {
        accessor: 'id',
        width: 40,
        sortable: false,
        filterable: false,
        Cell: props => <Button size="sm" color="danger" onClick={() => remove(props.value)}><i
          className="fa fa-remove"/></Button>
      }
    ];

    const toggleForm = () => setModalOpen(prevState => !prevState);

    const handleInput = (event) => {
      setCurrentFormData({...currentFormData, [event.target.id]: event.target.value });
    };

    const form = {
      code: { label: 'Product Code', type: 'text', readOnly: true, width: 12 },
      name: { label: 'Name', type: 'text', width: 12 }
    };

    const save = () => {
      modify(currentFormData);
      setCurrentFormData({});
      setModalOpen(false);
    };

    return (
      <>
        <FilteredTable
          data={data}
          columns={columns}
          hideFilter={true}
        />
        <Modal isOpen={modalOpen} toggle={toggleForm}
               className="modal-info modal-dialog-centered">
          {currentFormData.id ? (
            <>
              <ModalHeader toggle={toggleForm}>{currentFormData.code}</ModalHeader>
              <ModalBody>
                <Row className="mb-3">
                  <Col className={'d-flex justify-content-end'}>
                    <Button className="me-2" size={'sm'} color={'primary'} onClick={save}>
                      <i className="fa fa-save"/>
                    </Button>
                  </Col>
                </Row>
                <Row form>
                  {generateFormFields({
                    data: currentFormData,
                    fields: form,
                    handleInput,
                  })}
                </Row>
              </ModalBody>
            </>
          ) : ''}
        </Modal>
      </>
    );
  }
;

export default ServiceUtilisation;
