import React from 'react';
import { Row, Col, Card, CardBody, CardHeader, Badge, Progress } from 'reactstrap';
import FilteredTable from '../../../../components/FilteredTable';
import { ButtonIcon } from '../../../../components/ButtonIcon';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const Subnets = ({ loading, subnets, block, parentSubnet, canAddAndDelete }) => {

  // router
  const navigate = useNavigate();

  const columns = [
    {
      filterable: true,
      sortable: true,
      Header: 'ID',
      accessor: 'id',
      width: 100
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Name',
      accessor: 'name'
    },
    {
      filterable: true,
      sortable: false,
      Header: 'Network Address/Mask',
      id: 'networkAddress/Mask',
      accessor: row => `${row.networkAddress}/${row.mask}`
    },
    {
      filterable: false,
      sortable: true,
      Header: 'Allocated',
      accessor: 'isAllocated',
      Cell: ({ value, original }) => {
        let color = 'danger'
        let text = 'No';
        if (original.childSubnetCount > 0) {
          color = 'primary';
          text = 'Children';
        } else if (value) {
          color = 'success';
          text = 'Yes';
        }
        return <Badge pill color={color}>{text}</Badge>
      },
      width: 100
    },
    {
      filterable: false,
      sortable: false,
      Header: 'Addresses',
      id: 'addresses',
      accessor: row => {
        if (row.childSubnetCount > 0) {
          return '';
        }
        const percentage = (row.ipAddressCount / row.size) * 100;
        let color = 'info';
        if (percentage > 50) {
          color = 'warning';
        }
        if (percentage > 90) {
          color = 'danger';
        }
        return <div className="position-relative">
          <div className="position-absolute text-center small w-100">{`${row.ipAddressCount}/${row.size}`}</div>
          <Progress value={row.ipAddressCount} max={row.size} color={color} style={{ height: '4px', marginTop: '8px' }}/>
        </div>;
      }
    }
  ];

  return (
    <>
      <Row>
        <Col className={'d-flex'}>
          <Card className="w-100">
            <CardHeader>
              <Row>
                <Col>Subnets</Col>
                <Col className="text-end">
                  {canAddAndDelete ? (
                    <ButtonIcon
                      icon="fa fa-plus"
                      tooltip="Add subnet"
                      onClick={() => navigate({
                        pathname: `/sdb/ipam/subnets/new`,
                        block: block || parentSubnet?.block,
                        parentSubnet: parentSubnet
                      })}
                    />
                  ) : ''}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FilteredTable
                columns={columns}
                data={subnets}
                minRows={1}
                filterable={false}
                loading={loading}
                hideFilter={true}
                showPagination={subnets.length > 10}
                onRowClick={(subnet) => navigate(`/sdb/ipam/subnets/${subnet.id}`)}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Subnets;
