import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';
import {
  Alert, Button,
  Card, CardBody,
  CardTitle,
  Col,
  Row
} from 'reactstrap';
import generateFormFields from '../../helpers/FormFieldGenerator';
import SiteForm from './form';
import {
  createSite,
  deactivateSite,
  getSite, getSiteAuditHistory,
  resetSite,
  restoreSite,
  setSiteData,
  updateSite,
  updateSiteFormField
} from '../../actions/site';
import { diff } from 'deep-object-diff';
import { warningFeedback } from '../../actions/feedback';
import {setAccountSite } from '../../actions/accounts';
import resolveArgs from '../../helpers/ArgumentResolver';
import classnames from 'classnames';
import LoadingOverlay from '../../components/LoadingOverlay';
import SiteContacts from '../../components/Tables/SiteContacts';
import UnsavedChangesAlert from '../../components/Alerts/UnsavedChangesAlert';
import { ButtonIcon } from '../../components/ButtonIcon';
import { setConfirmDialog } from '../../actions/dialogs';
import FormValidationErrors from '../../components/Errors/FormValidationErrors';
import CollapsibleCard from '../../components/CollapsibleCard';
import ApiErrorResolver from '../../helpers/ApiErrorResolver';
import HeadlessModal from '../../components/Modals/HeadlessModal';
import Contact from '../Contact';
import { addAccountSite, updateAccountSite } from '../../actions/account';
import AuditHistory from "../../components/AuditHistory";

const Site = (
  {
    id,
    site,
    optionSets,
    account,
    contacts,
    closeModal,
    dispatch,
    contactHelpers,
    onUpdated
  }
) => {
  const {form, original, audits} = site
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showEntityModal, setShowEntityModal] = useState(false);
  const [contact, setContact] = useState(null);
  const [auditsLoading, setAuditsLoading] = useState(false);

  const toggleAuditsLoading = () => setAuditsLoading(prevState => !prevState);
  const toggleLoading = () => setLoading(prevState => !prevState);
  const toggleEntityModal = () => {
    setShowEntityModal(prevState => !prevState)
  }

  const isNew = (!id || id === 'new') && !original.accountid;

  const getData = () => {
    toggleLoading()
    dispatch(getSite(original.accountid || id))
      .then(() => {
       toggleLoading()
      });
  };

  const getAudits = () => {
    toggleAuditsLoading();
    dispatch(getSiteAuditHistory(original.accountid)).then(() => toggleAuditsLoading())
  }

  useEffect(() => {
    if (!isNew) {
      getData();
    }
    return () => {
      dispatch(resetSite());
    };
  }, []);

  const validated = () => {
    let errorArr = [];
    setErrors(errorArr);
    Object.entries(SiteForm())
      .forEach(([key, value]) => {
        if (value.mandatory && (form[key] === undefined || form[key] === null || form[key].length === 0)) {
          errorArr.push(`You must provide a valid ${value.label}`);
        }
      });
    if (errorArr.length === 0) {
      return true;
    }

    setErrors(errorArr);
    return false;
  };

  const saveSite =  () => {
    if (validated()) {
      if (!isNew) {
        const toSave = diff(original, form);
        if (!isEmpty(toSave)) {
          toggleLoading()
          dispatch(updateSite(original.accountid, resolveArgs(toSave))).then((result) => {
            if(result.errors){
              setErrors(ApiErrorResolver(result.errors))
            }
            if(result){
              dispatch(setSiteData(result))
              dispatch(updateAccountSite(result))
            }
            toggleLoading()
          });
        } else {
          dispatch(warningFeedback('nothing to update'));
        }
      } else {
        toggleLoading()
        dispatch(createSite(account.accountid, resolveArgs(form))).then((result) => {
          if(result.errors){
            setErrors(ApiErrorResolver(result.errors))
          }else if(result){
            dispatch(setSiteData(result));
            dispatch(addAccountSite(result))
          }
          toggleLoading()
        });
      }
    }
  };

  const deactivate = () => {
    toggleLoading()
    dispatch(deactivateSite(original.accountid)).then((result) =>  {
      if(result){
        dispatch(getSite(id)).then((result) => {
          if(result){
            dispatch(setSiteData(result))
            dispatch(setAccountSite(result))
          }
          toggleLoading()
        })
      }else {
        toggleLoading()
      }

    })
  }

  const restore = () => {
    toggleLoading()
    dispatch(restoreSite(original.accountid)).then((result) =>  {
      if(result){
        dispatch(getSite(id)).then((result) => {
          if(result){
            dispatch(setSiteData(result))
            dispatch(setAccountSite(result))
          }
          toggleLoading()
        })
      }else {
        toggleLoading()
      }

    })
  }

  const handleInput = (event) => {
    const field = event.target.id;
    const value = event.target.value;
    dispatch(updateSiteFormField(field, value));
  };

  const getSelectOptions = (field) => {
    if (field === 'primaryContact') {
      return Object.values(contacts)
          .map((option) => {
            return {
              value: option.id,
              label: option.name,
              isDisabled: !option.isActive
            };
          });
    }
    if (optionSets[field]) {
      return optionSets[field].options.map((option) => {
        return {
          value: option.value,
          label: option.label
        };
      });
    }
    return [];
  };

  const getSelectedOption = (field) => {
    let selected = [];
    if (field === 'primaryContact'){
      selected = contacts.filter((option) => {
        return form[field] && option.id === form[field].id;
      });
    }
    else if (optionSets[field]) {
      selected = optionSets[field].options.filter(option => option.value === form[field]);
    }
    if (selected.length) {
      return selected[0];
    }

    return null;
  };

  const handleSelectInput = (key, selected) => {
    if (key === 'primaryContact') {
      const contact = contacts.find(contact => contact.id === selected.value);
      dispatch(updateSiteFormField(key, contact));
    } else {
      dispatch(updateSiteFormField(key, selected ? selected.value : null));
    }

  };
  const onClose = () => {
    if(hasChanges()){
      dispatch(setConfirmDialog({
        color: 'danger',
        text: "You have unsaved changes! Closing this window will result losing the changes you've made.",
        proceed: () => closeModal()
      }))
    }else{
      closeModal()
    }
  }

  const onDeactivate = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "This will deactivate the site.",
      proceed: () => deactivate()
    }))
  }

  const onRestore = () => {
    dispatch(setConfirmDialog({
      color: 'danger',
      text: "This will reactivate the site.",
      proceed: () => restore()
    }))
  }
  const hasChanges = () => {
    const changes = diff(original, form);
    return !isEmpty(changes)
  }

  const isActive = () => {
    return original.isActive || isNew
  }

  const handleContactClick = () => {
    setContact({ id: form.primaryContact?.id || form.primaryContact})
    toggleEntityModal()
  }

  return (
    <div className="animated fadeIn">
      <LoadingOverlay loading={loading}>
        <Card className='bg-light border-0 mb-0'>
          <CardBody>
            <Row className='mb-2'>
              <Col className='d-flex'>
                <CardTitle>
                  {original.name}
                </CardTitle>
                <div className={classnames('d-flex','align-items-center', 'animated', 'fadeIn', 'ms-auto')}>
                  {
                    hasChanges() && isActive() && <UnsavedChangesAlert save={saveSite}/>
                  }
                  {isActive() && !isNew &&
                    <Button
                      onClick={onDeactivate}
                      color={'secondary'}
                      size={'sm'}
                      className={'ms-2'}
                    >
                      Deactivate
                    </Button>
                  }
                  {!isActive() &&
                    <Button
                      onClick={onRestore}
                      color={'secondary'}
                      size={'sm'}
                      className={'ms-2'}
                    >
                      Restore
                    </Button>
                  }
                  <ButtonIcon disabled={loading || !hasChanges()} icon={'fa fa-save'} tooltip={'Save'}  onClick={saveSite}/>
                  <ButtonIcon disabled={isNew} icon={'fa fa-refresh'} tooltip={'Reload'}  onClick={getData}/>
                  {closeModal &&
                    <ButtonIcon onClick={onClose} icon='fa fa-close' tooltip={'Close Popup'}/>
                  }
                </div>
              </Col>
            </Row>
            {!isNew && !isActive() &&
              <Row>
                <Col>
                  <Alert color={'warning'}>
                    <p className="mb-0" >This site has been deactivated.</p>
                  </Alert>
                </Col>
              </Row>
            }
            {original.company && !original.company.isActive && (
              <Row>
                <Col>
                  <Alert color={'warning'}>
                    <p className="mb-0" >Deactivated Account!</p>
                  </Alert>
                </Col>
              </Row>
            )}
            {!isEmpty(original.primaryContact) && !original.primaryContact.isActive && (
              <Row>
                <Col>
                  <Alert color={'warning'}>
                    <p className="mb-0" >Deactivated Primary Contact!</p>
                  </Alert>
                </Col>
              </Row>
            )}
            <FormValidationErrors errors={errors}/>
            <Row className="d-flex justify-content-between">
              <Col className={"d-flex md-4"}>
                <Card className="flex-1">
                  <CardBody>
                    <Row form>
                      {generateFormFields({
                        fields: SiteForm(handleContactClick, isNew),
                        handleInput,
                        getSelectOptions,
                        getSelectedOption,
                        handleSelectInput,
                        data:form
                      })}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {!isNew &&
              <Row>
                <Col>
                  <CollapsibleCard
                    title={'Site Contacts'}
                  >
                    <SiteContacts
                      contacts={form.contacts || []}
                      accountSites={account?.sites ?? []}
                      accountContacts={contacts}
                      contactTypes={contactHelpers.type.options}
                    />
                  </CollapsibleCard>
                </Col>
              </Row>

            }

            <Row>
              <Col>
                <CollapsibleCard
                    title={'Audit History'}
                    onEntering={() => isEmpty(audits) ? getAudits() : () => {}}
                >
                  <AuditHistory auditHistory={audits} loading={auditsLoading} fetchData={getAudits}/>
                </CollapsibleCard>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </LoadingOverlay>
      <HeadlessModal
        open={showEntityModal}
        onClosed={() => setContact(null)}
        size={'xlg'}
        toggle={toggleEntityModal}
      >
        <Contact id={contact?.id} toggleModal={toggleEntityModal}/>

      </HeadlessModal>
    </div>
  );
};

function mapStateToProps({ helpers, site, account }) {
  return {
    optionSets: helpers.optionSets.account,
    site: site,
    contacts: account.contacts,
    contactHelpers: helpers.optionSets.contact
  };
}

export default connect(mapStateToProps)(Site);
