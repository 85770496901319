import React, { Component } from 'react';
import { Card, CardBody, Row, Col, Badge, Button, Modal, ModalBody, Progress, Alert } from 'reactstrap';
import { SpinnerInfo } from '../../components/Spinner/Spinner';
import { TimeSeries } from 'pondjs';
import _ from 'underscore';
import { Charts, ChartContainer, ChartRow, YAxis, AreaChart, styler, Resizable, Legend } from 'react-timeseries-charts';
import { format } from 'd3-format';
import { WeathermapService } from '../../utils/Weathermaps/WeathermapService';
import { connect } from 'react-redux';
import { joinRooms } from '../../actions/socketio';

export class WeatherMapEdge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: {},
      entermaintactive: false,
      testid: false
    };
    this.handleTrackerChanged = this.handleTrackerChanged.bind(this);
    this.getEdge = this.getEdge.bind(this);
    this.enterMaintenance = this.enterMaintenance.bind(this);
    this.exitMaintenance = this.exitMaintenance.bind(this);
  }

  getEdge() {
    this.setState({
      loading: true,
      data: {}
    });
    WeathermapService.getWeathermapEdge(this.props.mapId, this.props.edgeId).then((result) => {
      if (result.status === 200) {
        let inTraffic = new TimeSeries({
          name: result.data.history[0].label,
          columns: ['time', 'in'],
          points: _.map(result.data.history[0].data, p => [p[0] * 1000, parseInt(p[1])])
        });

        let outTraffic = new TimeSeries({
          name: result.data.history[1].label,
          columns: ['time', 'out'],
          points: _.map(result.data.history[1].data, p => [p[0] * 1000, parseInt(p[1])])
        });

        let trafficSeries = TimeSeries.timeSeriesListMerge({
          name: 'traffic',
          seriesList: [inTraffic, outTraffic]
        });

        let max = _.max([inTraffic.max('in'), outTraffic.max('out')]);

        this.setState({
          data: result.data,
          max: max,
          trafficSeries: trafficSeries,
          timerange: trafficSeries.range(),
          loading: false
        });
      }
    }).catch((error) => {
      alert(error);
    });
  }

  componentDidMount() {
    this.getEdge();
  }

  componentDidUpdate(prevProps) {
    if (this.props.edgeId !== prevProps.edgeId) {
      this.getEdge();
    }
  }

  handleTrackerChanged(t) {
    this.setState({ tracker: t });
  }

  bitsToSize(bits, dp = 4) {
    if (bits === undefined) { return '-'; }
    const sizes = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps'];
    if (bits === 0) return 'n/a';
    const i = parseInt(Math.floor(Math.log(Math.abs(bits)) / Math.log(1000)), 10);
    if (i === 0) return `${bits} ${sizes[i]})`;
    return `${(bits / (1000 ** i)).toFixed(dp)} ${sizes[i]}`;
  }

  enterMaintenance() {
    let nodes = this.props.edgeId.split('--');
    this.setState({ entermaintactive: true });
    WeathermapService.api_enterMaintenance(nodes[0], nodes[1], this.props.auth.account.username).then((result) => {
      if (result.status === 200) {
        this.props.dispatch(joinRooms({ rooms: [result.data.uuid] }));
        this.setState({ testid: result.data.uuid });
        this.interval = setInterval(() => {
          if (this.props.progressBars[result.data.uuid] !== undefined) {
            if (this.props.progressBars[result.data.uuid].status === 'COMPLETE' || this.props.progressBars[result.data.uuid].status === 'FAILURE') {
              this.setState({ entermaintactive: false });
              clearInterval(this.interval);
            }
          }
        }, 500);
      }
    }).catch((error) => {
      this.setState({
        entermaintactive: false,
        errors: {
          errMessage: 'Fatal Error (' + error.status + ') ' + error.statusText
        }
      });
    });
  }

  exitMaintenance() {
    let nodes = this.props.edgeId.split('--');
    this.setState({ entermaintactive: true });
    WeathermapService.api_exitMaintenance(nodes[0], nodes[1], this.props.auth.account.username).then((result) => {
      if (result.status === 200) {
        this.props.dispatch(joinRooms({ rooms: [result.data.uuid] }));
        this.setState({ testid: result.data.uuid });
        this.interval = setInterval(() => {
          if (this.props.progressBars[result.data.uuid] !== undefined) {
            if (this.props.progressBars[result.data.uuid].status === 'COMPLETE' || this.props.progressBars[result.data.uuid].status === 'FAILURE') {
              this.setState({ entermaintactive: false });
              clearInterval(this.interval);
            }
          }
        }, 500);
      }
    }).catch((error) => {
      this.setState({
        entermaintactive: false,
        errors: {
          errMessage: 'Fatal Error (' + error.status + ') ' + error.statusText
        }
      });
    });
  }

  render() {
    const upDownStyle = styler([{ key: 'in', color: '#6BA4B8' }, { key: 'out', color: '#FF9E1B' }]);
    const axistype = 'linear';

    if (this.state.loading === true) {
      return (<Card><CardBody><SpinnerInfo/></CardBody></Card>);
    }
    const f = format('.2f');
    let inValue; let
      outValue;
    if (this.state.tracker) {
      const index = this.state.trafficSeries.bisect(this.state.tracker);
      const trackerEvent = this.state.trafficSeries.at(index);
      inValue = `${f(trackerEvent.get('in'))}`;
      outValue = `${f(trackerEvent.get('out'))}`;
    }
    const tracker = this.state.tracker ? `${this.state.tracker}` : '';
    let portstates = {};
    if (this.props.jtidata[this.state.data.data.source] !== undefined) {
      let pl = this.state.data.data.source_int.split(',');
      for (let j = 0; j < pl.length; j++) {
        if (pl.length > 1 && pl[j].startsWith('ae')) {
          console.log('ignoring ' + pl[j]);
        } else if (this.props.jtidata[this.state.data.data.source][pl[j]] !== undefined) {
          portstates[pl[j]] = this.props.jtidata[this.state.data.data.source][pl[j]].status.state;
        }
      }
    }
    let path = this.props.edgeId.split('--', 2);
    let revId = path[1] + '--' + path[0];
    return (<Row>
            <Col md={3}>
                {this.props.lsdb.edges[this.props.edgeId] === undefined
                && <Alert color="danger"><i className="glyphicons glyphicons-warning-sign" /> No LSDB information for this link</Alert>
                }
                <Card>
                    <CardBody>

                        <Row>
                            <Col md={6}>
                                {this.state.data.data.data_source === 'jti'
                                    && <div>
                                        <div className="text-uppercase text-muted fw-bold font-xs mb-0">Port Status</div>
                                        {Object.keys(portstates).map((key, idx) => {
                                          return (<Row><Col><span className="h5">{portstates[key] === 'UP' ? <Badge color="success">UP</Badge> : portstates[key] === 'down' ? <Badge color="danger">DOWN</Badge> : <Badge>UNKNOWN</Badge>}</span> Port {idx + 1}</Col></Row>);
                                        })}
                                    </div>
                                }
                                <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Capacity</div>
                                <div className="h5">{this.state.data.data.capacity}bps{Object.keys(portstates).length > 1 ? ' Bundle' : ''}</div>
                                <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Data Source</div>
                                <div className="h5 text-uppercase">{this.state.data.data.data_source ? this.state.data.data.data_source : 'Influx'}</div>
                            </Col>
                            {this.props.lsdb.edges[this.props.edgeId] !== undefined
                            && <Col md={6}>
                                {(this.props.lsdb.edges[this.props.edgeId].teMetric === 54321 || this.props.lsdb.edges[revId].teMetric === 54321)
                                  ? <Button color="success" onClick={this.exitMaintenance}>Exit Maintenance</Button>
                                  : <Button color="warning" onClick={this.enterMaintenance}>Enter Maintenance</Button>
                                }

                            </Col>
                            }
                        </Row>
                        <Modal isOpen={this.state.entermaintactive}>
                            <ModalBody>
                                {this.props.progressBars[this.state.testid] !== undefined
                                  ? <div>
                                        <Progress value={((this.props.progressBars[this.state.testid].current / this.props.progressBars[this.state.testid].total) * 100)}/>
                                        <p>Running step {this.props.progressBars[this.state.testid].current} of {this.props.progressBars[this.state.testid].total}: {this.props.progressBars[this.state.testid].message}</p>
                                    </div>
                                  : <p className="text-center">Initializing funky progress bar...</p>}
                            </ModalBody>
                        </Modal>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={6}>
                                <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2 text-end">{this.state.data.data.source_name} <i className="icon-control-forward"/></div>
                                <div className="h5 text-end">{this.bitsToSize(this.props.traffic[this.state.data.data.source + '--' + this.state.data.data.target])}</div>
                            </Col>
                            <Col md={6}>
                                <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2"><i className="icon-control-rewind"/> {this.state.data.data.target_name}</div>
                                <div className="h5">{this.bitsToSize(this.props.traffic[this.state.data.data.target + '--' + this.state.data.data.source])}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                            {this.props.lsdb.edges[this.props.edgeId]
                                && <div className="text-end">
                                <Row>
                                    <Col>
                                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">TE Metric <i className="icon-control-forward"/></div>
                                        <div className="h5">{this.props.lsdb.edges[this.props.edgeId].teMetric}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Reserved <i className="icon-control-forward"/></div>
                                        <div className="h5">{this.bitsToSize(this.props.lsdb.edges[this.props.edgeId].maximumReservableBW - this.props.lsdb.edges[this.props.edgeId].unreservedBandwidth)}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Link Speed <i className="icon-control-forward"/></div>
                                        <div className="h5">{this.bitsToSize(this.props.lsdb.edges[this.props.edgeId].maximumBW, 0)}</div>
                                    </Col>
                                </Row>
                                <Row>
                                        <Col>
                                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Total Reservable <i className="icon-control-forward"/></div>
                                        <div className="h5">{this.bitsToSize(this.props.lsdb.edges[this.props.edgeId].maximumReservableBW, 0)}</div>
                                    </Col>
                                </Row>
                                </div>
                            }
                            </Col>
                            <Col md={6}>
                            {this.props.lsdb.edges[revId]
                            && <div>
                                <Row>
                                    <Col>
                                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2"><i className="icon-control-rewind"/> TE Metric</div>
                                        <div className="h5">{this.props.lsdb.edges[revId].teMetric}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2"><i className="icon-control-rewind"/> Reserved</div>
                                        <div className="h5">{this.bitsToSize(this.props.lsdb.edges[revId].maximumReservableBW - this.props.lsdb.edges[revId].unreservedBandwidth)}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2"><i className="icon-control-rewind"/> Link Speed</div>
                                        <div className="h5">{this.bitsToSize(this.props.lsdb.edges[revId].maximumBW, 0)}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2"><i className="icon-control-rewind"/> Total Reservable</div>
                                        <div className="h5">{this.bitsToSize(this.props.lsdb.edges[revId].maximumReservableBW, 0)}</div>
                                    </Col>
                                </Row>
                            </div>
                            }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col md={9}>
                <Card>
                    <CardBody>
                        {this.state.data.history[0].data.length > 0
                        && <Resizable>
                            <ChartContainer
                                timeRange={this.state.timerange}
                                trackerPosition={this.state.tracker}
                                onTrackerChanged={this.handleTrackerChanged}
                                enablePanZoom={false}
                                maxTime={this.state.trafficSeries.range().end()}
                                minTime={this.state.trafficSeries.range().begin()}
                                minDuration={1000 * 60 * 60}
                                onBackgroundClick={() => this.setState({ selection: null })}
                                highlight={this.state.highlight}
                                onHighlightChange={highlight => this.setState({ highlight })
                                }
                                selection={this.state.selection}
                                onSelectionChange={selection => this.setState({ selection })
                                }
                                // onTimeRangeChanged={this.handleTimeRangeChange}
                            >
                                <ChartRow height="250" debug={false}>
                                    <Charts>
                                        <AreaChart
                                            axis="traffic"
                                            series={this.state.trafficSeries}
                                            columns={{
                                              up: ['in'],
                                              down: ['out']
                                            }}
                                            style={upDownStyle}
                                        />
                                    </Charts>
                                    <YAxis
                                        id="traffic"
                                        label="Traffic (bps)"
                                        labelOffset={0}
                                        min={-this.state.max}
                                        max={this.state.max}
                                        absolute={true}
                                        width="60"
                                        type={axistype}
                                    />
                                </ChartRow>
                            </ChartContainer>

                        </Resizable>
                        }
                        <Row>
                            <Col md={6}>
                                <p style={{ color: '#aeaeae' }}><b>{this.state.data.data.source_name + '-' + this.state.data.data.target_name} Peak:</b> {this.bitsToSize(this.state.trafficSeries.max('in'))}<br/><b>{this.state.data.data.target_name + '-' + this.state.data.data.source_name} Peak:</b> {this.bitsToSize(this.state.trafficSeries.max('out'))}</p>
                                <p style={{ color: '#808080', fontSize: '11px' }}>{tracker}&nbsp;</p>
                            </Col>
                            <Col md={6}>
                                <Legend
                                    type="swatch"
                                    align="right"
                                    marginBottom={0}
                                    style={upDownStyle}
                                    highlight={this.state.highlight}
                                    onHighlightChange={highlight => this.setState({ highlight })}
                                    selection={this.state.selection}
                                    onSelectionChange={selection => this.setState({ selection })}
                                    categories={[
                                      { key: 'in', label: this.state.data.data.source_name + '-' + this.state.data.data.target_name, value: this.bitsToSize(inValue) },
                                      { key: 'out', label: this.state.data.data.target_name + '-' + this.state.data.data.source_name, value: this.bitsToSize(outValue) }
                                    ]} />
                            </Col>
                        </Row>
                        <Row>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>);
  }
}

function mapStateToProps({ JTI, BGP, authenticationState, socketioProgress }) {
  return {
    jtidata: JTI.data,
    lsdb: BGP,
    auth: authenticationState,
    progressBars: socketioProgress.progressBars
  };
}

export default connect(mapStateToProps)(WeatherMapEdge);
