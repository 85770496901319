import { Button, Card, CardBody, Col, Row, Alert } from 'reactstrap';
import generateFormFields from '../../helpers/FormFieldGenerator';
import React, { useState } from 'react';
import FormValidationErrors from '../Errors/FormValidationErrors';
import LoadingOverlay from '../LoadingOverlay';
import { formValidator } from '../../helpers/FormValidator';
import isEmpty from 'lodash.isempty';

const ProvisionalOrderRequest = ({
  contacts,
  submit,
  cancel,
  loading
}) => {
  const [data, setData] = useState({
    name: ''
  })
  const [errors, setErrors] = useState([]);

  const form = {
    name: {
      label: 'Name',
      type: 'text',
      mandatory: true,
      width: 12
    },
    description: {
      label: 'Description',
      type: 'textarea',
      width: 12
    },
    contact: {
      label: 'Contact',
      type: 'select',
      width: 12
    }
  }

  const submitOrder = () => {
    if(validated()){
      submit(data)
    }
  }

  const validated = () => {
    let errorArr = formValidator(form, data);
    setErrors(errorArr);
    return isEmpty(errorArr);
  };
  return (
    <div className={'animated fadeIn'}>
      <LoadingOverlay loading={loading}>
        <Card className='bg-light border-0 mb-0'>
          <CardBody>
            <Alert color={'warning'}>Create a provisional order to use with public sector frameworks tender responses.</Alert>
            <FormValidationErrors errors={errors}/>
            <Row form>
              {generateFormFields({
                fields: form,
                handleInput : event => setData({...data, [event.target.id]: event.target.value}),
                getSelectOptions: () => contacts?.filter(contact => contact.isActive) ?? [],
                getSelectedOption: () => contacts?.filter(contact => contact.id === data.contact) ?? null,
                handleSelectInput: (key, selected) => setData({...data, contact: selected.id}),
                data: data
              })}
            </Row>
            <Row>
              <Col className={'d-flex justify-content-end'}>
                <Button color={'primary'} onClick={submitOrder}>Submit</Button>
                <Button className={'ms-2'} onClick={cancel}>Cancel</Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </LoadingOverlay>
    </div>
  )
}

export default ProvisionalOrderRequest
