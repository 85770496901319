import FilteredTableV2 from '../../components/FilteredTableV2';
import ExtraButtons from '../../utils/Helpers/ExtraButtons';
import { ButtonIcon } from '../../components/ButtonIcon';
import React, { useEffect, useState } from 'react';
import { Badge, ListGroup, ListGroupItem } from 'reactstrap';
import { SelectMod } from '../../components/Selects/SelectMod';
import numeral from 'numeral';
import { connect } from 'react-redux';
import {
  cloneQuoteProduct,
  removeQuoteProduct,
  resetQuoteProductLinkedProducts,
} from '../../actions/quote';
import { GenericModal } from '../../components/Modals/GenericModal';
import QuoteProduct from './QuoteProduct';
import isEmpty from 'lodash.isempty';
import { ProductEnums } from '../../utils/Constants/Product';
import HeadlessModal from '../../components/Modals/HeadlessModal';
import EntityMainFormCard from '../../components/Cards/EntityMainFormCard';
import { isCancelProduct } from '../../utils/Products/ProductService';
import startsWith from 'lodash.startswith';
import { resolveCurrencySign } from '../../utils/Helpers/Currency';
import Currency from '../../components/Currency';

const QuoteProducts = ({
  noDataText,
  products,
  newQuote,
  inputHandler,
  optionSets,
  productSLAs,
  activeServices,
  dispatch,
  onOrderChange,
  quote,
  currency
}) => {
  const [selectedProduct, setSelectedProduct] = useState({})
  const [showProductModal, setShowProductModal] = useState(false)

  const toggleModal = () => setShowProductModal(prevState => !prevState);

  const getSelectOptions = (field) => {
    if(field === 'sla'){
      return productSLAs.list.map(sla => {
        return {
          label: sla.name,
          value: sla.id,
        }
      })
    }
    if(field === 'orderType'){
      return optionSets.product['orderType'].options;

    }
    return optionSets.product[field].options;
  }

  const getSelectedOption = (field) => {
    if(field === 'sla'){
      return productSLAs.list.find(sla => sla.id === (selectedProduct.sla?.id ?? selectedProduct.sla ))
    }
    if(field === 'orderType'){
      return optionSets.product['orderType'].options.find(option => option.value === selectedProduct[field]);
    }
    return optionSets.product[field].options.find(option => option.value === selectedProduct[field]);
  }

  const handleOrderTypeChange = (productIndex, selected) => {
    if(selected.label === 'New Item'){
      dispatch(resetQuoteProductLinkedProducts(productIndex))
    }
  }

  const handleInput = (event) => {
    inputHandler(selectedProduct.id, {[event.target.id] : event.target.value})
    setSelectedProduct({
      ...selectedProduct,
    [event.target.id] : event.target.value
    })
  }
  const handleSelectInput = (id, field, selected) => {
    inputHandler(id, { [field]: selected?.value || null });
    setSelectedProduct({
      ...selectedProduct,
      [field]: selected?.value || null
    })
  }

  const removeProduct = (index) => {
    dispatch(removeQuoteProduct(index));
  }

  const cloneProduct = (index) => {
    dispatch(cloneQuoteProduct(index));
  }

  const getOrderTypeOption = (value) => {
    return optionSets.product['orderType'].options.find(option => option.value === value) ?? null
  }

  const getSelectMenuPlacement = (rowCount, rowIndex, pageSize, pageIndex) => {
    return (((pageSize * (pageIndex + 1)) - (rowIndex + 1)) - 2 < 0) ? 'top' : 'bottom'
  }

  const hasLinkedProducts = () =>{
    return quote.form.products.filter(product => !isEmpty(product.linkedProducts))
  }

  const getOrderTypeOptions = (productNumber = '') => {
    return optionSets.product['orderType'].options.map(option => {
      return {
        ...option,
        isDisabled: (isCancelProduct(productNumber) && option.label !== 'Cancel') ||  (!isCancelProduct(productNumber) && option.label === 'Cancel')
    }
    })
  }
  let columnsReadOnly = [
    {
      header: 'Product',
      accessorKey: 'productNumber',
      maxSize: 150
    },
    {
      header: 'Name',
      accessorKey: 'name',
      maxSize: 150
    },
    {
      header: 'Term (m)',
      accessorKey: 'term',
      maxSize: 100,
      cell: props => (numeral(props.getValue()).format('0')),
    },
    {
      header: 'Install',
      accessorKey: 'install',
      cell: props => <Currency currency={currency} value={props.getValue()}/>,
      maxSize: 100
    },
    {
      header: 'Rental',
      accessorKey: 'rental',
      cell: props => <Currency currency={currency} value={props.getValue()}/>,
      maxSize: 100
    }
  ];
  let columns = [
      {
        header: 'Product',
        accessorKey: 'productNumber',
        maxSize: 100
      },
      {
        header: 'Name',
        accessorKey: 'name',
        minSize: 200,
        cell: props => !props.row.original.pricingToolQuote ?
          props.getValue() || ''
          :
          <span>{props.getValue()}<Badge className="ms-2" color="warning">pricing-tool</Badge></span>
      },
      {
        header: 'Order Type',
        accessorKey: 'orderType',
        maxSize: 150,
        cell: (props) => {
          if(newQuote){
            return <SelectMod
              name="orderType"
              id={'orderType'}
              value={getOrderTypeOption(props.getValue())}
              options={getOrderTypeOptions(props.row.original.productNumber)}
              onChange={(selected) => {
                handleOrderTypeChange(props.row.index, selected)
                handleSelectInput(props.row.original.id, 'orderType', selected)
              }}
              menuPlacement={getSelectMenuPlacement(
                props.table.getPaginationRowModel().rows.length,
                props.row.index,
                props.table.getState().pagination.pageSize,
                props.table.getState().pagination.pageIndex,
              )}
            />
          }
          return getOrderTypeOption(props.getValue())?.label ?? ''
        }
      },
      {
        header: 'Linked Products',
        accessorKey: 'linkedProducts',
        minSize: hasLinkedProducts() ? 300 : 100,
        cell: (props) => {
          if(props.row.original.orderType !== ProductEnums.orderType.NEW_ITEM && newQuote){
            return (
              <SelectMod
                isDisabled={!newQuote}
                value={activeServices.filter(service => !isEmpty(props.row.original.linkedProducts
                  .filter(linkedProduct => linkedProduct.id === service.id )))
                  .map(service => {
                  return {label: `${service.productNumber} - ${service.name}`, value: service.id, isFixed: !newQuote }
                })}
                isMulti
                isFocused
                isClearable={!newQuote}
                hideClearable={ !newQuote ? { multiValueRemove: (base) => ({ ...base, display: 'none' }) } : null}
                closeMenuOnSelect={false}
                options={activeServices.map(service => {
                    return {label: `${service.productNumber} - ${service.name}`, value: service.id}
                  })}
                onChange={(selected) => {
                  const selectedValues = selected ? selected.map((select) => {
                    return {name: select.label, id: select.value}
                  }) : [];
                  inputHandler(props.row.original.id, { linkedProducts: selectedValues })
                }}
                menuPlacement={getSelectMenuPlacement(
                  props.table.getPaginationRowModel().rows.length,
                  props.row.index,
                  props.table.getState().pagination.pageSize
                )
              }
              />
            )
          }
          else if(!isEmpty(props.row.original.linkedProducts)){
            return  <ListGroup>
              {props.row.original.linkedProducts.map((linkedProduct) => {
                return <ListGroupItem key={linkedProduct.productNumber}>{`${linkedProduct.productNumber} - ${linkedProduct.name}`}</ListGroupItem>
              })}
            </ListGroup>

          }
          return ''
        }
      },
      {
        header: 'Term (m)',
        accessorKey: 'term',
        maxSize: 60,
        cell: props => (numeral(props.getValue()).format('0')),
      },
      {
        header: 'Install',
        accessorKey: 'install',
        maxSize: 80,
        cell: props => <Currency currency={currency} value={props.getValue()}/>,

      },
      {
        header: 'Rental',
        accessorKey: 'rental',
        maxSize: 80,
        cell: props => <Currency currency={currency} value={props.getValue()}/>,
      },
      {
        header: '',
        accessorKey: 'id',
        maxSize: 150,
        cell: props => {
          return (
            <div className={'d-flex justify-content-end'}>
              {(!props.row.original.pricingToolQuote && newQuote) &&
                <ButtonIcon
                  outline
                  icon={'fa fa-copy'}
                  onClick={() => cloneProduct(props.row.index)}
                  colour={'success'}
                  tooltip={'clone'}
                />
              }
              {newQuote &&
                <ButtonIcon
                  outline
                  icon={'fa fa-trash'}
                  onClick={() => removeProduct(props.row.index)}
                  colour={'danger'}
                  tooltip={'remove'}
                />
              }
              <ButtonIcon
                outline
                disabled={startsWith('HSO-00-101', props.row.original.productNumber)}
                icon={'fa fa-pencil'}
                onClick={() => {
                  setSelectedProduct(props.row.original)
                  setShowProductModal(true)

                }}
                colour={'info'}
                tooltip={'edit'}
              />


            </div>
          );
        }
      }
    ];
  const resolveColumns = () => {
    if (onOrderChange !== undefined) {
      quote.form.products.length > 1 && columnsReadOnly.push({
        header: '',
        accessorKey: 'id',
        maxSize: 60,
        cell: props => {
          return (<div className='d-flex justify-content-between'>
            <i className="fa fa-arrow-down ms-1 p-1" style={{ cursor: 'pointer' }}
               onClick={() => onOrderChange(props.row.index, props.row.index + 1)}/>
            <i className="fa fa-arrow-up ms-1 p-1" style={{ cursor: 'pointer' }}
               onClick={() => onOrderChange(props.row.index, props.row.index - 1)}/>
          </div>);
        }
      });
      return columnsReadOnly;
    }
    return columns;
  };
  return (
    <>
      <FilteredTableV2
        minRows={10}
        columns={resolveColumns()}
        data={products || quote.form.products}
        showPagination={quote.form.products.length > 10}
        pageSizeOptions={[10, 20, 50, 100, 200]}
        noDataText={noDataText || 'This Quote has no Products'}
        loading={false}
        defaultPageSize={10}
        autoResetPageIndex={false}
      />
      <HeadlessModal
        size={'lg'}
        open={showProductModal}
        toggle={toggleModal}
      >
          <QuoteProduct
            onClose={toggleModal}
            product={quote.form.products.find(product => selectedProduct?.id === product.id) ?? null}
            handleEditInputs={handleInput}
            handleSelectInput={(field, selected) => handleSelectInput(selectedProduct?.id, field, selected)}
            getSelectedOption={getSelectedOption}
            getSelectOptions={getSelectOptions}
          />

      </HeadlessModal>
    </>
  )
}

function mapStateToProps({
  helpers,
  productSLAs,
  quote
}) {
  return {
    optionSets: helpers.optionSets,
    productSLAs,
    quote
  };
}

export default connect(mapStateToProps)(QuoteProducts);
