import React, {useEffect, useState} from 'react';
import {SpinnerInfo} from "../../components/Spinner/Spinner";
import {
    Card,
    Col,
    Row,
    Badge,
    Button,
    InputGroup,
    Input,
    UncontrolledTooltip
} from "reactstrap";
import ReactTable from "react-table-v6";
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import {
    getEntries,
    addEntry,
    deleteEntry,
    renameEntry,
    SET_RENAME_LIVE
} from "../../actions/configTemplate";
import {connect} from "react-redux";
import {ScaleLoader} from "react-spinners";
import {setConfirmDialog} from "../../actions/dialogs";
import {checkPermission} from "../../utils/Auth/AuthService";
import {useNavigate} from 'react-router-dom'


export const paintTags = type => {
    switch (type) {
        case 'base':
            return 'danger';
        case 'create':
            return 'danger';
        case 'template':
            return 'success'
        default:
            return 'info'
    }
}

const ConfigTemplateList = ({
                                loading,
                                dispatch,
                                data,
                                deleting,
                                updating,
                                auth,
                                renameLive,
                                fetchEntryFailure
                            }) => {
    const [entryString, addEntryString] = useState('');
    const [renameButton, setRenameButton] = useState(undefined);
    const [renameText, setRenameText] = useState(undefined);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getEntries());
    }, []);

    const deleteTemplate = (id, description) =>
        dispatch(setConfirmDialog({
            color: 'danger',
            text: 'Are you sure you want to delete the template' + description + '?',
            proceed: () => dispatch(deleteEntry(id)),
        }));

    const renameTemplate = id => {
        dispatch(renameEntry(id, renameText))
    }

    const setRenameLive = value => {
        dispatch({type: SET_RENAME_LIVE, data: value})
    }

    const returnColumns = deleteTemplate => {
        return [
            {
                Header: 'Description',
                accessor: 'description',
                filterable: true,
                className: 'd-flex align-items-center',
                Cell: ({original: {description, id, notes} = []}) => {
                    if (renameLive === id) {
                        return (
                            <InputGroup>
                                <Button onMouseEnter={() => setRenameButton(id + "rename")}
                                        onMouseLeave={() => setRenameButton(undefined)}
                                        onClick={() => renameTemplate(id)}
                                        id="rename" size='sm'
                                        color={renameButton === id + "rename" ? 'info' : "success"}
                                >
                                    <i className="fa fa-check"/>
                                </Button>

                                <Button onMouseEnter={() => setRenameButton(id + "cancel")}
                                        onMouseLeave={() => setRenameButton(undefined)}
                                        id="cancel" size='sm'
                                        color={renameButton === id + "cancel" ? 'info' : "danger"}
                                        onClick={() => {
                                            setRenameLive(undefined)
                                            setRenameButton(undefined)
                                            setRenameText(undefined)
                                        }}
                                >
                                    <i className="fa fa-times"/>
                                </Button>
                                <UncontrolledTooltip placement="top" target='rename'>
                                    Rename
                                </UncontrolledTooltip>
                                <UncontrolledTooltip placement="top" target='cancel'>
                                    Cancel
                                </UncontrolledTooltip>
                                <Input placeholder="New name" onChange={e => setRenameText(e.target.value)}/>
                            </InputGroup>
                        )
                    } else {
                        return (
                            <>
                                <div className='w-100'>
                                    <h6 className="m-0 d-inline" id={"description" + id}>{description}</h6>
                                    <i
                                        style={{cursor: 'pointer', color: "red"}}
                                        className="fa fa-edit ms-2"
                                        onClick={() => setRenameLive(id)}
                                    />
                                </div>
                                <i style={{cursor: "pointer"}}
                                   onClick={() => navigate(id)}
                                   className="fa fa-search"></i>
                                {
                                    notes ?
                                        <UncontrolledTooltip placement="right" target={`description` + id}>
                                            {notes}
                                        </UncontrolledTooltip>
                                        : null
                                }
                            </>
                        )
                    }
                },
                filterMethod: (filter, row) =>
                    row[filter.id].toLowerCase().includes(filter.value) || row[filter.id].includes(filter.value)
            },
            {
                width: 200,
                Header: 'Date modified',
                Cell: ({original: {date_modified, id} = ''}) =>
                    <div className='w-100' style={{cursor: 'pointer'}}
                         onClick={() => navigate('configtemplate/' + id)}>
                        {moment(date_modified).format('DD-MM-YYYY - h:mm a')}
                    </div>,
                accessor: 'date_modified',
                className: 'd-flex align-items-center',
                sortMethod: (a, b) => {
                    return new Date(a) > new Date(b) ? 1 : -1
                }
            },
            {
                Header: 'Tags',
                Cell: ({original: {tags, id} = []}) => {
                    return (
                        tags.sort().map((tag, key) => <div key={key}
                                                           style={{cursor: 'pointer'}}
                                                           onClick={() => navigate('configtemplate/' + id)}
                            ><Badge className="me-1 p-2"
                                    key={key}
                                    data-for={tag === 'base' ? key + 'base' : key}
                                    data-tip={tag === 'base' ? key + 'base' : key}
                                    color={paintTags(tag)}>{tag}
                            </Badge>
                                <ReactTooltip
                                    key={key + 'tip'}
                                    id={key + 'base'}
                                    type="error"
                                    place="right"
                                    effect="float"
                                    multiline={true}
                                >Base templates are for extending <br/> and can't be generated directly
                                </ReactTooltip>
                            </div>
                        )
                    )
                },
                accessor: 'tags',
                filterable: true,
                className: 'd-flex align-items-center',
                filterMethod: (filter, row) => row.tags.find(e => e.startsWith(filter.value))
            },
            {
                width: 50,
                Cell: ({original: {id, description} = []}) =>
                    deleting === id || updating === id ? <><i className='fa fa-pen-square'/><ScaleLoader color='red'
                                                                                                         width={4}/><i
                            className='fa fa-pen-square'/></> :
                        <><i className='fa fa-pen me-2'/><i onClick={() => deleteTemplate(id, description)}
                                                            style={{cursor: 'pointer', color: "red"}}
                                                            className='fa fa-trash'/></>,
                className: 'd-flex align-items-center'
            }
        ];
    }

    const addNewEntry = () => {
        if (entryString.length > 2) {

            const newEntry = {
                'description': entryString,
                'schema': {}
            }

            addEntryString('')
            dispatch(addEntry(newEntry))
        }
    }

    if (loading) {
        return <SpinnerInfo/>
    }
    return (
        <div className="animated fadeIn">
            <Row>
                <Col>
                    <Card className="d-flex justify-content-center align-items-center">
                        <Row className="w-100 ps-4">
                            <Col sm={10} className="d-flex justify-content-start al mt-3 ps-2">
                                <h5 className="m-0 pt-2 pb-2">Config Templates</h5>
                            </Col>
                            {
                                fetchEntryFailure ?
                                    <Col sm={10} className="d-flex justify-content-start mt-3 ps-2">
                                        <h5 style={{color: "red"}} className="m-0 pt-2 pb-2 red">Network Error</h5>
                                        <i style={{color: "red"}} className="fa fa-exclamation-circle"></i>
                                    </Col>
                                    : null
                            }
                        </Row>

                        <Row className='w-100'>
                            <>
                                <Col sm={10} md={10}/>
                                <Col className='d-flex float-end' sm={2} md={2}>
                                    {
                                        checkPermission('ConfigTemplates', auth.account.permissions) ?
                                            <InputGroup>
                                                <Button id="add" size='sm' color='success'
                                                        onClick={() => addNewEntry()}>
                                                    <i className="fa fa-check"/>
                                                </Button>
                                                <UncontrolledTooltip placement="top" target='add'>
                                                    Add entry
                                                </UncontrolledTooltip>
                                                <Input placeholder="New Entry"
                                                       onChange={e => addEntryString(e.target.value)}/>
                                            </InputGroup>
                                            : null
                                    }
                                </Col>
                            </>
                        </Row>
                        <ReactTable className="ps-4 pe-4 pb-4 pt-2 mt-0 w-100"
                                    data={data}
                                    columns={returnColumns(deleteTemplate)}
                                    minRows={5}
                                    defaultSorted={[{id: 'date_modified', desc: true}]}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );
}


function mapStateToProps(
    {
        configTemplate:
            {
                fetching_entries, template_entries, deleting_entries, updatingEntry, renameLive, fetchEntryFailure
            }
        ,
        authenticationState,
    }) {
    return {
        loading: fetching_entries,
        data: template_entries,
        deleting: deleting_entries,
        updating: updatingEntry,
        auth: authenticationState,
        renameLive: renameLive,
        fetchEntryFailure: fetchEntryFailure
    };
}

export default connect(mapStateToProps)(ConfigTemplateList);
