import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, CardHeader, CardText, Row, Col, Progress, Form, InputGroup, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { submitDeviceCheck, getJobStatus } from '../../utils/DeviceCheck/DeviceCheckService';
import { joinRooms } from '../../actions/socketio';
import DeviceResult from './DeviceResult';

class DeviceCheck extends Component {
    static contextTypes = {
      router: PropTypes.shape({
        history: PropTypes.shape({
          push: PropTypes.func.isRequired,
          replace: PropTypes.func.isRequired
        }).isRequired,
        staticContext: PropTypes.object
      }).isRequired
    };

    constructor(props) {
      super(props);

      this.state = {
        data: {
          loaded: false,
          processing: false
        },
        errors: {},
        state: 0,
        device: '',
        progress: 0,
        taskname: '',
        res: {}
      };

      this.handleValidSubmit = this.handleValidSubmit.bind(this);
      this.resetState = this.resetState.bind(this);
      this.reRun = this.reRun.bind(this);
    }

    resetState() {
      this.setState({
        data: {
          loaded: false,
          processing: false
        },
        errors: {},
        state: 0,
        device: '',
        progress: 0,
        taskname: '',
        res: {},
        processing: false
      });
    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }

    reRun(event) {
      event.preventDefault();
      let dev = this.state.device;
      this.resetState();
      submitDeviceCheck(dev).then((result) => {
        if (result.status === 200) {
          this.props.dispatch(joinRooms({ rooms: [result.data.uuid] }));
          this.setState({ state: 1, testid: result.data.uuid, device: dev });
          this.interval = setInterval(() => {
            if (this.props.progressBars[result.data.uuid] !== undefined) {
              if (this.props.progressBars[result.data.uuid].status === 'COMPLETE') {
                getJobStatus(result.data.uuid).then((r2) => {
                  clearInterval(this.interval);
                  this.setState({ res: r2.data.meta, state: 2 });
                }).catch((error) => {
                  clearInterval(this.interval);
                  this.setState({
                    state: 0,
                    processing: false,
                    errors: {
                      errMessage: 'Fatal Error (' + error.status + ') ' + error.statusText
                    }
                  });
                });
              }
            }
          }, 500);
        }
      }).catch((error) => {
        this.setState({
          state: 0,
          processing: false,
          errors: {
            errMessage: 'Fatal Error (' + error.status + ') ' + error.statusText
          }
        });
      });
      this.setState({ processing: true });
    }

    handleValidSubmit(event) {
      event.preventDefault();
      submitDeviceCheck(this.refs.deviceName.value).then((result) => {
        if (result.status === 200) {
          this.props.dispatch(joinRooms({ rooms: [result.data.uuid] }));
          this.setState({ state: 1, testid: result.data.uuid, device: this.refs.deviceName.value });
          this.interval = setInterval(() => {
            if (this.props.progressBars[result.data.uuid] !== undefined) {
              if (this.props.progressBars[result.data.uuid].status === 'COMPLETE') {
                this.setState({ state: 2 });
                clearInterval(this.interval);
              }
            }
          }, 500);
        }
      }).catch((error) => {
        this.setState({
          state: 0,
          processing: false,
          errors: {
            errMessage: 'Fatal Error (' + error.status + ') ' + error.statusText
          }
        });
      });
      this.setState({ processing: true });
    }

    render() {
      if (this.state.state === 1) {
        if (this.props.progressBars[this.state.testid] !== undefined) {
          let progress = (this.props.progressBars[this.state.testid].current / this.props.progressBars[this.state.testid].total) * 100;
          return (<Row className="animated fadeIn">
                    <Col md={{ size: 4, offset: 4 }}>
                        <Card className="card-accent-secondary">
                            <CardHeader>Performing tests on {this.state.device}...</CardHeader>
                            <CardBody>
                                <Progress value={progress}/>
                                <p>Running test {this.props.progressBars[this.state.testid].current} of {this.props.progressBars[this.state.testid].total}: {this.props.progressBars[this.state.testid].message}</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>);
        }

        return (<Row className="animated fadeIn">
                    <Col md={{ size: 4, offset: 4 }}>
                        <Card className="card-accent-secondary">
                            <CardBody>
                                <p>Initializing funky progress bar...</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>);
      }

      if (this.state.state === 2) {
        return (<div className="animated fadeIn"><Row>
                <Col><p style={{ textAlign: 'right' }}><Button onClick={this.resetState} color="warning"><i className="icon-star"/> New Test</Button> <Button color="info" onClick={this.reRun}><i className="icon-refresh"/> Re-Run</Button> <Button id="UncontrolledPopover" type="button" className="secondary">
                    <i className="icon-share"/> Share
                </Button>
                    <UncontrolledPopover placement="bottom" target="UncontrolledPopover">
                        <PopoverHeader>Sharing Link</PopoverHeader>
                        <PopoverBody>https://admin.hso-group.net/toolbox/dcheck/{this.state.testid}</PopoverBody>
                    </UncontrolledPopover></p></Col>
            </Row>
                <Row>
                <Col>
                <DeviceResult uuid={this.state.testid}/>
            </Col>
                </Row></div>);
      }

      return (
            <div className="animated fadeIn">
                <Row>
                    <Col md={{ size: 4, offset: 4 }}>
                        <Card className="card-accent-secondary">
                            <CardHeader>Device Checker</CardHeader>
                            <CardBody>
                                {this.state.errors.infoMessage && <Card inverse={true} color="info" className="animated zoomIn"><CardBody><CardText>{this.state.errors.infoMessage}</CardText></CardBody></Card>}
                                {this.state.errors.errMessage && <Card inverse={true} color="danger" className="animated zoomIn"><CardBody><CardText>{this.state.errors.errMessage}</CardText></CardBody></Card>}
                                <Form onSubmit={this.handleValidSubmit}>
                                    <InputGroup>
                                        <input type="text" className="form-control" placeholder="Device Name" ref="deviceName" id="deviceName" name="deviceName" disabled={this.state.processing}/>
                                        <Button disabled>.hso-group.net</Button>
                                    </InputGroup>
                                    <div className="clearfix" style={{ marginTop: 10 + 'px', marginBottom: 20 + 'px' }}>
                                        <Button type="submit" color="primary" className="float-end" style={{ minWidth: 113 + 'px', minHeight: 35 + 'px' }} disabled={this.state.processing}>{this.state.processing ? <div style={{ marginTop: 2 + 'px' }}></div> : 'Check'}</Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
      );
    }
}

function mapStateToProps(state) {
  return {
    progressBars: state.socketioProgress.progressBars
  };
}

export default connect(mapStateToProps)(DeviceCheck);
