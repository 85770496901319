import { Button } from 'reactstrap'
import React from 'react';

const SyncButton = ({ onSubmit }) => {

  return (
    <>
      <Button className="btn-sm ms-2" onClick={onSubmit}>Sync with CRM</Button>
    </>
  );
};

export default SyncButton;
