import React, { useEffect, useState } from 'react'
import {
  Card,
  CardText,
  Col,
  Alert,
  CardBody,
} from 'reactstrap'
import ReactTable from 'react-table-v6'
import { getZones } from '../../utils/DNS/DNS'
import { SpinnerInfo } from '../../components/Spinner/Spinner'
import { useNavigate } from 'react-router-dom'

const DNS = () => {

  // router
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [zones, setZones] = useState([])
  const [error, setError] = useState({})

  useEffect(() => {
    getAccountZones()
  }, [])

  const getAccountZones = () => {
    getZones().then((result) => {
      let zones = []
      let error = {}
      if (result.status === 200) {
        zones = result.data
        error.fetch = false
      } else {
        error.fetch = true
      }

      setZones(zones)
      setLoading(false)
      setError(error)
    })
  }

  const handleClickToZone = (name) => {
    navigate('/toolbox/dns/' + name)
  }

  if (loading) {
    return (
      <div className="app flex-row align-items-center animated fadeIn">
        <div className="container">
          <div className="justify-content-center">
            <SpinnerInfo/>
          </div>
        </div>
      </div>
    )
  }
  if (error.fetch) {
    return (
      <div className="row d-flex justify-content-center">
        <Alert color={'danger'} className={'text-center'}>
          <CardText>Unfortunately we are having difficulties with gathering information related to your DNS
            service.</CardText>
          <CardText>Please contact support for further assistance.</CardText>
        </Alert>
      </div>
    )
  }

  return (

    <div className="animated fadeIn">
      <Card>
        <CardBody className="d-flex flex-column justify-content-center align-items-center">
          <Col md={9}>
            <DNSTable zones={zones} handleClick={handleClickToZone}/>
          </Col>
        </CardBody>
      </Card>
    </div>
  )
}

const DNSTable = ({ zones, handleClick }) => {
  const columns = [{
    Header: 'Name',
    accessor: 'name',
    sortable: false
  }, {
    Header: 'Type',
    accessor: 'type',
    sortable: false
  }, {
    Header: '',
    filterable: false,
    sortable: false,
    accessor: 'id',
    width: 50,
    Cell: props => (
      <div className={'text-primary'}><i className="fa fa-search"></i></div>
    )
  }
  ]
  return <ReactTable
    className="animated fadeIn"
    noDataText="You have no DNS records"
    data={zones}
    columns={columns}
    minRows="2"
    filterable
    getTrProps={(state, rowInfo) => ({
      onClick: handleClick.bind(null, rowInfo ? rowInfo.row.name : null),
      style: { cursor: 'pointer' }
    })}
    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
  />
}

export default DNS
