import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, CardBody, CardHeader, InputGroup, Form, Row, Col, Button} from "reactstrap";
import {getClosestExchanges, getServingExchange} from "../../../actions/discovery";

class Exchanges extends Component {

  constructor(props) {
    super(props);
    this.pcRef = React.createRef();
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleSearch(e) {
    e.preventDefault();
    const postcode = this.pcRef.current.value;
    this.props.dispatch(getClosestExchanges(postcode));
    this.props.dispatch(getServingExchange(postcode));
  }

  render() {
    return(<div>
      <Card className={"card-accent-primary"}>
        <CardHeader>
          Closest Exchange Search
        </CardHeader>
        <CardBody>
          <Form onSubmit={this.handleSearch}>
            <InputGroup>
              <input type="text" className="form-control" placeholder="Postcode" disabled={this.props.exchanges.status > 0} ref={this.pcRef}/>
            </InputGroup>
            <div className="clearfix" style={{ marginTop: 10 + 'px', marginBottom: 20 + 'px' }}>
              <Button type="submit" color="primary" className="float-end" style={{ minWidth: 113 + 'px', minHeight: 35 + 'px' }} disabled={this.props.exchanges.status > 0}>{this.props.exchanges.status > 0 ? <div style={{ marginTop: 2 + 'px' }}></div> : 'Check'}</Button>
            </div>
          </Form>
        {this.props.exchanges.data.length > 0 &&
          <Row><Col>
        <Card>
          <CardHeader className={"card-accent-info"}>
            <Row><Col md={5}>Name</Col><Col md={1}>MDF ID</Col><Col md={1}>Postcode</Col><Col md={1}>Distance (km)</Col></Row>
          </CardHeader>
          <CardBody>
          {this.props.exchanges.data.map((ex) => {
            return <Row><Col md={5}>{ex.name} {ex.mdf === this.props.exchanges.serving?.mdf ? <i className={"icon-star"} style={{fontWeight: 'bold', color: 'red'}}></i> : ''}</Col><Col md={1}>{ex.mdf}</Col><Col md={1}>{ex.postcode}</Col><Col md={1}>{(ex.distance/1000).toFixed(3)}</Col></Row>
          }) }
          </CardBody>
        </Card>
          </Col></Row>
            }
        </CardBody>
      </Card>
        </div>)
  }
}

function mapStateToProps({discovery}) {
  return {
    exchanges: discovery.exchanges
  };
}

export default connect(mapStateToProps)(Exchanges);
