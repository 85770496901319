import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Progress,
  Row
} from 'reactstrap';
import Shifts from '../../components/Shifts/Shifts';
import { canViewTasks, isFieldOps, isNoc } from '../../utils/Auth/AuthService';
import ReactTable from 'react-table-v6';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { getSystemStatus } from '../../actions/alarms';
import { getCases } from '../../actions/cases';
import { MyCases } from '../../components/Widgets/MyCases';
import { connect } from 'react-redux';
import MyTasks from '../../components/Widgets/MyTasks';


const Dashboard = ({
  user,
  BGP,
  cases,
  systemStatus,
  dispatch
}) => {
  const [inMaint, setInMaint] = useState(0);
  const [loadingCases, setLoadingCases] = useState(true);

  const esStatusMap = {'green': 'success'};

  useEffect(() => {
    if (isNoc(user.permissions) || isFieldOps(user.permissions)) {
      dispatch(getSystemStatus());
    }
    getUserCases()
  }, [])

  useEffect(() => {
    let result = 0;
    for (let d in BGP.edges) {
      if (BGP.edges[d].teMetric === 54321) {
        result += 1;
      }
    }
    setInMaint(result)
  }, [BGP])


  const getUserCases = () => {
    setLoadingCases(true)
    dispatch(getCases({userId: user.cui, status: 0})).then(() =>  setLoadingCases(false))
  }



  return (
    <div className="animated fadeIn">
      <Row>
        <Col md={12} lg={9}>
          <Row>
            {(isNoc(user.permissions) || isFieldOps(user.permissions)) &&
              <Col md={12}>
                <MyCases
                  cases={cases.list}
                  onReload={getUserCases}
                  loading={loadingCases}
                />
              </Col>
            }
            {canViewTasks(user.permissions) &&
              <Col md={12}>
                <MyTasks/>
              </Col>
            }

          </Row>
          {(isNoc(user.permissions) || isFieldOps(user.permissions))
            && <>
              <Row>
                <Col>
                  <Card>
                    <CardHeader>Zabbix Proxies</CardHeader>
                    <CardBody>
                      {systemStatus.zabbix !== undefined
                        && <ReactTable
                          getTheadProps={() =>{
                            return {
                              style: { border: 'none' }
                            };
                          }}
                          getTheadTrProps={() =>{
                            return {
                              style: { border: 'none' }
                            };
                          }}
                          getTheadThProps={() =>{
                            return {
                              style: { border: 'none' }
                            };
                          }}
                          getTdProps={(state, rowInfo, column) =>{
                            return {
                              style: { border: 'none', fontWeight: column.Header === 'Name' ? 'bold' : 'regular' }
                            };
                          }}
                          className="animated fadeIn -highlight -striped"
                          noDataText="No Zabbix Proxies found."
                          data={systemStatus.zabbix.proxies}
                          columns={[{
                            filterable: false,
                            sortable: true,
                            Header: 'Name',
                            accessor: 'host',
                            width: 250
                          }, {
                            filterable: false,
                            sortable: true,
                            Header: 'Last Seen',
                            accessor: 'lastaccess',
                            Cell: props => (
                              <div>{moment(props.value * 1000).fromNow()}</div>
                            )
                          },
                            {
                              filterable: false,
                              sortable: true,
                              Header: 'CPU',
                              accessor: 'cpu',
                              Cell: props => {
                                let barcol = 'info';
                                if (props.value > 50) { barcol = 'warning'; }
                                if (props.value > 90) { barcol = 'danger'; }
                                return (<Progress value={Math.round(props.value, 2)} color={barcol} style={{ height: '4px', marginTop: '8px' }}/>);
                              }
                            }, {
                              filterable: false,
                              sortable: true,
                              Header: 'Latency',
                              accessor: 'latency',
                              Cell: props => (
                                <div>{props.value}ms</div>
                              )
                            }, {
                              filterable: false,
                              sortable: true,
                              Header: 'Queue',
                              accessor: 'hitems'
                            }, {
                              id: 'devicescount',
                              filterable: false,
                              Header: '',
                              Cell: ({ row, original }) => {
                                let now = Date.now();
                                let then = original.lastaccess * 1000;
                                if (now - then >= 60500) {
                                  return (<div><Badge color={'danger'}>FAIL</Badge></div>);
                                }
                                return (<div><Badge color={'success'}>OK</Badge></div>);
                              }
                            }
                          ]}
                          minRows="1"
                          filterable={false}
                          defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1}
                          defaultSorted={[{ id: 'hitems', desc: true }]}
                          showPagination={false}
                        />
                      }
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          }
        </Col>
        <Col md={12} lg={3}>
          {
            (isNoc(user.permissions) || isFieldOps(user.permissions)) &&
            <Card>
              <CardHeader>
                Backbone / ES Status
              </CardHeader>
              <CardBody>
                <ListGroup>
                  <ListGroupItem className="justify-content-between fw-bold">Backbone Nodes - Active <Badge pill color={'info'}>{Object.keys(BGP.nodes).length}</Badge></ListGroupItem>
                  <ListGroupItem className="justify-content-between fw-bold">Backbone Links - Active  <Badge pill color={'info'}>{Math.ceil(Object.keys(BGP.edges).length / 2)}</Badge></ListGroupItem>
                  <ListGroupItem className="justify-content-between fw-bold">Backbone Links - In Maintenance  <Badge pill color={'warning'}>{inMaint / 2}</Badge></ListGroupItem>
                  <ListGroupItem className="justify-content-between fw-bold">ES Cluster State  <Badge pill color={esStatusMap[systemStatus.es?.status] || 'warning'}>{systemStatus.es?.status || 'pending check'}</Badge></ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>


          }
          <Shifts title={'hSo Tech Shifts'} team={'dca01ca2-8dd7-4391-a2c6-fd3bce952024'} color={'warning'}/>
          <Shifts title={'Office Team'} team={'c2a0b36b-26e9-4104-a471-1c1a9c9c6951'} color={'primary'}/>
        </Col>
      </Row>
    </div>
  )
}


const mapStateToProps = ({authenticationState, BGP, zabbixAlarms, cases, tasks, helpers}) => {
  return {
    user: authenticationState.account,
    BGP,
    systemStatus: zabbixAlarms.systemstatus,
    cases
  };
};
export default connect(mapStateToProps)(Dashboard);
