import React, { Component } from 'react';
import { Card, CardBody, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { newAttributeBox } from '../../actions/radius';
import { connect } from 'react-redux';
import { PulseLoader } from 'react-spinners';

class AddAttribBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attribute: '',
      op: ':=',
      value: '',
      infoIncomplete: false,
      attribsList: [],
      opsList: []
    };
    this.returnAttributeTypes = this.returnAttributeTypes.bind(this);
    this.newAttrib = this.newAttrib.bind(this);
  }

  componentDidMount() {
    const attribs = this.returnAttributeTypes(this.props.activeAttrib);
    const ops = this.returnOpTypes(this.props.activeAttrib);

    this.setState({ attribsList: attribs, attribute: attribs[0] });
    this.setState({ opsList: ops, op: ops[0] });
  }

  newAttrib() {
    // Run parent addAttribfunc Function
    if (this.state.value === '') {
      this.setState({ infoIncomplete: true });
      setTimeout(()=>this.setState({ infoIncomplete: false }), 2000);
      return;
    }
    this.props.addAttribFunc(
      {
        attribute: this.state.attribute,
        op: this.state.op,
        value: this.state.value
      }
    );
  }

    returnAttributeTypes = type => {
      if (type === 'reply') {
        return [
          'Delegated-IPv6-Prefix',
          'ERX-CoS-Shaping-Pmt-Type',
          'ERX-Ingress-Policy-Name',
          'ERX-Egress-Policy-Name',
          'ERX-IPv6-Ingress-Policy-Name',
          'ERX-IPv6-Egress-Policy-Name',
          'ERX-Primary-Dns',
          'ERX-Secondary-Dns',
          'ERX-Virtual-Router-Name',
          'Framed-IP-Address',
          'Framed-IPv6-Address',
          'Framed-IPv6-Prefix',
          'Framed-IPv6-Route',
          'Framed-Route',
        ];
      }
      return ['Password', 'Huntgroup-name'];
    };

    returnOpTypes = type => {
      if (type === 'reply') {
        return [':=', '+='];
      }
      return ['=='];
    };

    render() {
      return (
            <>
                <Row>
                    <Col className="d-flex justify-content-center mt-2">
                        <Col sm={12} md={6} lg={6}>
                            <Card className="card-accent-info  animated fadeIn">
                                <CardBody>
                                    <Form>
                                        <FormGroup row>
                                            <Label for="exampleSelect" size={'sm'} sm={12} md={12}
                                                   lg={4}>Attribute</Label>
                                            <Col md={12} sm={12} lg={8}>
                                                <Input bsSize={'sm'} type="select" name="select" id="exampleSelect"
                                                       onChange={e => {
                                                         this.setState({
                                                           attribute: e.target.value
                                                         });
                                                       }}
                                                >
                                                    {
                                                        this.state.attribsList.map((e, key) => {
                                                          return <option key={key}>{e}</option>;
                                                        })
                                                    }
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="exampleSelect" size={'sm'} sm={12} md={12} lg={4}>Value</Label>
                                            <Col sm={12} md={12} lg={8}>
                                                <Input type="text" bsSize={'sm'} name="text" id="exampleText"
                                                       onChange={e => this.setState({ value: e.target.value })}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="exampleSelect" size={'sm'} sm={12} md={12}
                                                   lg={4}>Operator</Label>
                                            <Col md={4}>
                                                <Input size={'sm'}
                                                       type="select"
                                                       name="select"
                                                       className="w-50"
                                                       id="exampleSelect"
                                                       onChange={e => this.setState({ op: e.target.value })}
                                                >
                                                    {
                                                        this.state.opsList.map((e, key) => {
                                                          return <option key={key}>{e}</option>;
                                                        })
                                                    }
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                    <hr/>
                                    <Row>
                                        <Col md={2}>
                                            <Button size={'sm'}
                                                    outline
                                                    color="warning"
                                                    onClick={() => this.props.dispatch(newAttributeBox(false))}
                                            >
                                                Close
                                            </Button>
                                        </Col>
                                        <Col className="d-flex justify-content-end align-items-center">
                                            {
                                                this.props.settingAttrib
                                                  ? <div className="float-end">
                                                        <PulseLoader size={10} color="red"/>
                                                    </div>
                                                  : null
                                            }
                                        </Col>
                                        {this.state.infoIncomplete
                                          ? <Col md={3} className="d-flex align-items-center justify-content-end">
                                                <h6 style={{ color: 'red', fontSize: '1.5' }}
                                                    className="m-0"
                                                >Missing details!</h6>
                                            </Col>
                                          : null
                                        }
                                        <Col md={1}>
                                            <Button size={'sm'}
                                                    outline
                                                    className="float-end"
                                                    color="danger"
                                                    onClick={this.newAttrib}
                                            >
                                                Add
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Col>
                </Row>
            </>
      );
    }
}

function mapStateToProps(state) {
  return {
    attribBox: state.radius.newAttribBox,
    activeUser: state.radius.activeUser,
    settingAttrib: state.radius.settingNewAttrib,
    activeAttrib: state.radius.activeAttrib
  };
}

export default connect(mapStateToProps)(AddAttribBox);
