import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Badge,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody
} from 'reactstrap';
import { connect } from 'react-redux';
import Loadable from 'react-loading-overlay';
import CaseSummary from '../../components/Cases/CaseSummary';
import { WeathermapService } from '../../utils/Weathermaps/WeathermapService';
import BeatLoader from 'react-spinners/BeatLoader';
var moment = require('moment');

class WeatherMapNode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: {},
      sortBy: 'lastchange'
    };
    this.sort = this.sort.bind(this);
    this.findCases = this.findCases.bind(this);
  }

  componentDidMount() {
    let nodeMap = {};
    for (let n = 0; n < this.props.topology.nodes.length; n++) {
      nodeMap[this.props.topology.nodes[n].id] = this.props.topology.nodes[n];
    }
    WeathermapService.getWeathermapNode(nodeMap[this.props.nodeId].name + '.hso-group.net').then((result) => {
      this.setState({ data: result.data.info, loading: false });
    });
  }

  findCases(events) {
    let cases = {};
    let ix = 0;
    for (let i = 0; i < events.length; i++) {
      let caseref = events[i].message.match(/CAS-([0-9]{6})-([A-Z0-9]{4})/);
      if (caseref !== null) {
        cases[caseref[0]] = 1;
      }
      ix = events[i].eventid;
    }
    if (Object.keys(cases).length > 0) {
      return (<p>{Object.keys(cases).map((cr) => <div key={cr}>
                    <Badge color="warning" id={'b_' + ix + '_' + cr}>{cr}</Badge>
                    <UncontrolledPopover trigger="legacy" placement="bottom" target={'b_' + ix + '_' + cr}>
                        <PopoverHeader>{cr}</PopoverHeader>
                        <PopoverBody>
                            <CaseSummary caseId={cr}/>
                        </PopoverBody>
                    </UncontrolledPopover>
                </div>)}</p>);
    }

    return (<div></div>);
  }

    sort = alarms => {
      return alarms.sort((a, b) => {
        let aValue = a[this.state.sortBy];
        let bValue = b[this.state.sortBy];

        // Support string comparison
        const sortTable = { true: 1, false: -1 };

        // Order descending (Order.DESC)
        return bValue === aValue ? 0 : sortTable[bValue > aValue];
      });
    };

    render() {
      const colMap = { 0: '#AFAFAF', 1: '#67c2ef', 2: '#FFC859', 3: '#FFA059', 4: '#E97659', 5: '#E45959' };
      let nodeMap = {};
      for (let n = 0; n < this.props.topology.nodes.length; n++) {
        nodeMap[this.props.topology.nodes[n].id] = this.props.topology.nodes[n];
      }
      if (nodeMap[this.props.nodeId].name.startsWith('to')) {
        return (
                <Row>
                    <Col md={4}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={4}>
                                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Type</div>
                                        <div className="h5">Remote Map</div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Node Name</div>
                                        <div className="h5">{nodeMap[this.props.nodeId].name}</div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
        );
      }
      return (<div><Row>
            <Col md={2}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Type</div>
                                <div className="h5">Node</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Node ID</div>
                                <div className="h5">{this.props.nodeId}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Node Name</div>
                                <div className="h5">{nodeMap[this.props.nodeId].name}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">Model</div>
                                <div className="h5">{this.state.loading ? <BeatLoader/> : this.state.data.model}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">RE0 Version</div>
                                <div className="h5">{this.state.loading ? <BeatLoader/> : this.state.data.re0.version}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="text-uppercase text-muted fw-bold font-xs mb-0 mt-2">RE0 Uptime</div>
                                <div className="h5">{this.state.loading ? <BeatLoader/> : this.state.data.re0.uptime}</div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col md={10}>
                <Card>
                    <CardBody>
                        <h6>{this.props.alarms.filter(alarm => alarm.hosts[0].host === nodeMap[this.props.nodeId].name).length} Alarms</h6>
                        {this.sort(this.props.alarms.filter(alarm => alarm.hosts[0].host === nodeMap[this.props.nodeId].name)).map((alarm) => <Row key={alarm.triggerid} style={{ padding: '5px', borderLeftColor: colMap[alarm.priority], borderBottomWidth: '1px', marginBottom: '10px' }} className={'callout callout-info'}>
                                <Col md={2} style={{ whiteSpace: 'nowrap' }}>{alarm.lastEvent.eventid in this.props.acks == 1
                                  ? <div>
                                        <Badge color="success" id={'acks_' + alarm.lastEvent.eventid}>Acknowledged ({this.props.acks[alarm.lastEvent.eventid].length})</Badge>
                                        <UncontrolledPopover trigger="legacy" placement="bottom" target={'acks_' + alarm.lastEvent.eventid}>
                                            <PopoverHeader>Acknowledges</PopoverHeader>
                                            <PopoverBody>
                                                {this.props.acks[alarm.lastEvent.eventid].map((ack) => <div key={ack.acknowledgeid}>
                                                        {ack.alias} acked {moment(ack.clock * 1000).fromNow()}
                                                        <pre style={{ backgroundColor: '#eaeaea', border: '1px solid #eaeaea', borderRadius: '4px', padding: '4px' }}>{ack.message}</pre>
                                                    </div>)}
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                        {alarm.lastEvent.eventid in this.props.filters && <div><Badge color="dark"><i className="icon-eye"></i> Filtered</Badge></div>}
                                        {alarm.lastEvent.eventid in this.props.acks && this.findCases(this.props.acks[alarm.lastEvent.eventid])}
                                    </div>
                                  : <Badge>No Ack</Badge>}</Col>
                                <Col md={2}>{moment(alarm.lastchange * 1000).fromNow()}</Col>
                                <Col md={2}>{alarm.hosts[0].host}</Col>
                                <Col md={5}>
                                    {alarm.description}
                                    {(alarm.triggerid in this.props.processing && this.props.processing[alarm.triggerid] === true) && <Loadable active={true} spinner color={'rgb(62,81,91)'} spinnerSize={'24px'} style={{ float: 'right' }}/>}
                                </Col>
                            </Row>)}
                    </CardBody>
                </Card>
            </Col>
        </Row></div>);
    }
}

function mapStateToProps({ zabbixAlarms }) {
  return {
    alarms: zabbixAlarms.alarms,
    acks: zabbixAlarms.acks,
    filters: zabbixAlarms.filters,
    processing: zabbixAlarms.processing
  };
}

export default connect(mapStateToProps)(WeatherMapNode);
