import React from 'react';
import { Row, Col, Card, CardBody, CardHeader, Badge } from 'reactstrap';
import FilteredTable from '../../../../components/FilteredTable';
import { ButtonIcon } from '../../../../components/ButtonIcon';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const Addresses = ({ loading, addresses, subnet, canAddAndDelete }) => {

  // router
  const navigate = useNavigate();

  const columns = [
    {
      filterable: true,
      sortable: true,
      Header: 'ID',
      accessor: 'id',
      width: 100
    },
    {
      filterable: true,
      sortable: true,
      Header: 'Name',
      accessor: 'name'
    },
    {
      filterable: true,
      sortable: false,
      Header: 'Address',
      accessor: 'address'
    },
    {
      filterable: false,
      sortable: true,
      Header: 'Allocated',
      accessor: 'isAllocated',
      Cell: props => <Badge pill color={props.value ? 'success' : 'danger'}>{props.value ? 'Yes' : 'No'}</Badge>,
      width: 100
    }
  ];

  return (
    <>
      <Row>
        <Col className={'d-flex'}>
          <Card className="w-100">
            <CardHeader>
              <Row>
                <Col>Addresses</Col>
                <Col className="text-end">
                  {canAddAndDelete ? (
                    <ButtonIcon
                      icon="fa fa-plus"
                      tooltip="Add address"
                      onClick={() => navigate({
                        pathname: `/sdb/ipam/addresses/new`,
                        subnet
                      })}
                    />
                  ) : ''}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FilteredTable
                columns={columns}
                data={addresses}
                minRows={1}
                filterable={false}
                loading={loading}
                hideFilter={true}
                showPagination={addresses.length > 10}
                onRowClick={(address) => navigate(`/sdb/ipam/addresses/${address.id}`)}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Addresses;
